import { FilterResources } from '@/types/enums/filter-types'
import { AutocompletePayload } from '@/types/interfaces'
import { AutocompleteResponse } from '@/types/interfaces/api-v2/autocomplete'
import { useTrips } from '@/use/trips'
import { extendedStatuses } from '../../statuses/extendedStatuses.json'
import { cloneDeep } from 'lodash'

const { tripsAutoComplete } = useTrips()

const tripsAutoCompleteFetchFn = async (params: AutocompletePayload) => {
  const { data, nextPage } = await tripsAutoComplete({ ...params }) as { data: AutocompleteResponse[], nextPage: number }
  return {
    data: data.map((item: AutocompleteResponse, index: number) => {
      return {
        id: `${index}-${item.text}`,
        text: item.text,
        value: item.text
      }
    }),
    nextPage
  }
}

const TRIP_DISPATCH_DATE = (config = {}) => {
  return {
    text: 'Dispatch Date',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'trip-dispatch-date-filter',
      headerText: 'Trip Dispatch Date',
      size: 'md',
      contentType: 'date',
      rowType: 'text',
      showHeaders: true
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.TRIP,
    ...config
  }
}

const TRIP_EXPIRATION_DATE = (config = {}) => {
  return {
    text: 'Expiration Date',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'trip-expiration-date-filter',
      headerText: 'Trip Expiration Date',
      size: 'md',
      contentType: 'date',
      rowType: 'text',
      showHeaders: true
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.TRIP,
    ...config
  }
}

const TRIP_ETA_DATE = (config = {}) => {
  return {
    text: 'ETA Date',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'trip-eta-date-filter',
      headerText: 'Trip ETA Date',
      size: 'md',
      contentType: 'date',
      rowType: 'text',
      showHeaders: true
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.TRIP,
    ...config
  }
}

const TRIP_ETC_DATE = (config = {}) => {
  return {
    text: 'ETC Date',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'trip-etc-date-filter',
      headerText: 'Trip ETC Date',
      size: 'md',
      contentType: 'date',
      rowType: 'text',
      showHeaders: true
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.TRIP,
    ...config
  }
}

const TRIP_COMPLETION_DATE = (config = {}) => {
  return {
    text: 'Completion Date',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'trip-completion-date-filter',
      headerText: 'Trip Completion Date',
      size: 'md',
      contentType: 'date',
      rowType: 'text',
      showHeaders: true
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.TRIP,
    ...config
  }
}

const TRIP_CATEGORY = (config = {}, params: any = { field: 'category' }, fetchFn: any = tripsAutoCompleteFetchFn) => {
  return {
    text: 'Trip Category',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'trip-category-filter',
      headerText: 'Trip Category',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: []
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.TRIP,
    ...config
  }
}

const TRIP_STATUS = (config = {}) => {
  return {
    text: 'Status',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'trip-status-filter',
      headerText: 'Trip Status',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn: async ({ terms }: { terms?: string }) => {
        const filteredStatuses = cloneDeep(extendedStatuses).filter((status) => status.text.toLocaleLowerCase().includes(terms?.toLowerCase() || '')).map(status => {
          return {
            id: status.text,
            text: status.text,
            value: status.code
          }
        })
        return {
          data: filteredStatuses,
          totalCount: filteredStatuses.length,
          totalPages: 1
        }
      },
      searchKeys: ['text']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.TRIP,
    ...config
  }
}

const TRIP_CREATION_DATE = (config = {}) => {
  return {
    text: 'Creation Date',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'trip-creation-date-filter',
      headerText: 'Trip Creation Date',
      size: 'md',
      contentType: 'datetime',
      rowType: 'text',
      showHeaders: true
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.TRIP,
    ...config
  }
}

const TRIP_SERVICE_CHECK_IN_TIME = (config = {}) => {
  return {
    text: 'Trip Service Check in',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'trip-service-check-in-filter',
      headerText: 'Trip Service Check In',
      size: 'md',
      contentType: 'datetime',
      rowType: 'text',
      showHeaders: true
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.TRIP,
    ...config
  }
}

const TRIP_SERVICE_CHECK_OUT_TIME = (config = {}) => {
  return {
    text: 'Trip Service Check out',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'trip-service-check-out-filter',
      headerText: 'Trip Service Check Out',
      size: 'md',
      contentType: 'datetime',
      rowType: 'text',
      showHeaders: true
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.TRIP,
    ...config
  }
}

const SERVICE_PHOTOS = (config = {}) => {
  const options = [
    { label: 'Has', value: '1', predicate: 'in' },
    { label: 'Has not', value: '0', predicate: 'not_in' }
  ]
  return {
    text: 'Service Photos',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'service-photos-filters',
      headerText: 'Service Photos',
      size: 'md',
      contentType: 'select',
      rowType: 'text',
      showHeaders: true,
      fetchFn: () => {
        return {
          data: options,
          totalCount: options.length,
          totalPages: 1
        }
      },
      params: {},
      operator: [{ text: 'Is', value: '_not_null' }],
      searchKeys: ['text']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.TRIP,
    ...config
  }
}

const CHECK_IN_OUT_ORIGIN = (config = {}) => {
  const options = [
    { label: 'Web', value: '0', predicate: '', ransackScope: 'web_adjustment' },
    { label: 'Mobile', value: '1', predicate: '', ransackScope: 'mobile_check_in_out_without_adjustments' },
    { label: 'Mobile Check In with Web Adjustment  ', value: '2', predicate: '', ransackScope: 'mobile_check_in_with_web_adjustment' }
  ]
  return {
    text: 'Check In/Out Origin',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'check-in-out-filters',
      headerText: 'Check In/Out Origin',
      size: 'md',
      contentType: 'select',
      rowType: 'text',
      showHeaders: true,
      fetchFn: () => {
        return {
          data: options,
          totalCount: options.length,
          totalPages: 1
        }
      },
      operator: [{ text: 'Is', value: 'eq' }],
      searchKeys: ['text']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.TRIP,
    ...config
  }
}

export {
  TRIP_DISPATCH_DATE,
  TRIP_EXPIRATION_DATE,
  TRIP_ETA_DATE,
  TRIP_ETC_DATE,
  TRIP_COMPLETION_DATE,
  TRIP_CATEGORY,
  TRIP_STATUS,
  TRIP_CREATION_DATE,
  TRIP_SERVICE_CHECK_IN_TIME,
  TRIP_SERVICE_CHECK_OUT_TIME,
  SERVICE_PHOTOS,
  CHECK_IN_OUT_ORIGIN
}
