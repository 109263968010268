import { CLIENT, VENDOR, BROKER, companyRoles } from '@/constants/permissions'

export const findCompanyType = (companyRoles: Array<string>): companyRoles => {
  if (!companyRoles || !companyRoles.length) {
    return CLIENT
  }
  if (companyRoles.includes(CLIENT) && companyRoles.includes(VENDOR) && companyRoles.length >= 2) {
    return BROKER
  } else if (companyRoles.includes(CLIENT) && companyRoles.length === 1) {
    return CLIENT
  } else if (companyRoles.includes(VENDOR) && companyRoles.length === 1) {
    return VENDOR
  } else {
    return CLIENT
  }
}
