import { ref } from 'vue'
import { without } from 'lodash'
import { ResourceAction, DetailsTab } from '@/types/interfaces'
import { TabLocations, DetailsPanelButtonState, ExtendedStatus } from '@/types/enums'
import { ADDRESS_BOOK, ICON_CHECKIN_PHOTOS, ICON_COMMENT_OUTLINE, ICON_FORMAT_ALIGN_LEFT, ICON_LIGHTNING_BOLT, ICON_LIST_BULLETED_SQUARE, ICON_MULTIPLE_OUTLINE, SITE_ICON, USER_HEADSET, WEATHER_EVENTS } from '@/constants/icons'

export const TRIPS_INCLUDIBLE_RESOURCES = 'cc_trip_client, cc_trip_client/logo, cc_trip_contractor, cc_trip_contractor/logo, work_order/vendor, work_order/vendor/logo, work_order/vendor/work_order_setting, tradesmen_trips/assignee, tradesmen_trips/assignee/image, tradesmen_trips/services, tradesmen_trips/services/vendor_verification, tradesmen_trips/services/contractor_verifications, tradesmen_trips/services/recent_adjustment, tradesmen_trips/services/recent_adjustment/adjusted_by, tradesmen_trips/services/recent_adjustment/tasks, work_order/locations/cc_territory, trade, tasks/task, trip_trade_services/trade_service, trip_category, trip_eta, invoice_statuses, service_materials, work_order/vendor/vendor_setting, cc_required_action, service_photos, work_order/vendor/client_setting, work_order/vendor/accounting_setting, work_order/locations/address/currency, work_order/vendor/email_setting, contractor, work_order/locations, cancelation_reason, work_order/locations/cc_location_billing_method, verified_verifications, travel_time_logs, purchase_order_assignment, services/vendor_verification, services/contractor_verifications, work_order/company, work_order/company/logo, checked_in_services, connected_storm_detail_trip, connected_storm_detail_trip/storm_detail, connected_storm_detail_trip/storm_detail/storm, connected_storm_detail_trip/added_by, invoices, storm_overrides, tradesmen_trips/services/tasks, services/service_exception, tradesmen_trips/services/recent_adjustment/task_services/task_check_ins'

export const TECHNICIAN_TRIPS_INCLUDIBLE_RESOURCES = 'team_member, team_member/image, services, services/trade_services, services/tasks, services/recent_adjustment, services/recent_adjustment/adjusted_by, services/service_exception, services/vendor_verification, services/contractor_verifications'

export const TRIP_VIEW_INCLUDIBLE_RESOURCES = 'work_order, work_order/locations, work_order/company, work_order/company/logo, work_order/trade_services, work_order/trades, work_order/trade_services, work_order/trade_services/tasks, trip_eta, services/tasks, services/vendor_verification, services/contractor_verifications, tasks/task, tradesmen_trips/services, cc_trip_contractor, cc_trip_contractor/logo, tasks/task, trade_services, external_connections, trip_trade_services, service_photos, verified_verifications, tradesmen_trips/assignee, tradesmen_trips/services/vendor_verification, tradesmen_trips/services/contractor_verifications, tradesmen_trips/services/recent_adjustment, tradesmen_trips/services/recent_adjustment/tasks'

export const TRIP_VIEW_WO_INCLUDIBLE_RESOURCES = 'locations, locations/cc_territory, company, company/logo, trade_services, trades, tasks/task, trade_services/tasks, vendor, vendor/logo, vendor/work_order_setting, priority_obj, cc_required_actions, services/vendor_verification, services/contractor_verifications, trips/trip_trade_services, trips/trip_trade_services/trade_service, trips/trade, trips/tasks, trips/trip_category, trips/connected_storm_detail_trip, trips/connected_storm_detail_trip/storm_detail, trips/connected_storm_detail_trip/storm_detail/storm, trips/connected_storm_detail_trip/added_by, trips/invoices, trips/storm_overrides, external_connections_including_trips, trips/purchase_order_assignment'

export interface EtaEtcPopoverItemsHsh {
  label: string
  value: string
  isIconType?: boolean
}

export const ALL_STATUSES = Object.values(ExtendedStatus)

export const TRIP_CANNOT_EDIT_STATUSES = [
  ExtendedStatus.CANCELED,
  ExtendedStatus.CLOSED_INCOMPLETE,
  ExtendedStatus.MUST_RETURN,
  ExtendedStatus.TRIP_VERIFIED
]
export const TRIP_CAN_EDIT_STATUSES = without(ALL_STATUSES, ...TRIP_CANNOT_EDIT_STATUSES)

export const CHECK_IN_OUT_PRIMARY_ACTION_STATUSES = [
  ExtendedStatus.TECHNICIAN_NEVER_ARRIVED,
  ExtendedStatus.TECHNICIAN_NEVER_ARRIVED_OR_EXPIRED,
  ExtendedStatus.ETA_MISSED,
  ExtendedStatus.NOT_CHECK_OUT,
  ExtendedStatus.OPEN_ASSIGNED
]

const ALREADY_PRIMARY = [ExtendedStatus.COMPLETED, ExtendedStatus.TRIP_VERIFIED]
const ALL_OTHER_STATUSES = ALL_STATUSES.filter((status) => !ALREADY_PRIMARY.includes(status))
const ALLOWED_STATUS_DISPATCH = [ExtendedStatus.OPEN_UNASSIGNED_WARNING, ExtendedStatus.OPEN_UNASSIGNED]
const ALLOWED_STATUS_CANCEL = [ExtendedStatus.REJECTED, ExtendedStatus.TECHNICIAN_NEVER_ARRIVED, ExtendedStatus.TECHNICIAN_NEVER_ARRIVED_OR_EXPIRED]
const ALLOWED_STATUS_VERIFY = [ExtendedStatus.COMPLETED]
const ALLOWED_STATUS_ADJUST = [ExtendedStatus.NOT_CHECK_OUT, ExtendedStatus.TECHNICIAN_NEVER_ARRIVED_OR_EXPIRED, ExtendedStatus.OPEN_ASSIGNED, ExtendedStatus.TECHNICIAN_NEVER_ARRIVED]
const ALLOWED_STATUS_REMINDER = [ExtendedStatus.ETA_MISSED, ExtendedStatus.NEEDS_REVIEW]
const ALLOWED_STATUS_REVIEW = [ExtendedStatus.NEEDS_REVIEW]
const ALLOWED_STATUS_CHECK_IN_REMINDER = [ExtendedStatus.ETA_MISSED]
const TRIP_NON_CANCELLED_STATUSES = without(ALL_STATUSES, ExtendedStatus.CANCELED)

export const OPERATION_FAILURE_STATUS_CODES = [3, 4, 5, 6, 10]
export const MUST_SCHEDULE_STATUS_CODES = [0, 1, 2, 11]
export const VERFICATION_REQUIRED_STATUS_CODES = [14]
export const IN_PROGRESS_STATUS_CODES = [7, 8, 9]
export const ALL_OTHER_STATUS_CODES = [] // trips with any status

export const tripPrimaryActionData: ResourceAction[] = [
  {
    name: 'ASSIGN',
    validStatuses: [
      ...ALLOWED_STATUS_DISPATCH
    ],
    primaryAction: [
      ...ALLOWED_STATUS_DISPATCH
    ]
  },
  {
    name: 'DISPATCH',
    validStatuses: [
      ...ALLOWED_STATUS_DISPATCH
    ],
    primaryAction: [
      ...ALLOWED_STATUS_DISPATCH
    ]
  },
  {
    name: 'Request Approval',
    validStatuses: [
      ...ALLOWED_STATUS_DISPATCH
    ],
    primaryAction: [
      ...ALLOWED_STATUS_DISPATCH
    ]
  },
  {
    name: 'Approve Asssignment',
    validStatuses: [
      ...ALLOWED_STATUS_DISPATCH
    ],
    primaryAction: [
      ...ALLOWED_STATUS_DISPATCH
    ]
  },
  {
    name: 'CANCEL',
    validStatuses: [
      ...ALLOWED_STATUS_CANCEL
    ],
    primaryAction: [
      ...ALLOWED_STATUS_CANCEL
    ]
  },
  {
    name: 'VERIFY',
    validStatuses: [
      ...ALLOWED_STATUS_VERIFY
    ],
    primaryAction: [
      ...ALLOWED_STATUS_VERIFY
    ]
  },
  {
    name: 'ADJUST',
    validStatuses: [
      ...ALLOWED_STATUS_ADJUST
    ],
    primaryAction: [
      ...ALLOWED_STATUS_ADJUST
    ]
  },
  {
    name: 'SEND REMINDER',
    validStatuses: [
      ...ALLOWED_STATUS_REMINDER
    ],
    primaryAction: [
      ...ALLOWED_STATUS_REMINDER
    ]
  },
  {
    name: 'REVIEW',
    validStatuses: [
      ...ALLOWED_STATUS_REVIEW
    ],
    primaryAction: [
      ...ALLOWED_STATUS_REVIEW
    ]
  },
  {
    name: 'SEND CHECK IN REMINDER',
    validStatuses: [
      ...ALLOWED_STATUS_CHECK_IN_REMINDER
    ],
    primaryAction: [
      ...ALLOWED_STATUS_CHECK_IN_REMINDER
    ]
  }
]

export const technicianTripActionData: any[] = [
  {
    name: 'Accept',
    validStatuses: [
      ...TRIP_CAN_EDIT_STATUSES
    ],
    primaryAction: [
      ...ALL_OTHER_STATUSES
    ]
  },
  {
    name: 'Reject',
    validStatuses: [
      ...ALLOWED_STATUS_VERIFY
    ],
    primaryAction: [
      ALLOWED_STATUS_VERIFY
    ]
  },
  {
    name: 'Verfiy',
    validStatuses: [
      ...ALLOWED_STATUS_VERIFY
    ],
    primaryAction: [
      ExtendedStatus.COMPLETED
    ]
  },
  {
    name: 'Adjust',
    validStatuses: [
      ...ALL_STATUSES
    ],
    primaryAction: [
      ExtendedStatus.COMPLETED
    ]
  },
  {
    name: 'Archive',
    validStatuses: [
      ...ALL_STATUSES
    ],
    primaryAction: [
      ExtendedStatus.COMPLETED
    ]
  },
  {
    name: 'Lock',
    validStatuses: [
      ...ALL_STATUSES
    ],
    primaryAction: [
      ExtendedStatus.COMPLETED
    ]
  },
  {
    name: 'Report',
    validStatuses: [
      ...ALL_STATUSES
    ],
    primaryAction: [
      ExtendedStatus.COMPLETED
    ]
  },
  {
    name: 'Delete',
    validStatuses: [
      ...ALL_STATUSES
    ],
    primaryAction: [
      ExtendedStatus.COMPLETED
    ]
  },
  {
    name: 'Send Emails',
    validStatuses: [
      ...ALL_STATUSES
    ],
    primaryAction: [
      ExtendedStatus.COMPLETED
    ]
  }
]

export const technicianTripPrimaryActionData: ResourceAction[] = [
  {
    name: 'Adjust',
    validStatuses: [
      ...TRIP_CAN_EDIT_STATUSES
    ],
    primaryAction: [
      ...ALL_OTHER_STATUSES
    ]
  },
  {
    name: 'Verify',
    validStatuses: [
      ...ALLOWED_STATUS_VERIFY
    ],
    primaryAction: [
      ExtendedStatus.COMPLETED
    ]
  }
]

export interface TripResourceAction extends ResourceAction {
  /**
   * This action ONLY appears if it is a primary action, otherwise it will not appear even if the state is valid
   */
  primaryActionOnly?: boolean,
  /**
   * This action is mutually exclusive with another action. If this action is present, the other action goes away.
   */
  mutuallyExclusiveWith?: string,
  specialBehaviors?: 'selfPerformingOnly' | 'vendorPerformingOnly'
}

export const TRIP_CREATE_ACTION: TripResourceAction[] = [
  {
    name: 'Save & Assign',
    eventName: 'trip-save-assign',
    validStatuses: true,
    primaryAction: []
  },
  {
    name: 'Save',
    eventName: 'trip-save',
    validStatuses: true,
    primaryAction: []
  }
]

export const TRIP_SAVE_DISPATCH_ACTION: TripResourceAction[] = [
  {
    name: 'Save & Dispatch',
    eventName: 'trip-save-dispatch',
    validStatuses: true,
    primaryAction: []
  }
]

export const TRIP_CLIENT_ACTIONS: TripResourceAction[] = [
  {
    name: 'View',
    eventName: 'view',
    detailsPanel: DetailsPanelButtonState.Hide,
    validStatuses: ALL_STATUSES,
    primaryAction: []
  },
  {
    name: 'Photos',
    eventName: 'trip-photos',
    validStatuses: [ExtendedStatus.NOT_CHECK_OUT, ExtendedStatus.IN_PROGRESS, ExtendedStatus.COMPLETED, ExtendedStatus.TRIP_VERIFIED],
    primaryAction: []
  }
]

export const TRIP_CONTRACTOR_ACTIONS: TripResourceAction[] = [
  {
    name: 'Accept',
    eventName: 'trip-accept',
    textColor: 'success',
    detailsPanel: DetailsPanelButtonState.Expose,
    validStatuses: [ExtendedStatus.NEEDS_REVIEW],
    primaryAction: []
  },
  {
    name: 'Reject',
    textColor: 'error',
    detailsPanel: DetailsPanelButtonState.Expose,
    eventName: 'trip-reject',
    validStatuses: [ExtendedStatus.NEEDS_REVIEW],
    primaryAction: []
  },
  {
    name: 'View',
    eventName: 'view',
    detailsPanel: DetailsPanelButtonState.Hide,
    validStatuses: without(ALL_STATUSES, ExtendedStatus.NEEDS_REVIEW),
    primaryAction: []
  },
  {
    name: 'Review',
    eventName: 'view',
    detailsPanel: DetailsPanelButtonState.Hide,
    validStatuses: [ExtendedStatus.NEEDS_REVIEW],
    primaryAction: [ExtendedStatus.NEEDS_REVIEW]
  },
  {
    name: 'Photos',
    eventName: 'trip-photos',
    validStatuses: [ExtendedStatus.NOT_CHECK_OUT, ExtendedStatus.IN_PROGRESS, ExtendedStatus.COMPLETED, ExtendedStatus.TRIP_VERIFIED],
    primaryAction: []
  },
  {
    name: 'Edit Check In/Out',
    eventName: 'edit-check-in-out',
    validStatuses: without(ALL_STATUSES, ExtendedStatus.TRIP_VERIFIED, ExtendedStatus.CLOSED_INCOMPLETE, ExtendedStatus.CANCELED),
    primaryAction: CHECK_IN_OUT_PRIMARY_ACTION_STATUSES
  },
  {
    name: 'Edit Check In/Out',
    eventName: 'edit-check-in-out-modal',
    validStatuses: without(ALL_STATUSES, ExtendedStatus.TRIP_VERIFIED, ExtendedStatus.CLOSED_INCOMPLETE, ExtendedStatus.CANCELED),
    primaryAction: CHECK_IN_OUT_PRIMARY_ACTION_STATUSES
  },
  {
    name: 'Verify',
    eventName: 'trip-verify',
    validStatuses: [ExtendedStatus.COMPLETED],
    primaryAction: [ExtendedStatus.COMPLETED]
  },
  {
    name: 'Unverify',
    eventName: 'trip-unverify',
    textColor: 'error',
    validStatuses: [ExtendedStatus.TRIP_VERIFIED],
    primaryAction: []
  },
  {
    name: 'Unverify With Services',
    eventName: 'unverify-with-services',
    textColor: 'error',
    validStatuses: [ExtendedStatus.TRIP_VERIFIED],
    primaryAction: []
  },
  {
    name: 'Generate Invoice',
    eventName: 'trip-generate-invoice',
    validStatuses: [ExtendedStatus.COMPLETED, ExtendedStatus.TRIP_VERIFIED],
    primaryAction: []
  }
]

export const TRIP_INTERMEDIARY_BROKER_ACTIONS: TripResourceAction[] = [
  {
    name: 'Accept',
    eventName: 'trip-accept',
    textColor: 'success',
    detailsPanel: DetailsPanelButtonState.Expose,
    validStatuses: [ExtendedStatus.NEEDS_REVIEW],
    primaryAction: []
  },
  {
    name: 'Reject',
    textColor: 'error',
    detailsPanel: DetailsPanelButtonState.Expose,
    eventName: 'trip-reject',
    validStatuses: [ExtendedStatus.NEEDS_REVIEW],
    primaryAction: []
  },
  {
    name: 'View',
    eventName: 'view',
    detailsPanel: DetailsPanelButtonState.Hide,
    validStatuses: ALL_STATUSES,
    primaryAction: [ExtendedStatus.NEEDS_REVIEW]
  },
  {
    name: 'Photos',
    eventName: 'trip-photos',
    validStatuses: [ExtendedStatus.NOT_CHECK_OUT, ExtendedStatus.IN_PROGRESS, ExtendedStatus.COMPLETED, ExtendedStatus.TRIP_VERIFIED],
    primaryAction: []
  },
  {
    name: 'Edit Check In/Out',
    eventName: 'edit-check-in-out',
    validStatuses: without(ALL_STATUSES, ExtendedStatus.TRIP_VERIFIED, ExtendedStatus.CLOSED_INCOMPLETE, ExtendedStatus.CANCELED),
    primaryAction: []
  },
  {
    name: 'Edit Check In/Out',
    eventName: 'edit-check-in-out-modal',
    validStatuses: without(ALL_STATUSES, ExtendedStatus.TRIP_VERIFIED, ExtendedStatus.CLOSED_INCOMPLETE, ExtendedStatus.CANCELED),
    primaryAction: []
  },
  {
    name: 'Reassign',
    eventName: 'trip-reassign',
    validStatuses: [ExtendedStatus.ETA_MISSED, ExtendedStatus.OPEN_ASSIGNED],
    primaryAction: []
  },
  {
    name: 'View Archive',
    eventName: 'trip-archive',
    validStatuses: without(ALL_STATUSES, ExtendedStatus.CANCELED),
    primaryAction: []
  },
  {
    name: 'Verify',
    eventName: 'trip-verify',
    validStatuses: [ExtendedStatus.COMPLETED],
    primaryAction: [ExtendedStatus.COMPLETED]
  },
  {
    name: 'Unverify',
    eventName: 'trip-unverify',
    textColor: 'error',
    validStatuses: [ExtendedStatus.TRIP_VERIFIED],
    primaryAction: []
  },
  {
    name: 'Unverify With Services',
    eventName: 'unverify-with-services',
    textColor: 'error',
    validStatuses: [ExtendedStatus.TRIP_VERIFIED],
    primaryAction: []
  },
  {
    name: 'Generate Invoice',
    eventName: 'trip-generate-invoice',
    validStatuses: [ExtendedStatus.COMPLETED, ExtendedStatus.TRIP_VERIFIED],
    primaryAction: []
  }
]

export const TRIP_BROKER_ACTIONS: TripResourceAction[] = [
  {
    name: 'Assign',
    eventName: 'trip-assign',
    validStatuses: [ExtendedStatus.OPEN_UNASSIGNED_WARNING, ExtendedStatus.OPEN_UNASSIGNED],
    primaryAction: [ExtendedStatus.OPEN_UNASSIGNED_WARNING, ExtendedStatus.OPEN_UNASSIGNED]
  },
  {
    name: 'View',
    eventName: 'view',
    validStatuses: ALL_STATUSES,
    primaryAction: [],
    detailsPanel: DetailsPanelButtonState.Hide
  },
  {
    name: 'Edit',
    eventName: 'trip-edit',
    validStatuses: without(ALL_STATUSES, ExtendedStatus.CANCELED, ExtendedStatus.TRIP_VERIFIED),
    primaryAction: [ExtendedStatus.TECHNICIAN_NEVER_ARRIVED_OR_EXPIRED, ExtendedStatus.ETA_MISSED, ExtendedStatus.REJECTED],
    specialBehaviors: 'selfPerformingOnly'
  },
  {
    name: 'Edit',
    eventName: 'trip-edit',
    validStatuses: without(ALL_STATUSES, ExtendedStatus.CANCELED, ExtendedStatus.TRIP_VERIFIED),
    primaryAction: [ExtendedStatus.TECHNICIAN_NEVER_ARRIVED_OR_EXPIRED, ExtendedStatus.REJECTED],
    specialBehaviors: 'vendorPerformingOnly'
  },
  {
    name: 'Generate Report',
    eventName: 'trip-generate-report',
    validStatuses: ALL_STATUSES,
    primaryAction: []
  },
  {
    name: 'Dispatch',
    eventName: 'trip-dispatch',
    validStatuses: [ExtendedStatus.OPEN_UNASSIGNED_DISPATCHED, ExtendedStatus.OPEN_UNASSIGNED_WARNING, ExtendedStatus.OPEN_UNASSIGNED, ExtendedStatus.NEEDS_REVIEW],
    primaryAction: [ExtendedStatus.OPEN_UNASSIGNED_DISPATCHED, ExtendedStatus.OPEN_UNASSIGNED_WARNING, ExtendedStatus.OPEN_UNASSIGNED, ExtendedStatus.NEEDS_REVIEW]
  },
  {
    name: 'Re-send Dispatch Email',
    eventName: 'trip-resend-dispatch-email',
    validStatuses: [ExtendedStatus.TECHNICIAN_NEVER_ARRIVED, ExtendedStatus.TECHNICIAN_NEVER_ARRIVED_OR_EXPIRED, ExtendedStatus.ETA_MISSED, ExtendedStatus.NOT_CHECK_OUT, ExtendedStatus.IN_PROGRESS, ExtendedStatus.OPEN_ASSIGNED, ExtendedStatus.MUST_RETURN, ExtendedStatus.NEEDS_REVIEW, ExtendedStatus.COMPLETED, ExtendedStatus.TRIP_VERIFIED, ExtendedStatus.CLOSED_INCOMPLETE],
    primaryAction: [],
    specialBehaviors: 'vendorPerformingOnly'
  },
  {
    name: 'Photos',
    eventName: 'trip-photos',
    validStatuses: ALL_STATUSES,
    primaryAction: [ExtendedStatus.NOT_CHECK_OUT, ExtendedStatus.IN_PROGRESS, ExtendedStatus.TRIP_VERIFIED]
  },
  {
    name: 'Check In / Out',
    eventName: 'trip-check-in',
    validStatuses: [ExtendedStatus.TECHNICIAN_NEVER_ARRIVED, ExtendedStatus.ETA_MISSED, ExtendedStatus.NOT_CHECK_OUT, ExtendedStatus.IN_PROGRESS, ExtendedStatus.OPEN_ASSIGNED, ExtendedStatus.MUST_RETURN],
    primaryAction: [],
    primaryActionOnly: true,
    mutuallyExclusiveWith: 'trip-adjust',
    specialBehaviors: 'selfPerformingOnly'
  },
  {
    name: 'Check In / Out',
    eventName: 'trip-check-in',
    validStatuses: without(ALL_STATUSES, ExtendedStatus.TRIP_VERIFIED, ExtendedStatus.CLOSED_INCOMPLETE, ExtendedStatus.CANCELED),
    primaryAction: [],
    primaryActionOnly: true,
    mutuallyExclusiveWith: 'trip-adjust',
    specialBehaviors: 'vendorPerformingOnly'
  },
  {
    name: 'Edit Check In/Out',
    eventName: 'edit-check-in-out',
    validStatuses: without(ALL_STATUSES, ExtendedStatus.TRIP_VERIFIED, ExtendedStatus.CLOSED_INCOMPLETE, ExtendedStatus.CANCELED),
    primaryAction: [ExtendedStatus.TECHNICIAN_NEVER_ARRIVED, ExtendedStatus.NOT_CHECK_OUT, ExtendedStatus.OPEN_ASSIGNED],
    specialBehaviors: 'selfPerformingOnly'
  },
  {
    name: 'Edit Check In/Out',
    eventName: 'edit-check-in-out',
    validStatuses: without(ALL_STATUSES, ExtendedStatus.TRIP_VERIFIED, ExtendedStatus.CLOSED_INCOMPLETE, ExtendedStatus.CANCELED),
    primaryAction: [],
    specialBehaviors: 'vendorPerformingOnly'
  },
  {
    name: 'Edit Check In/Out',
    eventName: 'edit-check-in-out-modal',
    validStatuses: without(ALL_STATUSES, ExtendedStatus.TRIP_VERIFIED, ExtendedStatus.CLOSED_INCOMPLETE, ExtendedStatus.CANCELED),
    primaryAction: [ExtendedStatus.TECHNICIAN_NEVER_ARRIVED, ExtendedStatus.NOT_CHECK_OUT, ExtendedStatus.OPEN_ASSIGNED],
    specialBehaviors: 'selfPerformingOnly'
  },
  {
    name: 'Edit Check In/Out',
    eventName: 'edit-check-in-out-modal',
    validStatuses: without(ALL_STATUSES, ExtendedStatus.TRIP_VERIFIED, ExtendedStatus.CLOSED_INCOMPLETE, ExtendedStatus.CANCELED),
    primaryAction: [],
    specialBehaviors: 'vendorPerformingOnly'
  },
  {
    name: 'View Archive',
    eventName: 'trip-archive',
    validStatuses: without(ALL_STATUSES, ExtendedStatus.CANCELED),
    primaryAction: []
  },
  {
    name: 'Cancel',
    textColor: 'error',
    eventName: 'trip-cancel',
    validStatuses: [ExtendedStatus.OPEN_UNASSIGNED, ExtendedStatus.OPEN_UNASSIGNED_WARNING, ExtendedStatus.REJECTED, ExtendedStatus.TECHNICIAN_NEVER_ARRIVED, ExtendedStatus.TECHNICIAN_NEVER_ARRIVED_OR_EXPIRED, ExtendedStatus.ETA_MISSED, ExtendedStatus.OPEN_ASSIGNED, ExtendedStatus.MUST_RETURN, ExtendedStatus.NEEDS_REVIEW],
    primaryAction: []
  },
  {
    name: 'Verify',
    eventName: 'trip-verify',
    validStatuses: [ExtendedStatus.COMPLETED],
    primaryAction: [ExtendedStatus.COMPLETED]
  },
  {
    name: 'Unverify',
    eventName: 'trip-unverify',
    textColor: 'error',
    validStatuses: [ExtendedStatus.TRIP_VERIFIED],
    primaryAction: []
  },
  {
    name: 'Unverify With Services',
    eventName: 'unverify-with-services',
    textColor: 'error',
    validStatuses: [ExtendedStatus.TRIP_VERIFIED],
    primaryAction: []
  },
  {
    name: 'Copy Trip',
    eventName: 'copy-trip',
    validStatuses: without(ALL_STATUSES, ExtendedStatus.CANCELED),
    primaryAction: []
  },
  {
    name: 'Send Text Message',
    eventName: 'send-text-message',
    validStatuses: without(ALL_STATUSES, ExtendedStatus.CANCELED),
    primaryAction: []
  },
  {
    name: 'Generate Invoice',
    eventName: 'trip-generate-invoice',
    validStatuses: [ExtendedStatus.COMPLETED, ExtendedStatus.TRIP_VERIFIED],
    primaryAction: []
  },
  {
    name: 'Skip',
    eventName: 'skip',
    validStatuses: ALL_STATUSES,
    primaryAction: [
      'Skip'
    ],
    textColor: 'error',
    detailsPanel: DetailsPanelButtonState.Only
  },
  {
    name: 'Add Work Order Note',
    eventName: 'add-work-order-note',
    validStatuses: TRIP_NON_CANCELLED_STATUSES,
    primaryAction: []
  },
  {
    name: 'Request Approval',
    eventName: 'vendor-dispatch-approval',
    validStatuses: [ExtendedStatus.OPEN_UNASSIGNED_DISPATCHED, ExtendedStatus.OPEN_UNASSIGNED_WARNING, ExtendedStatus.OPEN_UNASSIGNED],
    primaryAction: [ExtendedStatus.OPEN_UNASSIGNED_DISPATCHED, ExtendedStatus.OPEN_UNASSIGNED_WARNING, ExtendedStatus.OPEN_UNASSIGNED]
  },
  {
    name: 'Approve Asssignment',
    eventName: 'vendor-approve-assignment',
    validStatuses: [ExtendedStatus.OPEN_UNASSIGNED_DISPATCHED, ExtendedStatus.OPEN_UNASSIGNED_WARNING, ExtendedStatus.OPEN_UNASSIGNED],
    primaryAction: [ExtendedStatus.OPEN_UNASSIGNED_DISPATCHED, ExtendedStatus.OPEN_UNASSIGNED_WARNING, ExtendedStatus.OPEN_UNASSIGNED]
  },
  {
    name: 'Associate Event',
    eventName: 'associate-event',
    validStatuses: ALL_STATUSES,
    primaryAction: []
  },
  {
    name: 'Disassociate Event',
    eventName: 'disassociate-event',
    validStatuses: ALL_STATUSES,
    primaryAction: [],
    textColor: 'error'
  }
]

export const TripsDetailsPanelTabs = ref<DetailsTab[]>([
  {
    name: 'Overview',
    prependIcon: ICON_FORMAT_ALIGN_LEFT,
    quantity: 0,
    expose: { md: TabLocations.Left, lg: TabLocations.Left }
  },
  {
    name: 'Services',
    prependIcon: ICON_LIST_BULLETED_SQUARE,
    quantity: 0,
    expose: { lg: TabLocations.Center, md: TabLocations.Center }
  },
  {
    name: 'Assignees',
    prependIcon: USER_HEADSET,
    quantity: 0,
    expose: { lg: TabLocations.Center, md: TabLocations.Center }
  },
  {
    name: 'Photos',
    prependIcon: ICON_CHECKIN_PHOTOS,
    quantity: 0,
    expose: { lg: TabLocations.Center, md: TabLocations.Center }
  },
  {
    name: 'Weather Event',
    prependIcon: WEATHER_EVENTS,
    quantity: 0,
    expose: { lg: TabLocations.Center, md: TabLocations.Center }
  },
  {
    name: 'Contacts',
    prependIcon: ADDRESS_BOOK,
    quantity: 0,
    expose: { lg: TabLocations.Right, md: TabLocations.Center }
  },
  {
    name: 'Attachments',
    prependIcon: ICON_MULTIPLE_OUTLINE,
    quantity: 0,
    expose: { lg: TabLocations.Right, md: TabLocations.Center }
  },
  {
    name: 'WO Notes',
    prependIcon: ICON_COMMENT_OUTLINE,
    quantity: 0,
    expose: { md: TabLocations.Center }
  },
  {
    name: 'WO Activity',
    prependIcon: ICON_LIGHTNING_BOLT,
    quantity: 0,
    expose: { md: TabLocations.Center }
  },
  {
    name: 'Site Details',
    prependIcon: SITE_ICON,
    expose: { md: TabLocations.Center }
  }
])

export interface TripBulkActionsInteface {
  value: string
  event: string
  requiresConfirmation: boolean
  bulkConfig: { type: string }
  tabs: string[]
  actionFn?: any
}

export const TripBulkActions:TripBulkActionsInteface[] = [
  {
    value: 'Dispatch',
    event: 'dispatch',
    requiresConfirmation: false,
    bulkConfig: {
      type: 'menu'
    },
    tabs: ['all', 'must-schedule']
  },
  {
    value: 'Verify',
    event: 'verify',
    requiresConfirmation: false,
    bulkConfig: {
      type: 'menu'
    },
    tabs: ['all', 'verification-required']
  },
  {
    value: 'Unverify',
    event: 'unverify',
    requiresConfirmation: false,
    bulkConfig: {
      type: 'menu'
    },
    tabs: ['all']
  }
]
