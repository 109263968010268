import { api } from '@/api'
import qs from 'qs'
import { NewBackgroundTask } from '@/types/interfaces'
interface Params {
  source: string
  query: string
  workOrderIds: number[]
}

export default {
  create: (backgroundTaskParams: NewBackgroundTask, status: string | null) => {
    return api.$post(
      '/bulk_action_tasks',
      { bulk_action_task: { ...backgroundTaskParams }, status }
    )
  },
  confirm: (taskId: number, bulkActionConfirmParams?: any) => {
    return api.$patch(`/bulk_action_tasks/${taskId}/confirm`, {
      bulk_action_task: { ...bulkActionConfirmParams }
    }, {
      headers: {
        'Content-Type': 'application/json'
      },
      paramsSerializer: (params: any) => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  cancel: (taskId: number, jobType = 'bulk_action_task') => {
    return api.$patch(`/bulk_action_tasks/${taskId}/cancel`, { jobType })
  },
  getStats: (taskId: number) => {
    return api.$get(`/bulk_action_tasks/${taskId}/stats`)
  },
  validate: (backgroundTaskParams: NewBackgroundTask) => {
    return api.$post(
      '/bulk_action_tasks/validate',
      { bulk_action_task: { ...backgroundTaskParams } }
    )
  }
}
