import { JSONObject } from '..'

export interface Filter {
  id: number
  current: boolean
  query: JSONObject
  userId: number
  companyId: number
  filterType?: string | null
  templateName: string
  path: string
  filterConfig: {
    filters: {
      [k: string]: {
        operator: string,
        selected: any[],
        chip: {
          pinned: boolean
        }
      }
    }
    sortBy?: {
      value: string,
      direction: {
        text: string,
        value: string
      }
    },
    finderParam?: boolean
  }
  filterConfigOrder: JSONObject
  default: boolean
  filter?: any
  isAction: boolean
}

export interface FiltersParams {
  templateId?: number
  include?: string
  q?: any
  page?: number
  perPage?: number
  actionFilter?: boolean
}

export interface ActionFilterResource {
  id?: string
  resourceType: string
  resourceId: string | number
  actionFilterId: string | number
  parentTab: number | undefined
  attachedId?: number | undefined
  attachedType?: string | undefined
}

export interface ActionFilterConfig {
  name?: string
  value?: number | string
}

export enum AllowedPrefilledResourceTypes {
  Bg = 'BackgroundTask'
}
export interface PrefillFilterData {
  resourceType: AllowedPrefilledResourceTypes
  resourceId: number | string
  filterKey: string
}
