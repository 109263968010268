import { api, apis } from '@/api'
import qs from 'qs'
import { transformRansackToEndpointQuery } from '@/utils/api'
import { Vendor, VendorsBulkActionParams } from '@/types/interfaces'
import { AutocompletePayload } from '@/types/interfaces/api-v2/autocomplete'
import { VendorPhotosParams } from '@/use/vendors'

interface Params {
  include: string
  q: any
  page: number
  perPage: number
  activeOrPendingVendor: boolean
  id: number
  tab?: string
  workOrderIds?: any
  complianceStatus?: boolean
  showTradesText?: boolean
  fieldname?: string
  workOrderId?: number | string
  activeNonProposalVendors?: boolean
  includeAlertNotesCount?: boolean
  vendorStatisticsRequired?: boolean
  contactUser?: boolean
  withoutLimit?: boolean
  tripIds?: number[]
  latAndLng?: Array<string>
}
interface VendorUpdateParams {
  vendorId: string
  include?: string
  updateContent: Vendor
}

const surveyIncludes = 'survey_template, survey_template/questions, survey_template/questions/answer_type, survey_template/questions/answer_choices, observations/answer_choice, observations/answer_choices, observations/question, observations/observation_documents, company/headquarter_address'

export const transformed = (q: any) => transformRansackToEndpointQuery(q, [
  {
    queryField: /completed_at_date(.*)/,
    replaceWith: 'q_trips',
    key: 'completed_at_date'
  },
  {
    queryField: /work_order_company_name(.*)/,
    replaceWith: 'q_trips',
    key: 'work_order_company_name'
  },
  {
    queryField: /work_order_locations_name(.*)/,
    replaceWith: 'q_trips',
    key: 'work_order_locations_name'
  },
  {
    queryField: /locations_name(.*)/,
    replaceWith: 'q_trips',
    key: 'locations_name'
  },
  {
    queryField: /work_order_work_order_name(.*)/,
    replaceWith: 'q_trips',
    key: 'work_order_work_order_name'
  },
  {
    queryField: /trade_services_name(.*)/,
    replaceWith: 'q_trips',
    key: 'trade_services_name'
  },
  {
    queryField: /trades_name(.*)/,
    replaceWith: 'q_trips',
    key: 'trades_name'
  },
  {
    queryField: /work_order_work_order_type(.*)/,
    replaceWith: 'q_trips',
    key: 'work_order_work_order_type'
  },
  {
    queryField: /work_order_vendor_client_agreements_external_region(.*)/,
    replaceWith: 'q_trips',
    key: 'work_order_vendor_client_agreements_external_region'
  },
  {
    queryField: /work_order_created_at(.*)/,
    replaceWith: 'q_trips',
    key: 'work_order_created_at'
  },
  {
    queryField: /work_order_expiration_date(.*)/,
    replaceWith: 'q_trips',
    key: 'work_order_expiration_date'
  },
  {
    queryField: /expiration_date(.*)/,
    replaceWith: 'q_trips',
    key: 'expiration_date'
  },
  {
    queryField: /work_order_creation_date(.*)/,
    replaceWith: 'q_trips',
    key: 'work_order_creation_date'
  },
  {
    queryField: /extended_status_code(.*)/,
    replaceWith: 'q_trips',
    key: 'extended_status_code'
  },
  {
    queryField: /completed_at(.*)/,
    replaceWith: 'q_trips',
    key: 'completed_at'
  },
  {
    queryField: /work_order_follow_up_at(.*)/,
    replaceWith: 'q_trips',
    key: 'work_order_follow_up_at'
  },
  {
    queryField: /work_order_extended_status_state(.*)/,
    replaceWith: 'q_trips',
    key: 'work_order_extended_status_state'
  },
  {
    queryField: /work_order_locations_cc_territory_name(.*)/,
    replaceWith: 'q_trips',
    key: 'work_order_locations_cc_territory_name'
  },
  {
    queryField: /work_order_status_name(.*)/,
    replaceWith: 'q_trips',
    key: 'work_order_work_order_status_name'
  },
  {
    queryField: /work_orders_corrigo_connection_external_id(.*)/,
    replaceWith: 'q_trips',
    key: 'work_order_corrigo_connection_external_id'
  },
  {
    queryField: /work_orders_trips_service_channel_connection_external_id(.*)/,
    replaceWith: 'q_trips',
    key: 'work_order_trips_service_channel_connection_external_id'
  },
  {
    queryField: /start_date(.*)/,
    replaceWith: 'q_trips',
    key: 'start_date'
  },
  {
    queryField: /work_order_trades_name(.*)/,
    replaceWith: 'q_trips',
    key: 'work_order_trades_name'
  },
  {
    queryField: /services_effective_check_in_time(.*)/,
    replaceWith: 'q_trips',
    key: 'services_effective_check_in_time'
  },
  {
    queryField: /services_effective_check_out_time(.*)/,
    replaceWith: 'q_trips',
    key: 'services_effective_check_out_time'
  }
])

export default {
  getVendors: (params: any) => {
    const parameters: Params = params
    return apis.$get('/vendors', {
      params: parameters,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  getNetTerms: ({ id }: Params) => {
    return apis.$get('/vendors/net_terms', {
      params: {
        id
      }
    })
  },
  getById: (params: any) => {
    const parameters: Params = params
    return apis.$get(`/vendors/${parameters.id}`, {
      params: parameters,
      paramsSerializer: (params) => {
        return qs.stringify(params)
      }
    })
  },
  getBillableVendors: (params: Params) => {
    const { include, q, page, perPage, workOrderIds } = params
    return apis.$get('/vendors/billables', {
      params: {
        include,
        q,
        workOrderIds,
        page,
        perPage,
        ...transformed(q)
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  approve: (vendorId: number) => apis.$patch(`/vendors/${vendorId}/approve`),
  reject: (vendorId: number) => apis.$patch(`/vendors/${vendorId}/reject`),

  // everything beneath here is depcricated
  blockOrUnblockVendor: (params: any) => {
    const { vendorId, path = 'block' } = params
    return apis.$patch(`/vendors/${vendorId}/${path}`, {
      params: {
        id: vendorId
      },
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },
  approveOrRejectVendor: (params: any) => {
    const { vendorId, path = 'approve' } = params
    return apis.$patch(`/vendors/${vendorId}/${path}`, {
      params: {
        id: vendorId
      },
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },
  post: (params: any, requestBody: any) => {
    return apis.$post('/vendors', {
      ...requestBody
    }, {
      params,
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },
  update: ({ vendorId, updateContent, include }:VendorUpdateParams) => {
    return apis.$patch(`/vendors/${vendorId}`, { vendor: updateContent }, {
      params: {
        include
      },
      paramsSerializer: (params:any) => {
        return qs.stringify(params)
      }
    })
  },
  resendInvitation: (params: any) => {
    const { vendorId } = params
    return apis.$get(`/vendors/${vendorId}/resend_invitation`, {
      params: {
        id: vendorId
      },
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },
  resendInvitationBulk: (params: VendorsBulkActionParams) => {
    const { tab, q, ids, activeOrPendingVendor } = params
    return apis.$get('/vendors/bulk_resend_invitation', {
      params: {
        vendorIds: ids,
        tab,
        q,
        activeOrPendingVendor
      },
      paramsSerializer: (params: any) => qs.stringify(params, { arrayFormat: 'brackets' })
    })
  },
  saveVendorRequirements: (params: any) => {
    return apis.$post(`vendors/${params.vendorId}/create_compliance_requirement`, {
      compliance_requirment: params.requirement,
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },
  updateVendorRequirement: (params: any) => {
    return apis.$patch(`vendors/${params.vendorId}/update_compliance_requirement`, {
      compliance_requirment: params.requirement,
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },
  getVendorRequirements: (params: any) => {
    const { id } = params
    return apis.$get(`/vendors/${id}`, {
      params: {
        include: 'dba,contacts,logo,headquarter_address'
      },
      paramsSerializer: params => {
        return qs.stringify(params)
      }
    })
  },
  downloadSampleExcel: () => {
    const link = document.createElement('a')
    link.href = window.location.origin + '/api/v2/vendors/download_sample_excel'
    link.setAttribute(
      'download',
      'sample_template.xlsx'
    )
    link.click()
    link.remove()
  },
  downloadFilteredExcel: (params: any) => {
    const link = document.createElement('a')
    const q = qs.stringify({
      q: params.q
    }, { arrayFormat: 'brackets' })
    link.href = window.location.origin + `/api/v2/vendors?bulk_download=true&${params.selectedTab ? `tab=${params.selectedTab}&` : ''}&${q}`
    link.setAttribute(
      'download',
      'vendors_template.xlsx'
    )
    link.click()
    link.remove()
  },
  getVendorComplianceHeaders: () => apis.$get('vendors/compliance_headers'),
  inviteVendor: (companyId: number) => {
    return apis.$post('/vendors', {
      company_id: companyId,
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },
  getVendorComplianceDetails: (params: any) => {
    return apis.$get(`/vendors/${params.id}/vendor_compliances`, {
      params: {
        include: surveyIncludes,
        compliance_type: params.complianceType,
        document_type: params.documentType,
        document_id: params.documentId,
        include_status: true,
        check_shared_doc_availability: params?.sharedDocAvailable || false
      },
      paramsSerializer: params => {
        return qs.stringify(params)
      }
    })
  },
  postVendorComplianceDetails: (surveyObject: any, params: any) => {
    return apis.$post(`vendors/${params.vendorId}/vendor_compliances?document_type=${params.documentType}&tab=${params.tab}`, surveyObject, {
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },
  getVendorSharedComplianceDetails: (params: any) => {
    return apis.$get(`/vendors/${params.id}/vendor_compliances/public_documents`, {
      params: {
        include: surveyIncludes,
        compliance_type: params.complianceType,
        document_type: params.documentType,
        include_status: true
      },
      paramsSerializer: params => {
        return qs.stringify(params)
      }
    })
  },
  getVendorComplianceDetailsNoDoc: (params: any) => {
    return apis.$get(`/vendors/${params.id}/vendor_compliances/new`, {
      params: {
        include: surveyIncludes,
        compliance_type: params.complianceType,
        document_type: params.documentType,
        include_status: true,
        check_shared_doc_availability: params?.sharedDocAvailable || false
      },
      paramsSerializer: params => {
        return qs.stringify(params)
      }
    })
  },
  getVendorSharedComplianceList: (params: any) => {
    return apis.$get(`/vendors/${params.id}/vendor_compliances/public_documents`, {
      params: {
        include: surveyIncludes,
        include_status: true
      },
      paramsSerializer: params => {
        return qs.stringify(params)
      }
    })
  },
  postVendor: (param: any) => {
    return apis.$post('/vendors',
      param, { headers: { content_type: 'multipart/form-data' } })
  },
  getComplianceList: (param: any) => {
    return apis.$get(`vendors/${param.id}/compliance_documents_status`, {
      params: {
        grouped_coi_documents: param.isCOI
      },
      paramsSerializer: params => {
        return qs.stringify(params)
      }
    })
  },
  getComplianceRequirementList: (param: any) => {
    return apis.$get(`vendors/${param.id}/compliance_requirements`, {
      paramsSerializer: params => {
        return qs.stringify(params)
      }
    })
  },
  markVendorCompliant: (params: VendorsBulkActionParams) => {
    return apis.$patch('/vendors/mark_vendors_as_compliant', {
      ...params,
      paramsSerializer: (params: any) => qs.stringify(params, { arrayFormat: 'brackets' })
    })
  },
  markVendorNonCompliant: (params: VendorsBulkActionParams) => {
    return apis.$patch('/vendors/mark_vendors_as_non_compliant', {
      ...params,
      paramsSerializer: (params: any) => qs.stringify(params, { arrayFormat: 'brackets' })
    })
  },
  complianceAcceptReject: (surveyData: any) => {
    return apis.$patch(`/surveys/${surveyData.surveyId}/review_surveys/${surveyData.reviewSurveyId}`, {
      review_survey: surveyData.surveyAnswer,
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },
  complianceUpdate: (surveyObject: any, params: any) => {
    return apis.$patch(`vendors/${params.vendorId}/vendor_compliances/${params.complianceId}?document_type=${params.documentType}`, surveyObject, {
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },
  getTradesByVendorId: (params: any) => {
    const parameters: Params = params
    const { include, q, id, page, perPage } = parameters
    return apis.$get(`/vendors/${id}/vendor_trades/private_trades`, {
      params: {
        include,
        q,
        page,
        per_page: perPage
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  getPublicTradesByVendorId: (params: any) => {
    const parameters: Params = params
    const { include, q, id, page, perPage } = parameters
    return apis.$get(`/vendors/${id}/vendor_trades/public_trades`, {
      params: {
        include,
        q,
        page,
        per_page: perPage
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  getPrivateTrades: (params: any) => {
    const parameters: Params = params
    const { include, q, page, perPage } = parameters
    return apis.$get('/trades', {
      params: {
        include,
        q,
        page,
        per_page: perPage
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  getVendorsLicense: (param: any) => {
    return apis.$get(`vendors/${param.vendorId}/licenses`, {
      paramsSerializer: params => {
        return qs.stringify(params)
      }
    })
  },
  vendorTradeEdit: (param: any) => {
    return apis.$patch(`vendors/${param.vendorId}/vendor_trades/bulk_create_or_destroy`,
      {
        ...param.tradesIds,
        paramsSerializer: (params: any) => {
          return qs.stringify(params)
        }
      })
  },
  getPrivateRegionByVendorId: (params: any) => {
    const parameters: Params = params
    const { include, q, id } = parameters
    return apis.$get(`/vendors/${id}/regions`, {
      params: {
        include,
        q
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  getPublicRegionByVendorId: (params: any) => {
    const parameters: Params = params
    const { include, q, id } = parameters
    return apis.$get(`/vendors/${id}/regions/public_regions`, {
      params: {
        include,
        q
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  getVendorCOIRejectionEmailTemplate: (param: any) => {
    return apis.$get(`/vendors/${param.vendorId}/vendor_compliances/${param.complianceId}/default_coi_rejection_email_template`, {
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },
  sendComplianceRejectEmail: (param: any) => {
    return apis.$post(`/vendors/${param.vendorId}/vendor_compliances/${param.complianceId}/send_compliance_rejection_email`, {
      survey: param.body
    }, {
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },
  updateEmailAndSendResendVendorInvite: (param: any) => {
    return apis.$patch('/vendors/bulk_update_email_and_send_invitation', {
      vendors: param
    }, {
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },
  sendCOIExpirationRemainder: (params: VendorsBulkActionParams) => {
    return apis.$get('/vendors/send_coi_expiration_reminders', {
      params,
      paramsSerializer: (params: any) => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  updateProfileLogo: (params: any, id: number) => {
    return apis.$patch(`vendors/${id}}?include=logo`, params, { headers: { content_type: 'multipart/form-data' } })
  },
  updateProfile: (params: any, id: number) => {
    return apis.$patch(`vendors/${id}}`, { vendor: params.payload }, {
      params: {
        include: params.include
      },
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },
  addVendorAlert: (params: any) => {
    return apis.$post(`/vendors/${params.id}/alerts?include=alert_note`, {
      note: params.note,
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },
  editVendorAlert: (params: any) => {
    return apis.$patch(`/vendors/${params.id}/alerts/${params.noteId}?include=alert_note`, {
      note: params.note,
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },
  addVendorNote: (params: any) => {
    return apis.$post(`/vendors/${params.id}/notes`, {
      note: params.note,
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },
  editVendorNote: (params: any) => {
    return apis.$patch(`/vendors/${params.id}/notes/${params.noteId}`, {
      note: params.note,
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },
  deleteVendorAlert: (params: any) => {
    return apis.$delete(`/vendors/${params.id}/alerts/${params.noteId}?include=alert_note`, {
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },
  deleteVendorNote: (params: any) => {
    return apis.$delete(`/vendors/${params.id}/notes/${params.noteId}`, {
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },
  getContacts: (params: any) => {
    const parameters: Params = params
    const { include, q, id, contactUser } = parameters
    return apis.$get(`/vendors/${id}/contacts`, {
      params: {
        include,
        q,
        contactUser
      },
      paramsSerializer: (params) => {
        return qs.stringify(params)
      }
    })
  },
  deleteVendorContact: (params: any) => {
    return apis.$delete(`/vendors/${params.id}/contacts/${params.contactId}`, {
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },
  AddVendorContact: (params: any) => {
    return apis.$patch(`vendors/${params.id}`, params.contactObject, {
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },
  updateVendorContact: (params: any) => {
    return apis.$patch(`vendors/${params.id}/contacts/${params.contactId}`, params.contactObject, {
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },
  getVendorRegion: (params: any) => {
    return apis.$get(`/vendors/${params.id}/regions/states`, {
      params: {
        id: params.regionId,
        page: params.page
      },
      paramsSerializer: (params) => {
        return qs.stringify(params)
      }
    })
  },
  getVendorStateCounty: (params: any) => {
    const parameters: Params = params
    const { id, page, perPage } = parameters
    return apis.$get(`/vendors/${id}/regions/counties`, {
      params: {
        state_id: params.stateId,
        id: params.regionId,
        page,
        perPage
      },
      paramsSerializer: (params) => {
        return qs.stringify(params)
      }
    })
  },
  getVendorCountyZipCodes: (params: any) => {
    const parameters: Params = params
    const { id, page, perPage } = parameters
    return apis.$get(`/vendors/${id}/regions/zip_codes`, {
      params: {
        county_id: params.countyId,
        id: params.regionId,
        page,
        perPage
      },
      paramsSerializer: (params) => {
        return qs.stringify(params)
      }
    })
  },
  addVendorRegion: (params: any) => {
    return apis.$post(`/vendors/${params.vendorId}/regions`, {
      region: params.region,
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },
  updateVendorRegion: (params: any) => {
    return apis.$patch(`/vendors/${params.vendorId}/regions/${params.regionId}`, {
      region: params.region,
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },
  getClaimStatuses: (params: any) => {
    return apis.$get('/vendors/claim_statuses', {
      paramsSerializer: (params) => {
        return qs.stringify(params)
      }
    })
  },
  getAddresses: (params: any) => {
    return apis.$get(`/vendors/${params.vendorId}/addresses`, {
      params: { address: { address_type: params.addressType, inRelation: params.fromPrivate } },
      paramsSerializer: (params) => {
        return qs.stringify(params)
      }
    })
  },
  downloadVendorCompliancesDocs: (params: any) => {
    return api.$getBuffer(`/vendors/${params.vendorId}/vendor_compliances/download_info`, {
      params: {
        document_type: params.documentType
      },
      responseType: 'arraybuffer',
      paramsSerializer: (params) => {
        return qs.stringify(params)
      }
    })
  },
  getComplianceUploadReminderInfo: (params: any) => {
    return apis.$get(`/vendors/${params.id}/vendor_compliances/upload_reminder_info`, {
      paramsSerializer: (params) => {
        return qs.stringify(params)
      }
    })
  },
  sendComplianceUploadReminder: (params: any) => {
    return apis.$patch(`vendors/${params.id}/vendor_compliances/send_upload_reminder`, params.body, {
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },
  getCoiExpirationReminderInfo: (params: any) => {
    return apis.$get(`/vendors/${params.id}/vendor_compliances/coi_expiration_reminder_info`, {
      paramsSerializer: (params) => {
        return qs.stringify(params)
      }
    })
  },
  sendCoiExpirationReminder: (params: any) => {
    return apis.$patch(`vendors/${params.id}/vendor_compliances/send_coi_expiration_reminder`, params.body, {
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },
  getVendorCoinsConnections: (params: any) => {
    const parameters: Params = params
    const { include, q, page, perPage, tab } = parameters
    return apis.$get('/vendor/coins_connections', {
      params: {
        include,
        q,
        page,
        tab,
        per_page: perPage
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  getVendorMarkets: (params: any) => {
    const parameters: Params = params
    const { include, q, page, perPage, tab } = parameters
    return apis.$get('/vendor/markets', {
      params: {
        include,
        q,
        page,
        tab,
        per_page: perPage
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  getVendorRegions: (params: any) => {
    const parameters: Params = params
    const { include, q, page, perPage, tab } = parameters
    return apis.$get('/vendor/regions', {
      params: {
        include,
        q,
        page,
        tab,
        per_page: perPage
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  getVendorTrades: (params: any) => {
    const parameters: Params = params
    const { include, q, page, perPage, tab, fieldname } = parameters
    return apis.$get('/vendor/trades', {
      params: {
        include,
        q,
        page,
        tab,
        per_page: perPage,
        fieldname
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  getVendorAddresses: (params: any) => {
    const parameters: Params = params
    const { include, q, page, perPage, tab, fieldname } = parameters
    return apis.$get('/vendor/addresses', {
      params: {
        include,
        q,
        page,
        tab,
        per_page: perPage,
        fieldname
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  getVendorsCoverageAmount: (params: any) => {
    const parameters: Params = params
    const { include, q, page, perPage, tab } = parameters
    return apis.$get('/vendor/coverage_observations', {
      params: {
        include,
        q,
        page,
        tab,
        per_page: perPage
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  getInvitationTemplate: (vendorName: any) => {
    return apis.$get(`vendors/invitation_template?vendor_name=${vendorName}`, {
      paramsSerializer: (params) => {
        return qs.stringify(params)
      }
    })
  },
  vendorsAutoComplete: (params: AutocompletePayload) => {
    const { field, terms, predicate, tab, page, finderParams, perPage } = params
    return apis.$post('/vendors/autocomplete', {
      field,
      terms,
      predicate,
      tab,
      page,
      finderParams,
      perPage
    },
    {
      headers: {
        'Content-Type': 'application/json'
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  vendorsSearch: (params: any) => {
    const parameters: Params = params
    const { include, q, page, perPage, tab, activeOrPendingVendor, complianceStatus, showTradesText, vendorStatisticsRequired, latAndLng } = parameters
    return apis.$post('/vendors/search', {
      include,
      q,
      page,
      tab,
      activeOrPendingVendor,
      per_page: perPage,
      compliance_status: complianceStatus,
      show_trades_text: showTradesText,
      vendor_statistics_required: vendorStatisticsRequired,
      lat_and_lng: latAndLng
    },
    {
      headers: {
        'Content-Type': 'application/json'
      },
      paramsSerializer: (params: any) => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  billableVendorsSearch: (params: Params) => {
    const { include, q, page, perPage, workOrderIds, withoutLimit, tripIds } = params
    return apis.$post('/vendors/billables', {
      include,
      q,
      workOrderIds,
      tripIds,
      withoutLimit,
      page,
      perPage,
      ...transformed(q)
    },
    {
      headers: {
        'Content-Type': 'application/json'
      },
      paramsSerializer: (params: any) => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  getVendorsByWorkOrderId: (params: Params) => {
    const { q, page, perPage, workOrderId, activeNonProposalVendors } = params
    return apis.$get(`/work_orders/${workOrderId}/vendors`, {
      params: {
        q,
        page,
        perPage,
        active_non_proposal_vendors: activeNonProposalVendors
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  getVendorsPhotos: (vendorId: number, params: VendorPhotosParams) => {
    return api.$get(`/vendors/${vendorId}/trip_photos`, {
      params,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  getVendorsPhotosCount: (vendorId: number) => {
    return api.$get(`/vendors/${vendorId}/trip_photos/count`)
  },
  photoPreview: (params: { ids: number[], tab: string }) => {
    return api.$get('/vendors/photos_preview', {
      params: {
        withoutCount: true,
        ...params
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  }
}
