import {
  CLIENT_NAME,
  CLIENT_COUNTRY,
  CLIENT_STATE,
  CLIENT_CITY,
  CLIENT_ZIP,
  CLIENT_ADDRESS
} from '@/constants/filters/resources/client'

import {
  SITE_NAME,
  SITE_STATE,
  SITE_COUNTRY,
  SITE_CITY,
  SITE_ZIP,
  SITE_ADDRESS,
  SITE_REGION
} from '@/constants/filters/resources/site'

import {
  WORK_ORDER_NUMBER,
  WORK_ORDER_START_DATE,
  WORK_ORDER_CREATED_DATE,
  WORK_ORDER_EXPIRATION_DATE,
  WORK_ORDER_TRIP_EXPIRATION_DATE,
  WORK_ORDER_GENERATION_DATE,
  WORK_ORDER_TYPE,
  WORK_ORDER_PRIORITY,
  WORK_ORDER_TRIP_STATUS,
  WORK_ORDER_STATUS,
  WORK_ORDER_CUSTOM_STATUS,
  WORK_ORDER_CALL,
  WORK_ORDER_JOB_NAME,
  WORK_ORDER_DEPARTMENT_BRANCH,
  WORK_ORDER_FOLLOW_UP_DATE,
  WORK_ORDER_CLIENT_CONTACT,
  WORK_ORDER_TRIP_CATEGORY,
  WORK_ORDER_PROBLEM_TYPE,
  WORK_ORDER_CATEGORY,
  WORK_ORDER_COMPLETION_DATE,
  WORK_ORDER_TRIP_COMPLETION_DATE,
  VENDOR_INVOICE_EXISTS,
  WORK_ORDER_TRADE,
  WORK_ORDER_SERVICE,
  WORK_ORDER_BILLING_METHOD,
  WORK_ORDER_EXTERNAL_REGION,
  WORK_ORDER_SERVICE_CHANNEL_CONNECTION,
  WORK_ORDER_CORRIGO_CONNECTION,
  WORK_ORDER_SERVICE_CHECK_IN_TIME,
  WORK_ORDER_SERVICE_CHECK_OUT_TIME
} from '@/constants/filters/resources/workOrder'
import {
  TRIP_CREATION_DATE,
  TRIP_SERVICE_CHECK_IN_TIME,
  TRIP_SERVICE_CHECK_OUT_TIME
} from '../resources/trip'

import {
  PROPOSAL_NUMBER,
  PROPOSAL_CUSTOM_NAME,
  PROPOSAL_STATUS,
  PROPOSAL_DATE_OF_ISSUE,
  PROPOSAL_EXPIRATION_DATE
  // PROPOSAL_CLIENT_NAME,
  // PROPOSAL_SITE_NAME
} from '@/constants/filters/resources/proposal'

import {
  WEATHER_EVENT,
  WEATHER_EVENT_START_DATE,
  WEATHER_EVENT_END_DATE
} from '@/constants/filters/resources/weatherEvent'
import { BROKER, CLIENT } from '@/constants/permissions'
import { useSession } from '@/use/session'
import { CompanyRoleTypes } from '@/types/enums'

export const generateInvoicesFilters = () => {
  const { session } = useSession()

  const availableWeatherEventsFilters = () => {
    const baseFilters: any = []
    if (session.currentCompanyType === BROKER) {
      baseFilters.push(
        WEATHER_EVENT_END_DATE({ pinned: false, isPrimary: false }),
        WEATHER_EVENT_START_DATE({ pinned: true, isPrimary: true }),
        WEATHER_EVENT({ pinned: false, isPrimary: false })
      )
    }
    return baseFilters
  }

  const availableTripFilters = () => {
    const baseFilters: any = [
      TRIP_CREATION_DATE({ pinned: false, isPrimary: false }),
      TRIP_SERVICE_CHECK_IN_TIME({ pinned: false, isPrimary: false }),
      TRIP_SERVICE_CHECK_OUT_TIME({ pinned: false, isPrimary: false })
    ]
    return baseFilters
  }

  const availableWorkOrderFilters = () => {
    const baseFilters: any = [
      WORK_ORDER_NUMBER({ pinned: true, isPrimary: true }, { field: 'id', invoiceables: true }),
      WORK_ORDER_START_DATE({ pinned: false, isPrimary: false }),
      WORK_ORDER_CREATED_DATE({ pinned: false, isPrimary: false }),
      WORK_ORDER_EXPIRATION_DATE({ pinned: false, isPrimary: false }),
      WORK_ORDER_TRIP_EXPIRATION_DATE({ pinned: false, isPrimary: false }),
      WORK_ORDER_GENERATION_DATE({ pinned: false, isPrimary: false }),
      WORK_ORDER_TYPE({ pinned: false, isPrimary: false }, { field: 'work_order_type', invoiceables: true }),
      // WORK_ORDER_PRIORITY({ pinned: false, isPrimary: false }),
      WORK_ORDER_TRIP_STATUS({ pinned: false, isPrimary: false }),
      WORK_ORDER_STATUS({ pinned: false, isPrimary: false }),
      // WORK_ORDER_CALL({ pinned: false, isPrimary: false }),
      WORK_ORDER_JOB_NAME({ pinned: false, isPrimary: false }),
      // WORK_ORDER_DEPARTMENT_BRANCH({ pinned: false, isPrimary: false }),
      WORK_ORDER_FOLLOW_UP_DATE({ pinned: false, isPrimary: false }),
      // WORK_ORDER_CLIENT_CONTACT({ pinned: false, isPrimary: false }),
      // WORK_ORDER_TRIP_CATEGORY({ pinned: false, isPrimary: false }),
      // WORK_ORDER_COMPLETION_DATE(),
      WORK_ORDER_TRIP_COMPLETION_DATE(),
      WORK_ORDER_BILLING_METHOD({ pinned: false, isPrimary: false }),
      WORK_ORDER_CATEGORY({ pinned: false, isPrimary: true }, { field: 'category', invoiceables: true }),
      // WORK_ORDER_PROBLEM_TYPE({ pinned: false, isPrimary: true }, { field: 'problem_type', invoiceables: true })
      WORK_ORDER_SERVICE_CHANNEL_CONNECTION({ pinned: false, isPrimary: false }, { field: 'service_channel_connection_id', invoiceables: true }),
      WORK_ORDER_CORRIGO_CONNECTION({ pinned: false, isPrimary: false }, { field: 'corrigo_connection_id', invoiceables: true }),
      WORK_ORDER_SERVICE_CHECK_IN_TIME({ pinned: false, isPrimary: false }),
      WORK_ORDER_SERVICE_CHECK_OUT_TIME({ pinned: false, isPrimary: false })
    ]
    if (session.currentCompanyType === BROKER) {
      baseFilters.push(
        WORK_ORDER_EXTERNAL_REGION({ pinned: false, isPrimary: false }),
        WORK_ORDER_SERVICE({ pinned: false, isPrimary: false }),
        WORK_ORDER_TRADE({ pinned: false, isPrimary: false }),
        WORK_ORDER_CUSTOM_STATUS({ pinned: false, isPrimary: false })
      )
    }
    if (session.currentCompanyType === BROKER || session.currentCompanyType === CLIENT) {
      baseFilters.push(
        VENDOR_INVOICE_EXISTS()
      )
    }
    return baseFilters
  }

  const availableSiteFilters = () => {
    const baseFilters: any = [
      SITE_NAME({ pinned: true, isPrimary: true }, { field: 'location', invoiceables: true }),
      SITE_STATE({ pinned: true, isPrimary: true })
      // SITE_COUNTRY({ pinned: false, isPrimary: false }),
      // SITE_CITY({ pinned: false, isPrimary: false }),
      // SITE_ZIP({ pinned: false, isPrimary: false }),
      // SITE_ADDRESS({ pinned: false, isPrimary: false })
    ]
    if (session.currentCompanyType === BROKER) {
      baseFilters.push(
        SITE_REGION({ pinned: false, isPrimary: false })
      )
    }
    return baseFilters
  }

  return {
    filters: [
      CLIENT_NAME({ pinned: true, isPrimary: true }),
      ...availableSiteFilters(),
      ...availableWorkOrderFilters(),
      PROPOSAL_NUMBER(true, { pinned: false, isPrimary: false }, { field: 'proposal_number' }),
      PROPOSAL_CUSTOM_NAME(true, { pinned: false, isPrimary: false }, { field: 'custom_proposal_name' }),
      PROPOSAL_STATUS(CompanyRoleTypes.VENDOR, { pinned: false, isPrimary: false }),
      PROPOSAL_DATE_OF_ISSUE({ pinned: false, isPrimary: false }),
      PROPOSAL_EXPIRATION_DATE({ pinned: false, isPrimary: false }),
      // PROPOSAL_CLIENT_NAME({ pinned: false, isPrimary: false }),
      // PROPOSAL_SITE_NAME({ pinned: false, isPrimary: false })
      ...availableWeatherEventsFilters(),
      // CLIENT_COUNTRY({ pinned: false, isPrimary: false }),
      // CLIENT_STATE({ pinned: false, isPrimary: false }),
      // CLIENT_CITY({ pinned: false, isPrimary: false }),
      // CLIENT_ZIP({ pinned: false, isPrimary: false }),
      // CLIENT_ADDRESS({ pinned: false, isPrimary: false })
      ...availableTripFilters()
    ],
    groupBy: {
      value: 'clients',
      items: [
        {
          label: 'Client and Site',
          value: 'clients'
        },
        {
          label: 'Work Order',
          value: 'work-order'
        }
      ]
    },
    fieldMapping: {
      'client-name': {
        linkingTables: {
          sites: 'company',
          clients: ''
        },
        name: 'name',
        field: 'text',
        dynamicQueryKeys: {
          workOrdersName: 'company_name',
          weatherEventsName: ''
        },
        rowTypesToQuery: [
          'clients',
          'sites'
        ]
      },
      'client-country': {
        linkingTables: {
          sites: 'address',
          clients: ''
        },
        name: 'headquarter_address_country',
        field: 'value',
        dynamicQueryKeys: {
          workOrdersName: 'address_country',
          weatherEventsName: ''
        },
        rowTypesToQuery: [
          'clients',
          'sites'
        ]
      },
      'client-state': {
        linkingTables: {
          sites: 'address',
          clients: ''
        },
        name: 'headquarter_address_state',
        field: 'value',
        dynamicQueryKeys: {
          workOrdersName: 'address_state',
          weatherEventsName: ''
        },
        rowTypesToQuery: [
          'clients',
          'sites'
        ]
      },
      'client-city': {
        linkingTables: {
          sites: 'address',
          clients: ''
        },
        name: 'headquarter_address_city',
        field: 'value',
        dynamicQueryKeys: {
          workOrdersName: 'address_city',
          weatherEventsName: ''
        },
        rowTypesToQuery: [
          'clients',
          'sites'
        ]
      },
      'client-zip': {
        linkingTables: {
          sites: 'address',
          clients: ''
        },
        name: 'headquarter_address_zip_code',
        field: 'value',
        dynamicQueryKeys: {
          workOrdersName: 'address_zip_code',
          weatherEventsName: ''
        },
        rowTypesToQuery: [
          'clients',
          'sites'
        ]
      },
      'client-address': {
        linkingTables: {
          sites: 'address',
          clients: ''
        },
        name: 'headquarter_address_formatted_address',
        field: 'value',
        dynamicQueryKeys: {
          workOrdersName: 'address_full_address',
          weatherEventsName: ''
        },
        rowTypesToQuery: [
          'clients',
          'sites'
        ]
      },
      'site-name': {
        linkingTables: {
          clients: 'locations',
          sites: ''
        },
        name: 'name',
        field: 'text',
        dynamicQueryKeys: {
          workOrdersName: 'locations_name',
          weatherEventsName: 'location_name'
        },
        rowTypesToQuery: [
          'clients',
          'sites',
          'work-orders',
          'proposals',
          'events',
          'trips'
        ]
      },
      'site-country': {
        linkingTables: {
          clients: 'address',
          sites: ''
        },
        name: 'address_country',
        field: 'value',
        dynamicQueryKeys: {
          workOrdersName: 'address_country',
          weatherEventsName: 'address_country'
        },
        rowTypesToQuery: [
          'clients',
          'sites'
        ]
      },
      'site-city': {
        linkingTables: {
          clients: 'address',
          sites: ''
        },
        name: 'city',
        field: 'value',
        dynamicQueryKeys: {
          workOrdersName: 'address_city',
          weatherEventsName: 'address_city'
        },
        rowTypesToQuery: [
          'clients',
          'sites'
        ]
      },
      'site-zip': {
        linkingTables: {
          clients: 'address',
          sites: ''
        },
        name: 'zip_code',
        field: 'value',
        dynamicQueryKeys: {
          workOrdersName: 'address_zip_code',
          weatherEventsName: 'address_zip_code'
        },
        rowTypesToQuery: [
          'clients',
          'sites'
        ]
      },
      'site-address': {
        linkingTables: {
          clients: 'address',
          sites: ''
        },
        name: 'full_address',
        field: 'value',
        dynamicQueryKeys: {
          workOrdersName: 'address_full_address',
          weatherEventsName: 'address_full_address'
        },
        rowTypesToQuery: [
          'clients',
          'sites'
        ]
      },
      'site-region': {
        linkingTables: {
          clients: '',
          sites: '',
          proposals: '',
          'work-orders': '',
          events: ''
        },
        name: 'work_order_locations_cc_territory_name',
        field: 'text',
        dynamicQueryKeys: {
          workOrdersName: 'work_order_locations_cc_territory_name',
          weatherEventsName: 'work_order_locations_cc_territory_name'
        },
        rowTypesToQuery: [
          'clients',
          'sites',
          'work-orders',
          'proposals',
          'events'
        ]
      },
      'site-state': {
        linkingTables: {
          clients: 'locations_address',
          sites: ''
        },
        name: 'state',
        field: 'value',
        dynamicQueryKeys: {
          workOrdersName: 'address_state',
          weatherEventsName: 'address_state'
        },
        rowTypesToQuery: [
          'clients',
          'sites'
        ]
      },
      'work-order-number': {
        linkingTables: {
          clients: 'work_orders',
          sites: 'work_orders',
          proposals: 'work_order',
          'work-orders': '',
          events: ''
        },
        name: 'id',
        field: 'text',
        dynamicQueryKeys: {
          workOrdersName: 'id',
          weatherEventsName: 'id'
        },
        rowTypesToQuery: [
          'clients',
          'sites',
          'work-orders',
          'proposals',
          'events'
        ]
      },
      'work-order-call': {
        linkingTables: {
          clients: 'work_orders',
          sites: 'work_orders',
          proposals: 'work_order',
          'work-orders': '',
          events: ''
        },
        name: 'call_type',
        field: 'value',
        inject: {
          clients: {
            name: 'work_orders_call_type',
            operator: 'not_blank',
            values: true
          },
          sites: {
            name: 'work_orders_call_type',
            operator: 'not_blank',
            values: true
          }
        },
        dynamicQueryKeys: {
          workOrdersName: 'call_type',
          weatherEventsName: ''
        },
        rowTypesToQuery: [
          'clients',
          'sites',
          'work-orders',
          'proposals',
          'events'
        ]
      },
      'work-order-expiration-date': {
        linkingTables: {},
        name: 'work_order_expiration_date',
        field: 'value',
        dynamicQueryKeys: {},
        rowTypesToQuery: [
          'clients',
          'sites',
          'work-orders',
          'proposals',
          'events',
          'trips'
        ]
      },
      'work-order-created-date': {
        linkingTables: {},
        name: 'work_order_created_at',
        field: 'value',
        dynamicQueryKeys: {},
        rowTypesToQuery: [
          'clients',
          'sites',
          'work-orders',
          'proposals',
          'events',
          'trips'
        ]
      },
      'work-order-priority': {
        linkingTables: {
          clients: 'work_orders',
          sites: 'work_orders',
          proposals: 'work_orders',
          'work-orders': '',
          events: 'storm_detail_trips_trip'
        },
        name: 'priority',
        field: 'value',
        dynamicQueryKeys: {
          workOrdersName: 'priority',
          weatherEventsName: 'storm_detail_trips_trip'
        },
        rowTypesToQuery: [
          'clients',
          'sites',
          'work-orders',
          'proposals',
          'events'
        ]
      },
      'work-order-start-date': {
        linkingTables: {
          clients: 'work_orders',
          sites: 'work_orders',
          proposals: 'work_order',
          'work-orders': '',
          events: ''
        },
        name: 'trips_start_date',
        field: 'value',
        dynamicQueryKeys: {
          workOrdersName: 'trips_start_date',
          weatherEventsName: 'trips_start_date'
        },
        rowTypesToQuery: [
          'clients',
          'sites',
          'work-orders',
          'proposals',
          'events'
        ]
      },
      'work-order-trip-expiration-date': {
        linkingTables: {},
        name: 'trips_expiration_date',
        field: 'value',
        dynamicQueryKeys: {},
        rowTypesToQuery: [
          'clients',
          'sites',
          'work-orders',
          'proposals',
          'events',
          'trips'
        ]
      },
      'work-order-generation-date': {
        linkingTables: {},
        name: 'work_order_creation_date',
        field: 'value',
        dynamicQueryKeys: {},
        rowTypesToQuery: [
          'clients',
          'sites',
          'work-orders',
          'proposals',
          'events',
          'trips'
        ]
      },
      'work-order-type': {
        linkingTables: {
          clients: 'work_orders',
          sites: 'work_orders',
          proposals: 'work_order',
          'work-orders': '',
          events: ''
        },
        name: 'work_order_type',
        field: 'value',
        dynamicQueryKeys: {
          workOrdersName: 'work_order_type',
          weatherEventsName: 'work_order_type'
        },
        rowTypesToQuery: [
          'clients',
          'sites',
          'work-orders',
          'proposals',
          'events'
        ]
      },
      'work-order-trip-status': {
        linkingTables: {
          clients: 'work_orders',
          sites: 'work_orders',
          proposals: 'work_order',
          'work-orders': '',
          events: ''
        },
        name: 'extended_status_code',
        field: 'value',
        dynamicQueryKeys: {
          workOrdersName: 'trips_extended_status_code',
          weatherEventsName: 'trips_extended_status_code'
        },
        rowTypesToQuery: [
          'clients',
          'sites',
          'work-orders',
          'proposals',
          'events'
        ]
      },
      'work-order-status': {
        linkingTables: {
          clients: 'work_orders',
          sites: 'work_orders',
          proposals: 'work_order',
          'work-orders': '',
          events: ''
        },
        name: 'work_order_status_name',
        field: 'text',
        dynamicQueryKeys: {
          workOrdersName: 'work_order_status_name',
          weatherEventsName: 'work_order_status_name'
        },
        rowTypesToQuery: [
          'clients',
          'sites',
          'work-orders',
          'proposals',
          'events'
        ]
      },
      'work-order-custom-status': {
        linkingTables: {
          clients: 'work_orders',
          sites: 'work_orders',
          proposals: 'work_order',
          'work-orders': '',
          events: ''
        },
        name: 'work_order_status_name',
        field: 'value',
        dynamicQueryKeys: {
          workOrdersName: 'work_order_status_name',
          weatherEventsName: 'work_order_status_name'
        },
        rowTypesToQuery: [
          'clients',
          'sites',
          'work-orders',
          'proposals',
          'events'
        ]
      },
      'work-order-job-name': {
        linkingTables: {
        },
        name: 'work_order_work_order_name',
        field: 'value',
        dynamicQueryKeys: {
        },
        rowTypesToQuery: [
          'clients',
          'sites',
          'work-orders',
          'proposals',
          'events'
        ]
      },
      'work-order-department-branch': {
        linkingTables: {
          clients: 'work_orders',
          sites: 'work_orders',
          proposals: 'work_order',
          'work-orders': '',
          events: ''
        },
        name: 'cu_cc_departments_name',
        field: 'value',
        dynamicQueryKeys: {
          workOrdersName: 'cu_cc_departments_name',
          weatherEventsName: 'cu_cc_departments_name'
        },
        rowTypesToQuery: [
          'clients',
          'sites',
          'work-orders',
          'proposals',
          'events'
        ]
      },
      'work-order-follow-up-date': {
        linkingTables: {},
        name: 'work_order_follow_up_at',
        field: 'value',
        dynamicQueryKeys: {},
        rowTypesToQuery: [
          'clients',
          'sites',
          'work-orders',
          'proposals',
          'events',
          'trips'
        ]
      },
      'work-order-client-contact': {
        linkingTables: {
          clients: 'work_orders',
          sites: 'work_orders',
          proposals: 'work_order',
          'work-orders': '',
          events: ''
        },
        name: 'client_contact_full_name',
        field: 'value',
        dynamicQueryKeys: {
          workOrdersName: 'client_contact_full_name',
          weatherEventsName: 'client_contact_full_name'
        },
        rowTypesToQuery: [
          'clients',
          'sites',
          'work-orders',
          'proposals',
          'events'
        ]
      },
      'work-order-trip-category': {
        linkingTables: {
          clients: 'work_orders',
          sites: 'work_orders',
          proposals: 'work_order',
          'work-orders': '',
          events: ''
        },
        name: 'trips_trip_category_name',
        field: 'value',
        dynamicQueryKeys: {
          workOrdersName: 'trips_trip_category_name',
          weatherEventsName: 'trips_trip_category_name'
        },
        rowTypesToQuery: [
          'clients',
          'sites',
          'work-orders',
          'proposals',
          'events'
        ]
      },
      'work-order-problem-type': {
        linkingTables: {
          clients: 'work_orders',
          sites: 'work_orders',
          proposals: 'work_order',
          'work-orders': '',
          events: ''
        },
        name: 'problem_type',
        field: 'value',
        dynamicQueryKeys: {
          workOrdersName: 'problem_type',
          weatherEventsName: 'problem_type'
        },
        rowTypesToQuery: [
          'clients',
          'sites',
          'work-orders',
          'proposals',
          'events'
        ]
      },
      'work-order-problem-code': {
        linkingTables: {
          clients: 'work_orders',
          sites: 'work_orders',
          proposals: 'work_order',
          'work-orders': '',
          events: ''
        },
        name: 'category',
        field: 'value',
        dynamicQueryKeys: {
          workOrdersName: 'category',
          weatherEventsName: 'category'
        },
        rowTypesToQuery: [
          'clients',
          'sites',
          'work-orders',
          'proposals',
          'events'
        ]
      },
      // 'work-order-completion-date': {
      //   // ...
      // },
      'work-order-trip-completion-date': {
        linkingTables: {},
        name: 'completed_at',
        field: 'value',
        dynamicQueryKeys: {},
        rowTypesToQuery: [
          'clients',
          'sites',
          'work-orders',
          'proposals',
          'events',
          'trips'
        ]
      },
      'vendor-invoice-exists': {
        linkingTables: {},
        name: 'vendor_invoice_exists',
        field: 'value',
        dynamicQueryKeys: {},
        rowTypesToQuery: [
          'clients',
          'sites',
          'work-orders',
          'proposals',
          'events',
          'trips'
        ]
      },
      'work-order-service': {
        linkingTables: {
          clients: '',
          sites: '',
          proposals: '',
          'work-orders': '',
          events: ''
        },
        name: 'work_order_trade_services_name',
        field: 'value',
        dynamicQueryKeys: {
        },
        rowTypesToQuery: [
          'clients',
          'sites',
          'work-orders',
          'proposals',
          'events'
        ]
      },
      'work-order-trade': {
        linkingTables: {
          clients: '',
          sites: '',
          proposals: '',
          'work-orders': '',
          events: ''
        },
        name: 'work_order_trades_name',
        field: 'value',
        dynamicQueryKeys: {
        },
        rowTypesToQuery: [
          'clients',
          'sites',
          'work-orders',
          'proposals',
          'events'
        ]
      },
      'work-order-billing-method': {
        linkingTables: {
          clients: '',
          sites: '',
          proposals: '',
          'work-orders': '',
          events: ''
        },
        name: 'work_order_work_order_type',
        field: 'value',
        dynamicQueryKeys: {
        },
        rowTypesToQuery: [
          'clients',
          'sites',
          'work-orders',
          'proposals',
          'events'
        ]
      },
      'external-region': {
        linkingTables: {
          clients: '',
          sites: '',
          proposals: '',
          'work-orders': '',
          events: ''
        },
        name: 'work_order_locations_agreements_external_region',
        field: 'value',
        dynamicQueryKeys: {
        },
        rowTypesToQuery: [
          'clients',
          'sites',
          'work-orders',
          'proposals',
          'events'
        ]
      },
      'proposal-number': {
        linkingTables: {
          'work-orders': {
            name: 'id',
            field: 'workOrderId'
          },
          proposals: '',
          clients: 'proposals',
          sites: 'proposals',
          events: ''
        },
        name: 'estimate_number',
        field: 'value',
        dynamicQueryKeys: {
          workOrdersName: 'proposals_estimate_number',
          weatherEventsName: ''
        },
        rowTypesToQuery: [
          'clients',
          'sites',
          'work-orders',
          'proposals',
          'events'
        ]
      },
      'proposal-custom-name': {
        linkingTables: {
          'work-orders': {
            name: 'id',
            field: 'workOrderId'
          },
          proposals: '',
          clients: 'proposals',
          sites: 'proposals',
          events: ''
        },
        name: 'custom_proposal_name',
        field: 'value',
        dynamicQueryKeys: {
          workOrdersName: 'proposals_custom_proposal_name',
          weatherEventsName: ''
        },
        rowTypesToQuery: [
          'clients',
          'sites',
          'work-orders',
          'proposals',
          'events'
        ]
      },
      'proposal-status': {
        linkingTables: {
          'work-orders': {
            name: 'id',
            field: 'workOrderId'
          },
          proposals: '',
          clients: 'proposals_calculated_status',
          sites: 'proposals_calculated_status',
          events: ''
        },
        name: 'code',
        field: 'value',
        dynamicQueryKeys: {
          workOrdersName: 'proposals_calculated_status_code',
          weatherEventsName: ''
        },
        rowTypesToQuery: [
          'clients',
          'sites',
          'work-orders',
          'proposals',
          'events'
        ]
      },
      'proposal-date-of-issue': {
        linkingTables: {
          'work-orders': {
            name: 'id',
            field: 'workOrderId'
          },
          proposals: '',
          clients: 'proposals',
          sites: 'proposals',
          events: ''
        },
        name: 'date_of_issue',
        field: 'value',
        dynamicQueryKeys: {
          workOrdersName: 'proposals_date_of_issue',
          weatherEventsName: ''
        },
        rowTypesToQuery: [
          'clients',
          'sites',
          'work-orders',
          'proposals',
          'events'
        ]
      },
      'proposal-expiration-date': {
        linkingTables: {
          'work-orders': {
            name: 'id',
            field: 'workOrderId'
          },
          proposals: '',
          clients: 'proposals',
          sites: 'proposals',
          events: ''
        },
        name: 'proposal_date',
        field: 'value',
        dynamicQueryKeys: {
          workOrdersName: 'proposals_proposal_date',
          weatherEventsName: ''
        },
        rowTypesToQuery: [
          'clients',
          'sites',
          'work-orders',
          'proposals',
          'events'
        ]
      },
      'proposal-client-name': {
        linkingTables: {
          'work-orders': {
            name: 'id',
            field: 'workOrderId'
          },
          proposals: '',
          clients: 'proposals',
          sites: 'proposals',
          events: ''
        },
        name: 'company_name',
        field: 'value',
        dynamicQueryKeys: {
          workOrdersName: 'proposals_company_name',
          weatherEventsName: ''
        },
        rowTypesToQuery: [
          'clients',
          'sites',
          'work-orders',
          'proposals',
          'events'
        ]
      },
      'proposal-site-name': {
        linkingTables: {
          'work-orders': {
            name: 'id',
            field: 'workOrderId'
          },
          proposals: '',
          clients: 'proposals',
          sites: 'proposals',
          events: ''
        },
        name: 'location_name',
        field: 'value',
        dynamicQueryKeys: {
          workOrdersName: 'proposals_location_name',
          weatherEventsName: ''
        },
        rowTypesToQuery: [
          'clients',
          'sites',
          'work-orders',
          'proposals',
          'events'
        ]
      },
      'weather-event': {
        linkingTables: {
          clients: 'work_orders_trips_storm_details',
          sites: 'storm_details',
          proposals: 'work_orders_trips_storm_details',
          'work-orders': 'trips_storm_details',
          events: ''
        },
        name: 'id',
        field: 'value',
        dynamicQueryKeys: {
          workOrdersName: 'trips_storm_details_id',
          weatherEventsName: 'event_id'
        },
        rowTypesToQuery: [
          'clients',
          'sites',
          'work-orders',
          'proposals',
          'events'
        ]
      },
      'weather-event-start-date': {
        linkingTables: {
          clients: 'work_orders_trips_storm_details',
          sites: 'storm_details',
          proposals: 'work_orders_trips_storm_details',
          'work-orders': 'trips_storm_details',
          events: ''
        },
        name: 'storms_start',
        field: 'value',
        dynamicQueryKeys: {
          workOrdersName: 'trips_storm_details',
          weatherEventsName: ''
        },
        rowTypesToQuery: [
          'clients',
          'sites',
          'work-orders',
          'proposals',
          'events'
        ]
      },
      'weather-event-end-date': {
        linkingTables: {
          clients: 'work_orders_trips_storm_details',
          sites: 'storm_details',
          proposals: 'work_orders_trips_storm_details',
          'work-orders': 'trips_storm_details',
          events: ''
        },
        name: 'storms_end',
        field: 'value',
        dynamicQueryKeys: {
          workOrdersName: 'trips_storm_details',
          weatherEventsName: ''
        },
        rowTypesToQuery: [
          'clients',
          'sites',
          'work-orders',
          'proposals',
          'events'
        ]
      },
      'corrigo-connection': {
        linkingTables: {
          clients: 'work_orders',
          sites: 'work_orders',
          proposals: 'work_order',
          'work-orders': '',
          events: ''
        },
        name: 'corrigo_connection_external_id',
        field: 'value',
        dynamicQueryKeys: {
        },
        rowTypesToQuery: [
          'clients',
          'sites',
          'work-orders',
          'proposals',
          'events'
        ]
      },
      'service-channel-connection': {
        linkingTables: {
          clients: 'work_orders',
          sites: 'work_orders',
          proposals: 'work_order',
          'work-orders': '',
          events: ''
        },
        name: 'trips_service_channel_connection_external_id',
        field: 'value',
        dynamicQueryKeys: {
        },
        rowTypesToQuery: [
          'clients',
          'sites',
          'work-orders',
          'proposals',
          'events'
        ]
      },
      'trip-creation-date': {
        linkingTables: {
          clients: 'work_orders',
          sites: 'work_orders',
          proposals: 'work_order',
          'work-orders': '',
          events: ''
        },
        name: 'trips_start_date',
        field: 'value',
        dynamicQueryKeys: {
          workOrdersName: 'trips_trip_start_date',
          weatherEventsName: 'trips_trip_start_date'
        },
        rowTypesToQuery: [
          'clients',
          'sites',
          'work-orders',
          'proposals',
          'events'
        ]
      },
      'work-order-service-check-in': {
        linkingTables: {
          clients: 'work_orders',
          sites: 'work_orders',
          proposals: 'work_order',
          'work-orders': '',
          events: ''
        },
        name: 'work_orders_services_effective_check_in_time',
        field: 'value',
        dynamicQueryKeys: {},
        rowTypesToQuery: [
          'clients',
          'sites',
          'work-orders',
          'proposals',
          'events',
          'trips'
        ]
      },
      'work-order-service-check-out': {
        linkingTables: {
          clients: 'work_orders',
          sites: 'work_orders',
          proposals: 'work_order',
          'work-orders': '',
          events: ''
        },
        name: 'work_orders_services_effective_check_out_time',
        field: 'value',
        dynamicQueryKeys: {},
        rowTypesToQuery: [
          'clients',
          'sites',
          'work-orders',
          'proposals',
          'events',
          'trips'
        ]
      },
      'trip-service-check-in': {
        linkingTables: {
          clients: 'work_orders',
          sites: 'work_orders',
          proposals: 'work_order',
          'work-orders': '',
          events: ''
        },
        name: 'work_orders_trips_services_effective_check_in_time',
        field: 'value',
        dynamicQueryKeys: {},
        rowTypesToQuery: [
          'clients',
          'sites',
          'work-orders',
          'proposals',
          'events',
          'trips'
        ]
      },
      'trip-service-check-out': {
        linkingTables: {
          clients: 'work_orders',
          sites: 'work_orders',
          proposals: 'work_order',
          'work-orders': '',
          events: ''
        },
        name: 'work_orders_trips_services_effective_check_out_time',
        field: 'value',
        dynamicQueryKeys: {},
        rowTypesToQuery: [
          'clients',
          'sites',
          'work-orders',
          'proposals',
          'events',
          'trips'
        ]
      }
    }
  }
}
