import { api } from '@/api'
import qs from 'qs'
import { transformRansackToEndpointQuery } from '@/utils/api'
import { AutocompletePayload } from '@/types/interfaces'
import { ClientsBulkInvoiceTaskParams } from '@/use/clients'

interface Params {
  include?: string
  q?: any
  page?: number
  perPage?: number,
  id?: number,
  clientId? : number | string
  workOrderIds?: any,
  tab?: any,
  fieldname?: string,
  contactUser?: boolean
  withoutLimit?: boolean
  tripIds?: number[]
}

export const transformed = (q: any) => transformRansackToEndpointQuery(q, [
  {
    queryField: /completed_at_date(.*)/,
    replaceWith: 'q_trips',
    key: 'completed_at_date'
  },
  {
    queryField: /vendor_invoice_exists(.*)/,
    key: 'vendor_invoice_exists',
    noPredicate: true,
    valueIndex: 0
  },
  {
    queryField: /work_order_locations_name(.*)/,
    replaceWith: 'q_trips',
    key: 'work_order_locations_name'
  },
  {
    queryField: /locations_name(.*)/,
    replaceWith: 'q_trips',
    key: 'locations_name'
  },
  {
    queryField: /work_order_work_order_name(.*)/,
    replaceWith: 'q_trips',
    key: 'work_order_work_order_name'
  },
  {
    queryField: /work_order_trade_services_name(.*)/,
    replaceWith: 'q_trips',
    key: 'work_order_trade_services_name'
  },
  {
    queryField: /work_order_trades_name(.*)/,
    replaceWith: 'q_trips',
    key: 'work_order_trades_name'
  },
  {
    queryField: /work_order_work_order_type(.*)/,
    replaceWith: 'q_trips',
    key: 'work_order_work_order_type'
  },
  {
    queryField: /work_order_locations_agreements_external_region(.*)/,
    replaceWith: 'q_trips',
    key: 'work_order_locations_agreements_external_region'
  },
  {
    queryField: /work_order_extended_status_state(.*)/,
    replaceWith: 'q_trips',
    key: 'work_order_extended_status_state'
  },
  {
    queryField: /extended_status_code(.*)/,
    replaceWith: 'q_trips',
    key: 'extended_status_code'
  },
  {
    queryField: /work_order_locations_cc_territory_name(.*)/,
    replaceWith: 'q_trips',
    key: 'work_order_locations_cc_territory_name'
  },
  {
    queryField: /storms_start(.*)/,
    replaceWith: 'q_trips',
    key: 'storms_start'
  },
  {
    queryField: /storms_end(.*)/,
    replaceWith: 'q_trips',
    key: 'storms_end'
  },
  {
    queryField: /work_order_created_at(.*)/,
    replaceWith: 'q_trips',
    key: 'work_order_created_at'
  },
  {
    queryField: /work_order_creation_date(.*)/,
    replaceWith: 'q_trips',
    key: 'work_order_creation_date'
  },
  {
    queryField: /work_order_expiration_date(.*)/,
    replaceWith: 'q_trips',
    key: 'work_order_expiration_date'
  },
  {
    queryField: /expiration_date(.*)/,
    replaceWith: 'q_trips',
    key: 'expiration_date'
  },
  {
    queryField: /work_order_follow_up_at(.*)/,
    replaceWith: 'q_trips',
    key: 'work_order_follow_up_at'
  },
  {
    queryField: /work_order_problem_code(.*)/,
    replaceWith: 'q_trips',
    key: 'work_order_category'
  },
  {
    queryField: /work_order_status_name(.*)/,
    replaceWith: 'q_trips',
    key: 'work_order_work_order_status_name'
  },
  {
    queryField: /start_date(.*)/,
    replaceWith: 'q_trips',
    key: 'start_date'
  },
  {
    queryField: /services_effective_check_in_time(.*)/,
    replaceWith: 'q_trips',
    key: 'services_effective_check_in_time'
  },
  {
    queryField: /services_effective_check_out_time(.*)/,
    replaceWith: 'q_trips',
    key: 'services_effective_check_out_time'
  }
])

export default {
  getClients: (params:any) => {
    const parameters:Params = params
    const { include, q, page, perPage, tab } = parameters
    return api.$get('/clients', {
      params: {
        include,
        q,
        page,
        per_page: perPage,
        tab
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  getNetTerms: (params:any) => {
    const parameters: Params = params
    const { id } = parameters
    return api.$get('/clients/net_terms', {
      params: {
        id
      }
    })
  },
  getTaxExempt: (params: Params) => {
    const { include, clientId } = params
    return api.$get(`/clients/${clientId}/tax_exemptions`, {
      params: {
        include
      },
      paramsSerializer: params => {
        return qs.stringify(params)
      }
    })
  },
  getById: (params:any) => {
    const parameters:Params = params
    const { include, id } = parameters

    return api.$get(`/clients/${id}`, {
      params: {
        include
      },
      paramsSerializer: params => {
        return qs.stringify(params)
      }
    })
  },
  blockOrUnblockClient: (params: any) => {
    const { clientId, path = 'block' } = params
    return api.$patch(`/clients/${clientId}/${path}`, {
      params: {
        id: clientId
      },
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },
  rejectClient: (clientId: any) => api.$delete(`/clients/${clientId}/reject`),
  acceptClient: (clientId: any) => api.$patch(`/clients/${clientId}/approve`),
  getInvoiceableClients: (params:any) => {
    const parameters:Params = params
    const { include, q, page, perPage, workOrderIds } = parameters

    return api.$get('/clients/invoiceables', {
      params: {
        include,
        q,
        workOrderIds,
        page,
        per_page: perPage,
        ...transformed(q)
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  getRecruitTemplates: () => {
    return api.$get('/clients/recruit_templates', {
      params: {
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  getClientBillingMethods: (params:Params) => {
    const { include, q, page, perPage } = params
    return api.$get('/clients/billing_methods', {
      params: {
        include,
        q,
        page,
        per_page: perPage
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  inviteClient: (companyId: number, include: string) => {
    return api.$post('/clients', {
      company_id: companyId
    },
    {
      params: {
        include
      },
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },
  postClient: (param: any) => {
    return api.$post('/clients',
      param, { headers: { content_type: 'multipart/form-data' } })
  },
  updateClient: (id: string, param: any) => {
    return api.$patch(`/clients/${id}`,
      param, { headers: { content_type: 'multipart/form-data' } })
  },
  getClientsNotes: (params:any) => {
    const parameters:Params = params
    const { include, id } = parameters

    return api.$get(`/clients/${id}/notes`, {
      params: {
        include
      },
      paramsSerializer: params => {
        return qs.stringify(params)
      }
    })
  },
  addClientNote: (params: any) => {
    return api.$post(`/clients/${params.id}/notes`, {
      note: params.note,
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },
  editClientNote: (params: any) => {
    return api.$patch(`/clients/${params.id}/notes/${params.noteId}`, {
      note: params.note,
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },
  deleteClientNote: (params: any) => {
    return api.$delete(`/clients/${params.id}/notes/${params.noteId}`, {
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },
  downloadSampleExcel: () => {
    const link = document.createElement('a')
    link.href = window.location.origin + '/api/v2/clients/download_sample_excel'
    link.setAttribute(
      'download',
      'sample_template.xlsx'
    )
    link.click()
    link.remove()
  },
  getClientsCustomFields: (params:any) => {
    const parameters:Params = params
    const { id } = parameters
    return api.$get(`/clients/${id}/custom_fields`)
  },
  getClientContacts: (params:any, isPublic: boolean) => {
    const parameters:Params = params
    const { include, id, contactUser } = parameters
    let url = `/clients/${id}/contacts`
    if (isPublic) {
      url = `/clients/${id}/contacts/public_contacts`
    }
    return api.$get(url, {
      params: {
        include,
        contactUser
      },
      paramsSerializer: params => {
        return qs.stringify(params)
      }
    })
  },
  addClientContacts: (params: any) => {
    return api.$patch(`/clients/${params.id}`, params.contactObject, {
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },
  updateClientContacts: (params: any) => {
    return api.$patch(`clients/${params.id}/contacts/${params.contactId}`, params.contactObject, {
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },
  deleteClientContacts: (params: any) => {
    return api.$delete(`/clients/${params.id}/contacts/${params.contactId}`, {
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },
  getClientsMaterials: (params:any) => {
    const parameters:Params = params
    const { include, clientId } = parameters

    return api.$get(`/clients/${clientId}/material_rates`, {
      params: {
        include
      },
      paramsSerializer: params => {
        return qs.stringify(params)
      }
    })
  },
  addClientMaterials: (params: any) => {
    return api.$post(`/clients/${params.id}/material_rates`, params.materialObject, {
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },
  updateClientMaterials: (params: any) => {
    return api.$patch(`clients/${params.id}/material_rates/${params.materialRateId}`, params.materialObject, {
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },
  bulkDeleteMaterials: (materialRateIds: Array<string>, clientId: number) => {
    return api.$delete(`clients/${clientId}/material_rates/bulk_destroy`, {
      params: {
        material_rate_ids: materialRateIds.join(',')
      }
    })
  },
  getAddresses: (params: any) => {
    return api.$get(`/clients/${params.id}/addresses`, {
      paramsSerializer: (params) => {
        return qs.stringify(params)
      }
    })
  },
  inviteClientEmployee: (params: any) => {
    return api.$post('/company_users/invite_employee', {
      ...params.employeeObject
    },
    {
      params: {
      },
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },
  updateClientDetails: (id: string, params:any) => {
    return api.$patch(`clients/${id}}`, { client: params.payload }, {
      params: {
        include: params.include
      },
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },
  updateProfileLogo: (params: any, id: number) => {
    return api.$patch(`clients/${id}}?include=logo`, params, { headers: { content_type: 'multipart/form-data' } })
  },
  getClientsLicense: (param: any) => {
    return api.$get(`clients/${param.clientId}/licenses`, {
      paramsSerializer: params => {
        return qs.stringify(params)
      }
    })
  },
  getPublicAddresses: (params: any) => {
    return api.$get(`/clients/${params.id}/addresses/public_addresses`, {
      paramsSerializer: (params) => {
        return qs.stringify(params)
      }
    })
  },
  getClientAddresses: (params: any) => {
    const parameters: Params = params
    const { include, q, page, perPage, tab, fieldname } = parameters
    return api.$get('/client/addresses', {
      params: {
        include,
        q,
        page,
        tab,
        per_page: perPage,
        fieldname
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  getClientLocations: (params: any) => {
    const parameters: Params = params
    const { include, q, page, perPage, tab } = parameters
    return api.$get('/client/locations', {
      params: {
        include,
        q,
        page,
        tab,
        per_page: perPage
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  getClientLocationsAddress: (params: any) => {
    const parameters: Params = params
    const { include, q, page, perPage, tab, fieldname } = parameters
    return api.$get('/client/location_addresses', {
      params: {
        include,
        q,
        page,
        tab,
        per_page: perPage,
        fieldname
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  clientsAutoComplete: (params: AutocompletePayload) => {
    const { field, terms, predicate, tab, page, perPage } = params
    return api.$post('/clients/autocomplete', {
      field,
      terms,
      predicate,
      tab,
      page,
      perPage
    },
    {
      headers: {
        'Content-Type': 'application/json'
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  clientsSearch: (params:any) => {
    const parameters:Params = params
    const { include, q, page, perPage, tab } = parameters
    return api.$post('/clients/search', {
      include,
      q,
      page,
      per_page: perPage,
      tab
    },
    {
      headers: {
        'Content-Type': 'application/json'
      },
      paramsSerializer: (params: any) => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  invoiceablesClientsSearch: (params:any) => {
    const parameters:Params = params
    const { include, q, page, perPage, workOrderIds, withoutLimit, tripIds } = parameters
    return api.$post('/clients/invoiceables', {
      include,
      q,
      workOrderIds,
      tripIds,
      withoutLimit,
      page,
      per_page: perPage,
      ...transformed(q)
    },
    {
      headers: {
        'Content-Type': 'application/json'
      },
      paramsSerializer: (params: any) => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  // getClientsPhotos: (clientId: number, params: Params) => {
  //   return api.$get(`/clients/${clientId}/trip_photos`, {
  //     params,
  //     paramsSerializer: params => {
  //       return qs.stringify(params, { arrayFormat: 'brackets' })
  //     }
  //   })
  // },
  // getClientsPhotosCount: (clientId: number) => {
  //   return api.$get(`/clients/${clientId}/trip_photos/count`)
  // },
  // photoPreview: (params: { ids: number[], tab: string }) => {
  //   return api.$get('/clients/photos_preview', {
  //     params: {
  //       withoutCount: true,
  //       ...params
  //     },
  //     paramsSerializer: params => {
  //       return qs.stringify(params, { arrayFormat: 'brackets' })
  //     }
  //   })
  // },
  createBulkInvoiceTask: (params: ClientsBulkInvoiceTaskParams) => {
    return api.$post('/clients/bulk_invoice_task', {
      ...params, excludePayload: true, receivable: true
    }, {
      paramsSerializer: (params: any) => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  }
}
