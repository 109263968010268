import { VCheckbox } from 'vuetify/lib/components/VCheckbox';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "uc-tabs-prepend-actions d-flex align-center flex-1 px-3"
  }, [[_vm.showSelectAll ? _c(VCheckbox, {
    staticClass: "checkbox",
    attrs: {
      "hide-details": "",
      "value": _vm.isBulkSelected,
      "indeterminate": _vm.isIndeterminate
    },
    on: {
      "change": function ($event) {
        return _vm.$emit('bulk-select', _vm.isBulkSelected);
      }
    }
  }) : _vm._e(), _vm.hasButtonMenu ? _c('uc-button-menu', _vm._g(_vm._b({
    staticClass: "mx-2",
    attrs: {
      "disabled": _vm.noneSelected
    }
  }, 'uc-button-menu', _vm.componentMenuProps, false), _vm.menuListenerObj)) : _vm._e(), _vm.actions.length && !_vm.$vuetify.breakpoint.xsOnly ? _c('div', {
    staticClass: "actions d-flex align-center ml-2"
  }, _vm._l(_vm.calculatedActions, function (action, index) {
    return _c('uc-tooltip', {
      key: index,
      attrs: {
        "disabled": _vm.noneSelected,
        "position": "top",
        "text": action.toolTip
      }
    }, [_c('uc-button', _vm._b({
      key: index,
      staticClass: "mr-1",
      attrs: {
        "disabled": _vm.noneSelected,
        "state": 'text',
        "size": 'md',
        "type": 'text',
        "icon": true,
        "has-hover": true
      },
      on: {
        "click": function ($event) {
          return _vm.$emit(action.eventName);
        }
      }
    }, 'uc-button', action, false))], 1);
  }), 1) : _vm._e()], _vm._t("append")], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };