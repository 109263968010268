import { companyRoles } from '@/constants/permissions'
import { useApp } from './app'
import { lowerCase } from 'lodash'

interface MappingAnnotations {
  [key: string]: {
    [key in companyRoles]?: string
  }
}
const MAPPINGS: MappingAnnotations = {
  approve: { broker: 'confirm', vendor: 'confirm' },
  reject: { broker: 'deny', vendor: 'deny' },
  approval: { broker: 'confirmation', vendor: 'confirmation' },
  rejection: { broker: 'denial', vendor: 'denial' },
  approved: { broker: 'confirmed', vendor: 'confirmed' },
  rejected: { broker: 'denied', vendor: 'denied' }
}

const { companyType } = useApp()

export const useTextMapping = (compType?: companyRoles) => {
  const mapText = (text: string): string => {
    const currentCompanyType = compType || companyType.value
    if (!currentCompanyType) return text

    const lowerCasedText = lowerCase(text)
    const convertedText = MAPPINGS[lowerCasedText]?.[currentCompanyType]
    return (convertedText && convertedText !== '' ? convertedText : text)
  }

  return { mapText }
}
