import { api } from '@/api'
import { WOFinancialAttachmentDeleteParam } from '@/types/interfaces/api-v2/attachment'
import { NewWorkOrder, PatchWorkOrderParams, PostPurchaseOrderAssignmentParams } from '@/types/interfaces/api-v2/work-order'
import { TripPatchParams } from '@/types/interfaces/api-v2/trip'
import qs from 'qs'
import { AutocompletePayload } from '@/types/interfaces/api-v2/autocomplete'
import { getPriorityParams } from './priorities'
import { DefaultTypesParam, workOrderPhotosParams, WorkOrdersBulkInvoiceTasksParams } from '@/use/work-orders'

export interface getWorkOrdersParams {
  scope?: string
  include?: string
  q?: any
  page?: number
  perPage?: number
  workOrderId?: string | number,
  clientId?: number | string,
  vendorId?: number | string,
  workOrderIds?: Array<string>
  scopeContext?: string
  includeAttributesForCustomStatus?: boolean
  includeCountsForWoDetails?: boolean,
  withInvoicePoStatuses?: boolean,
  includeAttributes?: boolean,
  noteId?: string,
  targetId?: string,
  locationId?: number | string
  includeMaterialAttribute?: boolean
  sitePermanentInstruction?: boolean
  profileDetailsRequired?: boolean
  actionDetailsRequired?: boolean
  tripIds?: Array<string | number>
  includeContactsDetails?: boolean
  sort?: string,
  withoutCount?: boolean
  assigneeDetailsRequiredWithTrip?: boolean
  includeUnreadNotesCount?: boolean
  includePhotosCountWithTrip?: boolean
  contactUser?: boolean
  tripScope?: string
  includeTripsCount?: boolean
  excludeDefaultInclude?: boolean
  excludeLocationTradeServices?: boolean
  excludeLocationFullAddress?: boolean
  excludeLocationTimezone?: boolean
  ids?: number[]
}

interface createWorkOrderParams {
  workOrder?: NewWorkOrder
  include?: string
  scopeContext?: string
  includeAttributesForCustomStatus?: boolean
  includeCountsForWoDetails?: boolean,
  withInvoicePoStatuses?: boolean,
  includeAttributes?: boolean,
  includeContactsDetails: boolean
}

export default {
  get: ({ scope, scopeContext, include, q, page, perPage, includeAttributesForCustomStatus, includeMaterialAttribute, profileDetailsRequired, actionDetailsRequired, includeAttributes, sort, withoutCount, tripScope, includeUnreadNotesCount, includeTripsCount, excludeLocationTradeServices, excludeLocationFullAddress, excludeLocationTimezone, ids }: getWorkOrdersParams) => {
    return api.$get('/work_orders', {
      params: {
        scope,
        scopeContext,
        include,
        q,
        page,
        perPage,
        include_can_custom_status_change: includeAttributesForCustomStatus,
        includeMaterialAttribute,
        profileDetailsRequired,
        actionDetailsRequired,
        includeAttributes,
        sort,
        withoutCount,
        tripScope,
        includeUnreadNotesCount,
        includeTripsCount,
        excludeLocationTradeServices,
        excludeLocationFullAddress,
        excludeLocationTimezone,
        ids
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  getById: ({ scope, include, q, page, perPage, workOrderId, scopeContext, includeCountsForWoDetails, withInvoicePoStatuses, includeAttributes, includeAttributesForCustomStatus, includeMaterialAttribute, sitePermanentInstruction, contactUser, assigneeDetailsRequiredWithTrip, includePhotosCountWithTrip, actionDetailsRequired }: getWorkOrdersParams) => {
    return api.$get(`/work_orders/${workOrderId}`, {
      params: {
        scope,
        include,
        q,
        page,
        per_page: perPage,
        include_counts_for_wo_details: includeCountsForWoDetails,
        with_invoice_po_statuses: withInvoicePoStatuses,
        include_attributes_for_wo_details: includeAttributes,
        scope_context: scopeContext,
        include_can_custom_status_change: includeAttributesForCustomStatus,
        include_material_attribute: includeMaterialAttribute,
        sitePermanentInstruction,
        assigneeDetailsRequiredWithTrip,
        contactUser,
        includePhotosCountWithTrip,
        actionDetailsRequired
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  getTripsById: ({ scope, include, q, page, perPage, workOrderId, excludeDefaultInclude, sort }: getWorkOrdersParams) => {
    return api.$get(`/work_orders/${workOrderId}/trips`, {
      params: {
        scope,
        include,
        q,
        page,
        perPage,
        excludeDefaultInclude,
        sort
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  getInvoiceablesByClientId: ({ scope, include, q, page, perPage, clientId, workOrderIds, tripIds }: getWorkOrdersParams) => {
    return api.$get(`/clients/${clientId}/work_orders/invoiceables`, {
      params: {
        scope,
        workOrderId: workOrderIds,
        tripIds,
        include,
        q,
        page,
        perPage
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  getBillablesByVendorId: ({ scope, include, q, page, perPage, vendorId, workOrderIds, tripIds }: getWorkOrdersParams) => {
    return api.$get(`/vendors/${vendorId}/work_orders/billables`, {
      params: {
        scope,
        workOrderId: workOrderIds,
        tripIds,
        include,
        q,
        page,
        perPage
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  getWoTripReports: ({ scope, include, q, page, perPage, workOrderId, workOrderIds, tripIds }: getWorkOrdersParams) => {
    return api.$get('/trip_reports/new', {
      params: {
        scope,
        include,
        q,
        page,
        per_page: perPage,
        workOrderId,
        workOrderIds,
        tripIds
      }
    })
  },
  getWOStatus: ({ scope, include, q, page, perPage }: getWorkOrdersParams) => {
    return api.$get('/work_orders/status_list', {
      params: {
        q,
        page,
        per_page: perPage
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  WorkOrdersAutoComplete: (params: AutocompletePayload) => {
    const { field, terms, predicate, page, invoiceables, perPage, allRecords } = params
    return api.$post('/work_orders/autocomplete', {
      field,
      terms,
      predicate,
      page,
      invoiceables,
      perPage,
      allRecords
    },
    {
      headers: {
        'Content-Type': 'application/json'
      },
      paramsSerializer: (params: any) => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  downloadSampleUploadExcel: () => {
    const downloadHref = window.location.origin + '/api/v2/work_orders/download_sample_excel'
    window.open(downloadHref, '_blank')
  },
  downloadFilteredExcel: (params: { q: any }) => {
    let url = ''
    const woUrl = new URL('/api/v2/work_orders/download_filtered_excel', window.location.origin)
    woUrl.search = qs.stringify({ scope_context: 'all', q: params.q }, { arrayFormat: 'brackets' })
    url = woUrl.pathname + woUrl.search
    window.open(url, '_blank')
  },
  patch: (workOrderId: number | string, include: string, params: any) => api.$patch(`/work_orders/${workOrderId}`, {
    workOrder: params,
    include,
    paramsSerializer: (params: any) => {
      return qs.stringify(params, { arrayFormat: 'brackets' })
    }
  }),
  getReasonsIfNonCancellable: (workOrderId: number | string) => api.$get(`/work_orders/${workOrderId}/validate_resources_on_cancel`),
  activeCustomStatuses: ({ workOrderId, q, page, perPage }: getWorkOrdersParams) => api.$get(`/work_orders/${workOrderId}/active_custom_statuses`, {
    params: {
      q,
      page,
      per_page: perPage
    },
    paramsSerializer: params => {
      return qs.stringify(params, { arrayFormat: 'brackets' })
    }
  }),
  getWoActivities: ({ workOrderId, scope, include, q, page, perPage }: getWorkOrdersParams) => {
    return api.$get(`/work_orders/${workOrderId}/work_order_activities`, {
      params: {
        scope,
        include,
        q,
        page,
        per_page: perPage
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  fetchWorkOrdersDescription: (workOrderId: number | string, tripIds: (number | string)[], invoiceId: number | string) => {
    return api.$get(`/work_orders/${workOrderId}/get_wo_description`, {
      params: {
        tripIds,
        invoiceId
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  workOrdersSearch: ({ scope, include, q, page, perPage }: getWorkOrdersParams) => {
    return api.$post('/work_orders/search', {
      scope,
      include,
      q,
      page,
      per_page: perPage
    },
    {
      headers: {
        'Content-Type': 'application/json'
      },
      paramsSerializer: (params: any) => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  getWorkOrderNotes: ({ workOrderId, targetId, include, q, page, perPage }: getWorkOrdersParams) => {
    return api.$get(`/work_orders/${workOrderId}/notes/index_v2`, {
      params: {
        targetId,
        include,
        q,
        page,
        per_page: perPage
      }
    })
  },
  getWOPriorities: ({ scope, include, q, page, perPage }: getPriorityParams) => {
    return api.$get('/work_orders/priorities/selectable', {
      params: {
        scope,
        include,
        q,
        page,
        per_page: perPage
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  getProblemType: ({ q, page, perPage }: getWorkOrdersParams) => {
    return api.$get('/settings/work_orders/categories', {
      params: {
        q,
        page,
        per_page: perPage
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  deleteWorkOrderNote: ({ workOrderId, noteId }: getWorkOrdersParams) => {
    return api.$delete(`/work_orders/${workOrderId}/notes/${noteId}`, {
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },
  patchWorkOrder: ({ workOrder, id, include, includeAttributesForCustomStatus, includeContactsDetails }: PatchWorkOrderParams) => {
    return api.$patch(`/work_orders/${id}`, {
      work_order: workOrder,
      include,
      include_can_custom_status_change: includeAttributesForCustomStatus,
      include_contacts_details: includeContactsDetails,
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },
  updateTrip: (workOrderId: number | string, tripId: number | string, params?: TripPatchParams, include?: string, assigneeDetailsRequired?: boolean, includeCompliance?: boolean, includeCanApproveNonCompliantVendor?: boolean) => {
    return api.$patch(`/work_orders/${workOrderId}/trips/${tripId}`, {
      trip: params,
      include,
      assignee_details_required: assigneeDetailsRequired,
      includeCompliance,
      includeCanApproveNonCompliantVendor,
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },
  getWorkOrderCompanies: ({ workOrderId }: getWorkOrdersParams) => {
    return api.$get(`/work_orders/${workOrderId}/notes/company_note_actors`, {
      paramsSerializer: params => {
        return qs.stringify(params)
      }
    })
  },
  getWOTradesServices: ({ perPage, page, include, workOrderId }: getWorkOrdersParams) => api.$get(`/work_orders/${workOrderId}`, {
    params: {
      page,
      per_page: perPage,
      include
    },
    paramsSerializer: params => {
      return qs.stringify(params)
    }
  }),
  getWOTrades: ({ perPage, page, include, workOrderId }: getWorkOrdersParams) => api.$get(`/work_orders/${workOrderId}/work_order_trades`, {
    params: {
      page,
      per_page: perPage,
      include
    },
    paramsSerializer: params => {
      return qs.stringify(params)
    }
  }),
  deleteFinancialAttachment: ({ workOrderId, resourceId, resourceType }: WOFinancialAttachmentDeleteParam) => {
    return api.$delete(`/work_orders/${workOrderId}/${resourceType}s/${resourceId}`, {
      paramsSerializer: (params) => {
        return qs.stringify(params)
      }
    })
  },
  patchWoCustomStatus: (workOrderId: number | string, include: string, params: any, includeAttributesForCustomStatus: boolean) => api.$patch(`/work_orders/${workOrderId}/update_custom_status`, {
    workOrder: params,
    include,
    include_can_custom_status_change: includeAttributesForCustomStatus,
    paramsSerializer: (params: any) => {
      return qs.stringify(params, { arrayFormat: 'brackets' })
    }
  }),
  getRecentWorkOrdersForLocation: ({ include, q, page, perPage, locationId }: getWorkOrdersParams) => api.$get(`/locations/${locationId}/work_orders/work_orders_for_defined_timeframe`, {
    params: {
      include,
      q,
      page,
      per_page: perPage
    },
    paramsSerializer: params => {
      return qs.stringify(params)
    }
  }),
  createWorkOrder: ({ workOrder, include, scopeContext, includeAttributesForCustomStatus, includeCountsForWoDetails, withInvoicePoStatuses, includeAttributes, includeContactsDetails } : createWorkOrderParams) => {
    return api.$post('/work_orders', {
      work_order: workOrder,
      include,
      include_counts_for_wo_details: includeCountsForWoDetails,
      with_invoice_po_statuses: withInvoicePoStatuses,
      include_attributes_for_wo_details: includeAttributes,
      scope_context: scopeContext,
      include_can_custom_status_change: includeAttributesForCustomStatus,
      include_contacts_details: includeContactsDetails
    })
  },
  addTripAttachment: (workOrderId: number, tripId: number | string, requestBody: FormData) => api.$patch(
    `work_orders/${workOrderId}/trips/${tripId}/`, requestBody, {
      headers:
      {
        content_type: 'multipart/form-data'
      },
      paramsSerializer: (params: any) => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    }
  ),
  createWorkOrderNote: (workOrderId: number, requestBody: FormData, include = '') => {
    return api.$post(`/work_orders/${workOrderId}/notes/create_v2`, requestBody, {
      params: {
        include
      },
      headers: {
        content_type: 'multipart/form-data'
      },
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },
  uploadBulkAttachment: (workOrderId: number | string, requestBody: FormData) => api.$patch(`/work_orders/${workOrderId}`, requestBody, {
    headers: {
      content_type: 'multipart/form-data'
    },
    paramsSerializer: (params: any) => {
      return qs.stringify(params)
    }
  }),
  bulkCreatePurchaseOrderAssignments: (workOrderId: number | string, params: PostPurchaseOrderAssignmentParams) => {
    return api.$post(`/work_orders/${workOrderId}/purchase_order_assignments/bulk_create`, params)
  },
  patchWorkOrderForProposals: (workOrderId: number | string, proposalIds: (number | string)[], type: string) => {
    const proposals = proposalIds.map(proposal => {
      return {
        proposal_id: proposal,
        proposal_type: type
      }
    })
    return api.$post(`/work_orders/${workOrderId}/proposal_assignments/bulk_create`,
      {
        proposal_assignments: proposals,
        paramsSerializer: (params: any) => {
          return qs.stringify(params)
        }
      })
  },
  getWorkOrderManager: ({ workOrderId, q, page, perPage, scopeContext, include }: getWorkOrdersParams) => {
    return api.$get(`/work_orders/${workOrderId}/assigned_users`, {
      params: {
        q,
        page,
        per_page: perPage,
        scope_context: scopeContext,
        include
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  getWorkOrderDefaultTypes: ({ tradeServiceId, locationId }: DefaultTypesParam) => {
    return api.$get(`/trade_services/${tradeServiceId}/defaults`, {
      params: {
        locationId
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  getWorkOrdersCount: (filterParams: any) => {
    return api.$get('/work_orders/count', {
      params: filterParams,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  getWorkOrdersPhotos: (woId: number, params: workOrderPhotosParams) => {
    return api.$get(`/work_orders/${woId}/photos`, {
      params,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  getWorkOrdersPhotosCount: (woId: number) => {
    return api.$get(`/work_orders/${woId}/photos/count`)
  },
  getProfitLossDetail: (param: any) => {
    return api.$get(`/work_orders/${param.id}/profit_loss_detail`, {
      params: {
        required: param.required
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  getWorkOrderResourcesCount: (workOrderId: number) => {
    return api.$get(`/work_orders/${workOrderId}/resources_count`, {
      params: { scope_context: 'all' }
    })
  },
  getWorkOrderRequiredActions: (workOrderId: number, actionDetailsRequired: boolean) => {
    return api.$get(`/work_orders/${workOrderId}/required_actions`, {
      params: {
        action_details_required: actionDetailsRequired
      },
      paramsSerializer: (params: any) => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  marginPercentage: (params: { ids: number[], scopeContext: string }) => {
    return api.$get('/work_orders/margin_percentage', {
      params: {
        withoutCount: true,
        ...params
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  photoPreview: (params: { ids: number[], scopeContext: string }) => {
    return api.$get('/work_orders/photos_preview', {
      params: {
        withoutCount: true,
        ...params
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  workOrderContactDetail: (params: { ids: number[], scopeContext: string }) => {
    return api.$get('/work_orders/contact_details', {
      params: {
        withoutCount: true,
        ...params
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  createBulkInvoiceTask: (params: WorkOrdersBulkInvoiceTasksParams) => {
    return api.$post('/work_orders/bulk_invoice_task', {
      ...params, excludePayload: true
    }, {
      paramsSerializer: (params: any) => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  }
}
