import { inject, reactive, ref, watch } from 'vue'
import repositories from '@/repositories'
import { useApp } from '@/use/app'
import {
  TRIPS_ASSIGNEE_TABLE_HEADERS,
  TRIPS_TABLE_HEADERS
} from '@/constants/headers/trip'
import { baseGetResponse } from '@/utils/api'
import { AutocompletePayload, BackgroundTask, MenuAction, tabInfo, Trip, Vendor, WorkOrder } from '@/types/interfaces'
import { useListRow } from '../list-rows'
import {
  ALL_STATUSES,
  technicianTripActionData,
  technicianTripPrimaryActionData,
  TRIP_BROKER_ACTIONS,
  TRIP_CLIENT_ACTIONS,
  TRIP_CONTRACTOR_ACTIONS,
  TRIP_CREATE_ACTION,
  TRIP_INTERMEDIARY_BROKER_ACTIONS,
  TRIP_SAVE_DISPATCH_ACTION,
  tripPrimaryActionData,
  TripResourceAction,
  TripBulkActions
} from '@/constants/actions-by-resource/trips'
import { WorkOrderCompanyRole } from '@/types/enums/work-order/company_role'
import { filter, findIndex, kebabCase, sortBy, cloneDeep, difference, isEmpty } from 'lodash'
import { TripAssigneeType } from '@/types/enums/trip/assignee-type'
import { AutocompleteResponse } from '@/types/interfaces/api-v2/autocomplete'
import { WORK_ORDER } from '@/constants/resource-types'
import { NewTrip, PostTripNoteParams } from '@/types/interfaces/api-v2/trip'
import { BackgroundTaskSources, DetailsPanelButtonState, ExtendedStatus, DetailsPanelMode } from '@/types/enums'
import { generateDefaultDateTime, AllowedAttrTypes } from '@/utils/work-order/trips/dates'
import { PriorityObj } from '@/types/interfaces/api-v2/work-order/priority'
import { PageTab } from '@/constants/top-level-tabs'
import { allTripsTab, mustScheduleTab } from '@/constants/nested-tabs'
import { CreateOrUpdateTripParams, TripCountParam } from '@/repositories/trips'
import { AxiosResponse } from 'axios'
import { useTripsVerification } from '@/use/trips/verification'
import { useSession } from '@/use/session'
import { useCompanySettings } from '@/use/company-settings'
import { useDetailsPanel } from '../details-panel'
import { useTripsTimecard } from './timecard'
import { AttachmentInfo } from '@/use/attachments'
import { BROKER } from '@/constants/permissions'

const tripModalRef = ref<any>(null)
const trips = reactive({
  loading: false,
  list: []
})
const { isBroker, isClient } = useApp()

interface getTripParams {
  q?: object
  include?: string
}

export interface TripsCreateOrUpdateResponse {
  success: boolean
  errors?: string | object
  data?: Trip
  totalPages?: number
  totalCount?: number
  currentPage?: number
}

export interface BulkTripActionEventData {
  source?: null | string,
  query?: null | string,
  tripIds?: null | string | number[],
  tab?: null | string
}

const renderBulkDisptachTripModal = ref(false)
const renderBulkVerifyTripModal = ref(false)
const renderBulkUnverifyTripModal = ref(false)
const bulkTripDispatchEventData = ref<BulkTripActionEventData>({})
const bulkVerifyEventData = ref<BulkTripActionEventData>({})
const bulkUnverifyEventData = ref<BulkTripActionEventData>({})

const renderBulkGenerateTripReport = ref(false)
const bulkGenerateTripReportData = ref<Trip[]>([])

let tripPrimaryMenuActionsMap = {} as Record<string, MenuAction[]>
let technicianTripMenuActionsMap = {} as Record<string, MenuAction[]>
let technicianTripPrimaryMenuActionsMap = {} as Record<string, MenuAction[]>

export interface DefaultBillingMethodParams {
  workOrderId: number | string
  tradeServiceId: number | string
}

interface TripRow {
  props: {
    trip: Trip
  }
}

export interface TripsBulkInvoiceTasksParams {
  scopeContext: string
  receivable?: boolean
  payable?: boolean
  filterScope?: string
  ids?: number[]

  q?: any
}

const renderTripsBatchInvoiceConfiguration = ref(false)
const defaultTripsBulkInvoiceTasksParams:TripsBulkInvoiceTasksParams = {
  scopeContext: '',
  receivable: false,
  payable: false,
  filterScope: undefined,
  ids: [],
  q: null
}
const tripsBulkInvoiceTasksParams = ref<TripsBulkInvoiceTasksParams>(defaultTripsBulkInvoiceTasksParams)

watch(() => renderTripsBatchInvoiceConfiguration, (newVal) => {
  if (!newVal) {
    tripsBulkInvoiceTasksParams.value = defaultTripsBulkInvoiceTasksParams
  }
})

// Define the structure for bulk action configuration
interface BulkConfig {
  type: string
}

// Define the structure for the action
interface Action {
  value: string
  requiresConfirmation: boolean
  actionFn: (resources: Trip[], additionalParams: any) => void
  bulkConfig: BulkConfig
  companyRoles: string[]
  userRoles: string[]
}
export type HeaderActionKey = 'invoiceable' | 'billable' | 'invoiceableAndBillable'

const setBulkInvoiceTaskParams = (resources: Trip[], additionalParams: any) => {
  const tripIds = resources.map((workOrder: Trip) => Number(workOrder.id))
  if (additionalParams.bulkSelectionData?.isBulkExclusive) {
    tripsBulkInvoiceTasksParams.value.q = additionalParams.exportQuery()
    tripsBulkInvoiceTasksParams.value.filterScope = additionalParams.selectAllParams.filterScope
    delete tripsBulkInvoiceTasksParams.value.ids
  } else {
    tripsBulkInvoiceTasksParams.value.ids = tripIds
  }
  renderTripsBatchInvoiceConfiguration.value = true
}

const ACTION_FILTER_HEADER_ACTIONS: Record<HeaderActionKey, Action> = {
  invoiceable: {
    value: 'generate-receivable',
    requiresConfirmation: false,
    companyRoles: [BROKER],
    userRoles: ['invoice', 'sites', 'customers'],
    actionFn: (resources: Trip[], additionalParams: any) => {
      tripsBulkInvoiceTasksParams.value.receivable = true
      delete tripsBulkInvoiceTasksParams.value.payable
      setBulkInvoiceTaskParams(resources, additionalParams)
    },
    bulkConfig: {
      type: 'menu'
    }
  },
  billable: {
    value: 'generate-payable',
    requiresConfirmation: false,
    companyRoles: [BROKER],
    userRoles: ['invoice', 'sites', 'vendors'],
    actionFn: (resources: Trip[], additionalParams: any) => {
      tripsBulkInvoiceTasksParams.value.payable = true
      delete tripsBulkInvoiceTasksParams.value.receivable
      setBulkInvoiceTaskParams(resources, additionalParams)
    },
    bulkConfig: {
      type: 'menu'
    }
  },
  invoiceableAndBillable: {
    value: 'generate-invoices',
    companyRoles: [BROKER],
    userRoles: ['invoice', 'sites', 'customers', 'vendors'],
    requiresConfirmation: false,
    actionFn: (resources: Trip[], additionalParams: any) => {
      tripsBulkInvoiceTasksParams.value.receivable = true
      tripsBulkInvoiceTasksParams.value.payable = true
      setBulkInvoiceTaskParams(resources, additionalParams)
    },
    bulkConfig: {
      type: 'menu'
    }
  }
}

const getActionFilterHeaderActions = (headerAction: HeaderActionKey) => ACTION_FILTER_HEADER_ACTIONS[headerAction]

const tripsPerformBulkAction = (resources: Trip[] | TripRow[], action: string, query = '') => {
  if (resources.length === 0) return
  const tripIds = (resources as Trip[]).map((resource: Trip) => Number(resource.id))
  switch (action) {
    case 'dispatch': {
      if (tripsTableRef.value?.bulkSelectionData?.isBulkExclusive) {
        query = JSON.stringify(tripsTableRef.value.bulkActionQuery())
      }
      bulkTripDispatchEventData.value = query !== ''
        ? { source: BackgroundTaskSources.QUERY, query }
        : { source: BackgroundTaskSources.IDS, tripIds, query }
      renderBulkDisptachTripModal.value = true
      break
    }
    case 'download_trips_report': {
      bulkGenerateTripReportData.value = (resources as TripRow[]).map(e => e.props.trip)
      renderBulkGenerateTripReport.value = true
      break
    }
    case 'verify':
    case 'unverify': {
      const eventData = action === 'unverify' ? bulkUnverifyEventData : bulkVerifyEventData
      const renderModal = action === 'unverify' ? renderBulkUnverifyTripModal : renderBulkVerifyTripModal

      if (tripsTableRef.value?.bulkSelectionData?.isBulkExclusive) {
        query = JSON.stringify(tripsTableRef.value.bulkActionQuery())
      }

      eventData.value = query !== ''
        ? { source: BackgroundTaskSources.QUERY, query }
        : { source: BackgroundTaskSources.IDS, tripIds, query }

      renderModal.value = true
      break
    }
    default:
      break
  }
}

export interface TripReportParams {
  workOrderId?: string,
  contentTypes?: Array<string>,
  reportFormat?: string,
  exportType?: string,
  tripIds?: string,
  workOrderIds?: Array<string>,
  workOrderTripReport?: boolean
  tripReportTypes?: any
}
export interface TripReportTemplateParams {
  reportType?: string,
  name?: string,
  headers?: Array<string>,
  userId?: string
}

const getTripsTableHeaders = (canSelect: boolean, isWithinWorkOrder = false) => {
  return TRIPS_TABLE_HEADERS(canSelect, isWithinWorkOrder)
}

const getTripAssigneeHeaders = () => {
  return TRIPS_ASSIGNEE_TABLE_HEADERS()
}

const cancelTrip = async (workOrderId:number|string, tripId:number, reason:string, isSendEmailNotification = false) => {
  try {
    const params = {
      trip: {
        sendTripCancelEmail: isSendEmailNotification,
        cancelationReasonAttributes: {
          reason
        }
      }
    }
    const res = await repositories.trips.cancelTrip(params, workOrderId, tripId)
    return { data: res.trip as Trip }
  } catch (err) {
    return { data: false }
  }
}

export const defaultTripObject = (workOrder: WorkOrder): NewTrip => {
  const locationTz = workOrder.locations?.[0]?.timezone
  const woStartDate = workOrder.creationDate?.toString() || workOrder.createdAt.toString()
  return {
    id: -1,
    position: -1,
    workOrderId: workOrder.id,
    startDate: generateDefaultDateTime(AllowedAttrTypes.ETA, workOrder.priorityObj, locationTz, woStartDate, false),
    endDate: generateDefaultDateTime(AllowedAttrTypes.ETC, workOrder.priorityObj, locationTz, woStartDate, false),
    expirationDate: generateDefaultDateTime(AllowedAttrTypes.CLOSE_DATE, workOrder.priorityObj, locationTz, woStartDate, false),
    dispatchTime: '',
    nte: -1,
    equipments: [],
    description: '',
    ftcWorkOrderId: '',
    extendedStatus: ExtendedStatus.OPEN_UNASSIGNED_WARNING,
    extendedStatusText: '',
    contractorId: -1,
    tradeId: -1,
    externalStatus: '',
    totalHours: -1,
    addedById: -1,
    addedByType: '',
    tradeServiceIds: [-1],
    scExtendedStatusLabel: '',
    externalStatusColor: '',
    tripAttachmentsCount: -1,
    assigneeType: TripAssigneeType.TEAM_MEMBER,
    ccTripContractor: {} as Vendor,
    contractor: {} as Vendor,
    clientBillingMethod: '',
    vendorBillingMethod: '',
    tripCategory: {
      id: '',
      name: ''
    },
    tripEta: {
      status: '',
      eta: generateDefaultDateTime(AllowedAttrTypes.ETA, {} as PriorityObj, locationTz),
      reason: ''
    },
    trade: {
      id: -1,
      name: '',
      sicCode: '',
      authorizationNumber: '',
      licenses: false,
      state: '',
      noEquipment: false,
      parentId: -1,
      color: ''
    },
    tripTradeServices: []
  }
}

const tripsTableRef = ref<any>(null)
const timeCardTableRef = ref<any>(null)

export const useTrips = (forTrip = true) => {
  const parentType = inject('resourceType', null)
  const TRIP_ACTIONS = {
    edit: {
      value: forTrip ? 'edit' : 'trip-edit',
      actionFn: (trips: Trip[], { emit, rowType, changeFocus }: any) => {
        if (rowType === 'trip' && parentType !== WORK_ORDER) {
          emit?.('edit')
          changeFocus?.({ ...trips[0], detailsPanelMode: DetailsPanelMode.Edit })
        } else {
          const { openGlobalDetailsPanel } = useDetailsPanel()
          openGlobalDetailsPanel('trips/details', {
            actions: getTripsActions(),
            isEditMode: true,
            canDoubleClickToEdit: false,
            keepWorkOrderInfoOpen: false,
            showDefaultDetailsTab: true,
            selectedResources: trips,
            isGlobalDetailsPanel: true
          }, '', { }, { })
        }
      },
      noSuccessEmit: true
    },
    assign: {
      value: 'trip-assign',
      requiresConfirmation: true,
      advancedModal: 'trips/modals/assign',
      dynamicContentProps: { keepDetailsOpenOnSave: true }
    },
    dispatch: {
      value: 'trip-dispatch',
      requiresConfirmation: true,
      advancedModal: 'trips/modals/dispatch'
    },
    checkIn: {
      value: 'trip-check-in',
      requiresConfirmation: false,
      actionFn: (trips: Trip[]) => {
        const tripId = trips[0].id
        // functionality to be added later
        console.log('Check in action for trip', tripId)
      }
    },
    accept: {
      value: 'trip-accept',
      requiresConfirmation: true,
      advancedModal: 'trips/modals/accept'
    },
    reject: {
      value: 'trip-reject',
      requiresConfirmation: true,
      advancedModal: 'trips/modals/reject'
    },
    adjust: {
      value: 'trip-adjust',
      requiresConfirmation: false,
      actionFn: (trips: Trip[]) => {
        const tripId = trips[0].id
        // functionality to be added later
        console.log('Adjust action for trip', tripId)
      }
    },
    generateInvoice: {
      value: 'trip-generate-invoice',
      requiresConfirmation: true,
      advancedModal: 'trips/generate-invoice',
      generateProps: (trips: Trip[]) => {
        return {
          params: {
            ids: trips.map((trip) => trip.id),
            receivable: true,
            payable: true
          }
        }
      }
    },
    copyTrip: {
      value: 'copy-trip',
      requiresConfirmation: true,
      advancedModal: 'trips/modals/copy'
    },
    verify: {
      value: 'trip-verify',
      advancedModal: 'trips/modals/verify',
      requiresConfirmation: true
    },
    reassign: {
      value: 'trip-reassign',
      requiresConfirmation: false,
      actionFn: (trips: Trip[]) => {
        const tripId = trips[0].id
        // functionality to be added later
        console.log('Reassign action for trip', tripId)
      }
    },
    unverify: {
      value: 'trip-unverify',
      requiresConfirmation: true,
      advancedModal: 'trips/modals/unverify'
    },
    unverifyWithServices: {
      value: 'unverify-with-services',
      requiresConfirmation: true,
      advancedModal: 'trips/modals/unverify',
      dynamicContentProps: { unverifyWithServices: true }
    },
    sendTextMessage: {
      value: 'send-text-message',
      requiresConfirmation: true,
      advancedModal: 'trips/modals/send-message'
    },
    cancel: {
      value: 'trip-cancel',
      requiresConfirmation: true,
      advancedModal: 'trips/modals/trip-cancel'
    },
    archive: {
      value: 'trip-archive',
      requiresConfirmation: true,
      advancedModal: 'trips/modals/archive'
    },
    photos: {
      value: 'trip-photos',
      requiresConfirmation: true,
      advancedModal: 'trips/modals/photos'
    },
    resendDispatchEmail: {
      value: 'trip-resend-dispatch-email',
      requiresConfirmation: true,
      advancedModal: 'trips/modals/resend-vendor-dispatch-email'
    },
    generateReport: {
      value: 'trip-generate-report',
      requiresConfirmation: true,
      advancedModal: 'uc/attachments/uc-workorder-details-modal'
    },
    view: {
      value: 'view',
      actionFn: (trips: Trip[], { rowType, changeFocus }: any) => {
        if (rowType === 'trip' && parentType !== WORK_ORDER) {
          changeFocus({ ...trips[0], detailsPanelMode: DetailsPanelMode.View })
        } else {
          const { openGlobalDetailsPanel } = useDetailsPanel()
          openGlobalDetailsPanel('trips/details', {
            actions: getTripsActions(),
            isEditMode: false,
            selectedResources: trips,
            canDoubleClickToEdit: true,
            keepWorkOrderInfoOpen: false,
            showDefaultDetailsTab: false,
            isGlobalDetailsPanel: true
          }, '', { }, { })
        }
      },
      noSuccessEmit: true
    },
    skip: {
      value: 'skip'
    },
    saveAndAssign: {
      value: 'trip-save-assign',
      requiresConfirmation: false
    },
    save: {
      value: 'trip-save',
      requiresConfirmation: false
    },
    addWorkOrderNote: {
      value: 'add-work-order-note',
      requiresConfirmation: true,
      advancedModal: 'work-orders/details/notes/new'
    },
    vendorDispatchApproval: {
      value: 'vendor-dispatch-approval',
      requiresConfirmation: true,
      advancedModal: 'trips/modals/assignment/vendor/dispatch-approval'
    },
    vendorApproveAssignment: {
      value: 'vendor-approve-assignment',
      requiresConfirmation: true,
      advancedModal: 'trips/modals/assignment/vendor/approve-assignment'
    },
    saveAndDispatch: {
      value: 'trip-save-dispatch',
      requiresConfirmation: false
    },
    editTimeCard: {
      value: 'edit-check-in-out',
      updateSelectedResource: false,
      actionFn: (trips: Trip[]) => {
        const { openGlobalDetailsPanel } = useDetailsPanel()
        openGlobalDetailsPanel('trips/details', {
          actions: getTripsActions(),
          isEditMode: false,
          selectedResources: trips,
          canDoubleClickToEdit: true,
          keepWorkOrderInfoOpen: false,
          showDefaultDetailsTab: false,
          isGlobalDetailsPanel: true,
          selectedTab: 'Assignees'
        }, '', { }, { })
      },
      noSuccessEmit: true,
      detailsPanel: DetailsPanelButtonState.Hide
    },
    editTimeCardModal: {
      value: 'edit-check-in-out-modal',
      advancedModal: 'trips/modals/timecard',
      requiresConfirmation: true,
      detailsPanel: DetailsPanelButtonState.Hide,
      noSuccessEmit: true,
      dynamicContentProps: { isTimecard: false }
    },
    associateEvent: {
      value: 'associate-event',
      updateSelectedResource: false,
      actionFn: (trips: Trip[]) => {
        const { openGlobalDetailsPanel } = useDetailsPanel()
        openGlobalDetailsPanel('trips/details', {
          actions: getTripsActions(),
          isEditMode: false,
          selectedResources: trips,
          canDoubleClickToEdit: true,
          keepWorkOrderInfoOpen: false,
          showDefaultDetailsTab: false,
          isGlobalDetailsPanel: true,
          selectedTab: 'Weather Event'
        }, '', { }, { })
      },
      noSuccessEmit: true,
      detailsPanel: DetailsPanelButtonState.Hide
    },
    disassociateEvent: {
      value: 'disassociate-event',
      updateSelectedResource: false,
      actionFn: (trips: Trip[]) => {
        const { openGlobalDetailsPanel } = useDetailsPanel()
        openGlobalDetailsPanel('trips/details', {
          actions: getTripsActions(),
          isEditMode: false,
          selectedResources: trips,
          canDoubleClickToEdit: true,
          keepWorkOrderInfoOpen: false,
          showDefaultDetailsTab: false,
          isGlobalDetailsPanel: true,
          selectedTab: 'Weather Event'
        }, '', { }, { })
      },
      noSuccessEmit: true,
      detailsPanel: DetailsPanelButtonState.Hide
    }
  }

  const clientHeaderActions = [
    TRIP_ACTIONS.view,
    TRIP_ACTIONS.photos
  ]

  interface AllTripsAndMustScheduleTripsParams {
    pdfViewerRef: any,
    actionToastRef: any,
    trackedResourceIds?: number[],
    resourceIds?: number[],
    bulkSelectionData: {
      isBulkExclusive: boolean
      isSelectAll: boolean
    },
    exportQuery?: any,
    totalCount: number
    totalSelectedCount?: number
  }

  const sharedActionsForTrips = (tab: string) => {
    if (isBroker.value) {
      const bulkActions = TripBulkActions.filter((action) => action.tabs.includes(tab))
      bulkActions.forEach((action) => {
        action.actionFn = (resources: Trip[]) => {
          tripsPerformBulkAction(resources, action.event)
        }
      })
      return [
        ...getTripsActions(),
        ...bulkActions
      ]
    } else {
      return [...getTripsActions()]
    }
  }

  const allHeaderActions = [
    {
      value: 'Generate Report',
      requiresConfirmation: false,
      actionFn: (resources: Trip[]) => {
        tripsPerformBulkAction(resources, 'download_trips_report')
      },
      bulkConfig: {
        type: 'primary'
      }
    },
    TRIP_ACTIONS.accept,
    TRIP_ACTIONS.reject,
    TRIP_ACTIONS.adjust,
    TRIP_ACTIONS.assign,
    TRIP_ACTIONS.checkIn,
    TRIP_ACTIONS.dispatch,
    TRIP_ACTIONS.edit,
    TRIP_ACTIONS.generateInvoice,
    TRIP_ACTIONS.reassign,
    TRIP_ACTIONS.verify,
    TRIP_ACTIONS.unverify,
    TRIP_ACTIONS.unverifyWithServices,
    TRIP_ACTIONS.sendTextMessage,
    TRIP_ACTIONS.cancel,
    TRIP_ACTIONS.archive,
    TRIP_ACTIONS.resendDispatchEmail,
    TRIP_ACTIONS.generateReport,
    TRIP_ACTIONS.copyTrip,
    TRIP_ACTIONS.skip,
    TRIP_ACTIONS.saveAndAssign,
    TRIP_ACTIONS.save,
    TRIP_ACTIONS.addWorkOrderNote,
    TRIP_ACTIONS.vendorDispatchApproval,
    TRIP_ACTIONS.vendorApproveAssignment,
    TRIP_ACTIONS.saveAndDispatch,
    TRIP_ACTIONS.editTimeCard,
    TRIP_ACTIONS.editTimeCardModal,
    TRIP_ACTIONS.associateEvent,
    TRIP_ACTIONS.disassociateEvent
  ]

  const getTripsActions = () => {
    // Early return clientHeaderActions if isClient
    if (isClient.value) return clientHeaderActions

    const allActions = cloneDeep(allHeaderActions)

    const parentType = inject('resourceType', null)

    // Show bulk generate report action when work order is focus
    if (parentType !== WORK_ORDER) {
      allActions.forEach((action: { value: string, bulkConfig?: { type: string } }) => {
        if (action.value === 'Generate Report') delete action.bulkConfig
      })
    }

    return allActions.concat(clientHeaderActions)
  }

  const localTrips = reactive({
    loading: false,
    list: []
  })
  const getTrips = async (params: any) => {
    try {
      const res = await repositories.trips.get(params)
      return {
        data: res.trips,
        totalCount: res.meta.totalCount,
        totalPages: res.meta.totalPages
      }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const getTripById = async (workOrderId: number | string, tripId: number | string, params?: {include?: string, assigneeDetailsRequired?: boolean, includeCompliance?: boolean, includeCanApproveNonCompliantVendor?: boolean, parentServiceRequired?: boolean }) => {
    try {
      const res = await repositories.trips.getById(workOrderId as number, tripId as number, params)
      return { data: res.trip }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const getTripPhotos = async (tripId: string | number, params: any) => {
    try {
      const res = await repositories.trips.getPhotos(tripId, params)
      return {
        data: res
      }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }
  const getTripPhotosCount = async (tripId: string | number) => {
    try {
      const res = await repositories.trips.getTripsPhotosCount(tripId)
      return {
        data: res.count || 0
      }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }
  const assign = async (params:any, workOrderId:any, tripId:any) => {
    try {
      const res = await repositories.trips.assign(params, workOrderId, tripId)
      return res
    } catch (err) {
      return err
    }
  }
  const getWoTripsPhotos = async (workOrderId: string | number, tripId: string | number, params: any) => {
    try {
      const res = await repositories.trips.getTripsPhotos(workOrderId, tripId, params)
      return {
        data: res.tripPhotos,
        currentPage: res.meta.currentPage,
        totalCount: res.meta.totalCount,
        totalPages: res.meta.totalPages,
        nextPage: res.meta.nextPage,
        prevPage: res.meta.prevPage
      }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const getAllTrips = async (params: any) => {
    try {
      const res = await repositories.trips.getAllTrips(params)
      return {
        data: res.trips,
        totalCount: res.meta.totalCount,
        totalPages: res.meta.totalPages,
        currentPage: res.meta.currentPage,
        nextPage: res.meta.nextPage
      } as baseGetResponse
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const getAllTripsCount = async (params: TripCountParam) => {
    try {
      const res = await repositories.trips.getAllTripsCount(params)
      return { data: res.results } as { data: tabInfo[] }
    } catch (err) {
      console.log(err)
      return { data: [] }
    }
  }

  const getTechnicianTrips = async (params: any) => {
    try {
      const res = await repositories.trips.getTechnicianTrips(params)
      return {
        data: res.technicianTrips,
        totalCount: res.meta.totalCount,
        totalPages: res.meta.totalPages,
        currentPage: res.meta.currentPage
      }
    } catch (err) {
      return { data: [] }
    }
  }

  const tripsAutoComplete = async (params: AutocompletePayload) => {
    try {
      const res = await repositories.trips.tripsAutoComplete(params)
      return {
        data: res.results,
        nextPage: res.nextPage
      } as { data: AutocompleteResponse[], nextPage: number }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const getTrip = (params: getTripParams) => {
    return getAllTrips(params)
  }

  const { generateMenuActions } = useListRow()

  const generatePrimaryAction = () => {
    tripPrimaryMenuActionsMap = generateMenuActions(ALL_STATUSES, tripPrimaryMenuActionsMap, tripPrimaryActionData, [])
  }

  const generateTechnicianTripMenuActions = () => {
    technicianTripMenuActionsMap = generateMenuActions(ALL_STATUSES, technicianTripMenuActionsMap, technicianTripActionData, [])
  }

  const generateTechnicianTripPrimaryAction = () => {
    technicianTripPrimaryMenuActionsMap = generateMenuActions(ALL_STATUSES, technicianTripPrimaryMenuActionsMap, technicianTripPrimaryActionData, [])
  }

  const generateTripActions = (trip: Trip, isDetailsPanel = false) => {
    const { getActionMenuData } = useListRow()
    if (trip.id === -1) {
      // check assignee presence in add trip flow and change menu actions to save & dispatch
      const assignee = Array.isArray(trip.assigneeDetails)
        ? trip.assigneeDetails[0]
        : trip.assigneeDetails
      if (assignee?.assigneeType) {
        return getActionMenuData('', [], [...TRIP_SAVE_DISPATCH_ACTION, TRIP_CREATE_ACTION[1]], '', false, null)
      }
      return getActionMenuData('', [], [...TRIP_CREATE_ACTION], '', false, null)
    }

    const currentCompanyRole = trip.currentCompanyRole
    const primaryAction = trip.ccRequiredAction?.action

    let actions: TripResourceAction[]
    if (currentCompanyRole === WorkOrderCompanyRole.CLIENT) {
      actions = cloneDeep(TRIP_CLIENT_ACTIONS)
    } else if (currentCompanyRole === WorkOrderCompanyRole.CONTRACTOR) {
      actions = cloneDeep(TRIP_CONTRACTOR_ACTIONS)
    } else if (currentCompanyRole === WorkOrderCompanyRole.INTERMEDIARY_BROKER) {
      actions = cloneDeep(TRIP_INTERMEDIARY_BROKER_ACTIONS)
    } else {
      actions = cloneDeep(TRIP_BROKER_ACTIONS)
      actions = actions.map(
        action => { return { ...action, eventName: action.eventName === 'trip-edit' ? 'edit' : action.eventName } }
      )
    }
    const isPrimaryPredicate = (action: TripResourceAction) => !!primaryAction && action.eventName === `trip-${kebabCase(primaryAction)}`

    actions = filter(actions, (action) => (
      (!action.primaryActionOnly || isPrimaryPredicate(action)) &&
      (!action.specialBehaviors ||
        (action.specialBehaviors === 'selfPerformingOnly' && trip.assigneeType === TripAssigneeType.TEAM_MEMBER) ||
        (action.specialBehaviors === 'vendorPerformingOnly' && trip.assigneeType === TripAssigneeType.VENDOR)
      )
    ))

    // primary action bubbles to the top
    actions = sortBy(actions, (action, index) => isPrimaryPredicate(action) ? -1 : index)
    // exclude any actions where the mutuallyExclusiveWith exists after filters,
    // and has the event name of the action in question
    actions = filter(actions,
      (action) => {
        switch (action.name) {
          case 'Dispatch': {
            const showDispatch = !trip.dispatchTime
            if (trip?.assigneeType === TripAssigneeType.TEAM_MEMBER || trip?.assigneeType === TripAssigneeType.VENDOR) {
              let hasAssigneeIds = false
              // assigneeDetails:  for TeamMember we get Collection, whereas for Vendor we are getting Object in API Response
              if (trip.assigneeDetails instanceof Array) {
                // This will get executed for TeamMember
                hasAssigneeIds = trip.assigneeDetails.some(a => a.assigneeId)
                return (showDispatch && hasAssigneeIds && findIndex(actions, (innerAction) => innerAction.mutuallyExclusiveWith === action.eventName) === -1)
              } else {
                // This will get executed for Vendor
                hasAssigneeIds = !!trip.assigneeDetails?.assigneeId
                return showDispatch && !(!hasAssigneeIds && (trip.vendorCompliancyRequired && !trip.ccTripContractor?.compliantVendorOfCc) && findIndex(actions, (innerAction) => innerAction.mutuallyExclusiveWith === action.eventName) === -1)
              }
            } else {
              return showDispatch && findIndex(actions, (innerAction) => innerAction.mutuallyExclusiveWith === action.eventName) === -1
            }
          }
          case 'Send Text Message': {
            let hasAssigneeIds = false
            // assigneeDetails:  for TeamMember we get Collection, whereas for Vendor we are getting Object in API Response
            if (trip.assigneeDetails instanceof Array) {
              hasAssigneeIds = trip.assigneeDetails.some(a => a.assigneeId)
            } else {
              hasAssigneeIds = !!trip.assigneeDetails?.assigneeId
            }
            return (trip.assigneeType === TripAssigneeType.TEAM_MEMBER && hasAssigneeIds && findIndex(actions, (innerAction) => innerAction.mutuallyExclusiveWith === action.eventName) === -1)
          }
          case 'Photos': {
            return (!!trip.photosCount && findIndex(actions, (innerAction) => innerAction.mutuallyExclusiveWith === action.eventName) === -1)
          }
          default:
            return findIndex(actions, (innerAction) => innerAction.mutuallyExclusiveWith === action.eventName) === -1
        }
      })

    const { session } = useSession()
    const { updatedVerifyActionText, minutesLeftToVerifyTrip } = useTripsVerification(trip)
    if (trip.extendedStatus === 'completed' && trip.verifiedVerifications?.map((verification) => verification.companyId).includes(session.currentCompany?.id)) {
      // this case is about contractor company verified the trip and broker verification is pending
      // in such scenario the trip's extended status is still 'completed' hence the manipulations
      const verifyAction = actions.find((action) => action.eventName === 'trip-verify')
      if (verifyAction) {
        actions = difference(actions, [verifyAction])
      }
      // we do not allow to unverify after adjustment hours are passed
      if (minutesLeftToVerifyTrip.value > 0) {
        const unverifyAction = actions.find((action) => action.eventName === 'trip-unverify')
        if (unverifyAction && Array.isArray(unverifyAction.validStatuses)) {
          actions = difference(actions, [unverifyAction])
          unverifyAction.validStatuses.push('completed')
          actions.push(unverifyAction)
        }
        const unverifyWithServicesAction = actions.find((action) => action.eventName === 'unverify-with-services')
        if (unverifyWithServicesAction && Array.isArray(unverifyWithServicesAction.validStatuses)) {
          actions = difference(actions, [unverifyWithServicesAction])
          unverifyWithServicesAction.validStatuses.push('completed')
          actions.push(unverifyWithServicesAction)
        }
      }
    } else if (trip.extendedStatus === 'completed') {
      const verifyAction = actions.find((action) => action.eventName === 'trip-verify')
      // not adding current company check since verify action will be never be available for client
      if (verifyAction) {
        actions = difference(actions, [verifyAction])
        // update verify button text with time left for verification else to Exception Note if missed time for verification
        actions.unshift(updatedVerifyActionText(verifyAction))
      }
    } else if (trip.extendedStatus === 'trip_verified' && !(trip.verifiedVerifications?.map((verification) => verification.companyId).includes(session.currentCompany?.id))) {
      // contractor should not be able to unverify a trip verified by broker directly
      actions = actions.filter((action) => action.eventName !== 'trip-unverify')
      actions = actions.filter((action) => action.eventName !== 'unverify-with-services')
    }

    // we don't have any extended status to determine whether the trip has assignee or not
    // so we are checking the assignee details presence to display action 'Assign'
    // and not displaying 'Dispatch' action if there is no assignee in the trip
    if (Array.isArray(trip.assigneeDetails) ? trip.assigneeDetails.length > 0 : !!trip.assigneeDetails) {
      actions = actions.filter((action) => action.eventName !== 'trip-assign')
    } else {
      actions = actions.filter((action) => action.eventName !== 'trip-dispatch')
    }

    if (trip.assigneeType !== TripAssigneeType.VENDOR || isEmpty(trip.assigneeDetails) || !trip.vendorCompliancyRequired || trip.vendorCompliancy || trip.contractor?.vendorCompliance) {
      actions = actions.filter(action => action.eventName && !['vendor-dispatch-approval', 'vendor-approve-assignment'].includes(action.eventName))
    }
    if (trip.vendorCompliancyRequired && (!trip.vendorCompliancy || !trip.ccTripContractor?.compliantVendorOfCc)) {
      const { settings } = useCompanySettings()
      const compliancySetting = settings?.value?.data?.compliancySetting
      const approvers = [...compliancySetting?.tripNonCompliantVendorMustApproverIds, ...compliancySetting?.tripNonCompliantVendorApproverIds]
      const currentUserIsApprover = !!approvers.find((id: number) => id === session.loggedUser.id)
      if (currentUserIsApprover && !trip.canApproveNonCompliantVendor) {
        actions = actions.filter(action => action.eventName && !['vendor-dispatch-approval', 'vendor-approve-assignment'].includes(action.eventName))
      } else if (!currentUserIsApprover) {
        actions = actions.filter(action => action.eventName !== 'vendor-approve-assignment')
      }
    }

    if (trip.wwInfo && trip.currentCompanyRole === WorkOrderCompanyRole.BROKER) {
      if (trip.wwInfo.assigned) {
        actions = actions.filter((action) => action.eventName !== 'associate-event')
        if (trip.invoices?.length) {
          actions = actions.filter((action) => action.eventName !== 'disassociate-event')
        }
      } else {
        actions = actions.filter((action) => action.eventName !== 'disassociate-event')
      }
    } else {
      actions = actions.filter((action) => !['associate-event', 'disassociate-event'].includes(action.eventName || ''))
    }

    // Open timeCard edit modal when single timeCard data in trip
    // Open details screen with assignee tab when multiple timeCards
    // Remove edit check in/out action when canAdjustTimecard is false
    const { canAdjustTimecard } = useTripsTimecard(trip)
    const { isVendor } = useApp()
    if (canAdjustTimecard.value) {
      const isMultipleTripsServices = (trip.tradesmenTrips?.length && (trip.tradesmenTrips.length > 1 || (trip.tradesmenTrips.length === 1 && trip.tradesmenTrips[0].services && trip.tradesmenTrips[0].services?.length > 1)))
      const isSingleTrip = trip.tradesmenTrips?.length === 1
      if (isMultipleTripsServices) {
        actions = actions.filter(action => action.eventName !== 'edit-check-in-out-modal')
      } else if (isSingleTrip) {
        actions = actions.filter(action => action.eventName !== 'edit-check-in-out')
      } else if (isVendor) {
        actions = actions.filter(action =>
          action.eventName !== 'edit-check-in-out')
      } else {
        actions = actions.filter(action =>
          action.eventName !== 'edit-check-in-out' &&
          action.eventName !== 'edit-check-in-out-modal'
        )
      }
    } else {
      actions = actions.filter(action =>
        action.eventName !== 'edit-check-in-out' &&
        action.eventName !== 'edit-check-in-out-modal'
      )
    }

    // at this point everything remaining is just based on the trip's status, so we apply the regular action filters
    return getActionMenuData(trip.extendedStatus, [], actions, '', isDetailsPanel, null)
  }

  const createTripNote = async (workOrderId: string, params: PostTripNoteParams) => {
    try {
      const res = await repositories.trips.createTripNote(workOrderId, params)
      return res
    } catch (err) {
      console.error(err)
      return err
    }
  }

  const acceptTrip = async (workOrderId: string, tripId: number) => {
    try {
      const res = await repositories.trips.acceptTrip(workOrderId, tripId)
      return { data: res.trip as Trip }
    } catch (err) {
      return { data: false }
    }
  }

  const rejectTrip = async (workOrderId: string, tripId: number, vendorTripRejectionReason: string) => {
    try {
      const res = await repositories.trips.rejectTrip(workOrderId, tripId, vendorTripRejectionReason)
      return { data: res.trip as Trip }
    } catch (err) {
      return { data: false }
    }
  }

  const fetchVendorTeamMemberEmails = async (params: any) => {
    try {
      const res = await repositories.trips.vendorTeamMemberEmails(params)
      return {
        data: res.users,
        totalCount: res.meta.totalCount,
        totalPages: res.meta.totalPages,
        currentPage: res.meta.currentPage
      } as baseGetResponse
    } catch (err) {
      return { data: [] }
    }
  }

  const resendVendorDispatchEmail = async (params: { id: number, to: string[], cc?: string[], bcc?: string[] }) => {
    try {
      const res = await repositories.trips.resendVendorEmail(params)
      return { data: res }
    } catch (err) {
      return { data: { success: false } }
    }
  }

  const getTripCategories = async (params: any) => {
    try {
      const res = await repositories.trips.getTripCategories(params)
      return {
        data: res.tripCategories,
        totalCount: res.meta.totalCount,
        totalPages: res.meta.totalPages,
        currentPage: res.meta.currentPage
      } as baseGetResponse
    } catch (err) {
      return { data: [] }
    }
  }

  const createOrUpdateTrip = async (params: CreateOrUpdateTripParams): Promise<TripsCreateOrUpdateResponse> => {
    try {
      const res = await repositories.trips.createOrUpdateTrip(params)
      return { success: true, data: res.trip }
    } catch (err) {
      const errorMesaages = (err as AxiosResponse).data.errors || (err as AxiosResponse).data.errorMesaages
      return { success: false, errors: errorMesaages }
    }
  }

  const updateTabsQuantity = (): void => {
    const { nestedTabs } = useApp()
    const tabs = nestedTabs.value.trips.filter((tripTab: PageTab) =>
      [allTripsTab, mustScheduleTab].includes(tripTab.name)
    )
    if (!tabs.length) return

    tabs.forEach((tab: PageTab) => {
      if (tab.quantity === '99+') return

      const prevQuantity = Number(tab.quantity)
      tab.quantity = prevQuantity === 0 ? '1' : (prevQuantity + 1).toString()
    })
  }

  const getDefaultVendorBillingMethod = async (tripId: number, params: { tradeServiceId: number, contractorId: number }) => {
    try {
      const res = await repositories.trips.getDefaultVendorBillingMethod(tripId, params)
      return { data: res.defaultVendorBillingMethod as string }
    } catch (err) {
      return { data: null }
    }
  }

  const getLaborRateSchedule = async (tripId: number) => {
    try {
      const res = await repositories.trips.getLaborRateSchedule(tripId)
      return { data: res.laborRateSchedule }
    } catch (err) {
      return { data: null }
    }
  }

  const copyAndCreateTrip = async (workOrderId: number, tripId: number) => {
    try {
      const res = await repositories.trips.copyAndCreateTrip(workOrderId, tripId)
      return { data: res.trip }
    } catch (err) {
      return { data: false }
    }
  }

  const copyTrip = async (workOrderId: number, tripId: number, params: {include?: string, assigneeDetailsRequired?: boolean }): Promise<TripsCreateOrUpdateResponse> => {
    try {
      const res = await repositories.trips.copyTrip(workOrderId, tripId, params)
      return { success: true, data: res.trip }
    } catch (err) {
      const errorMessages = (err as AxiosResponse).data.errors || (err as AxiosResponse).data.errorMessages
      return { success: false, errors: errorMessages }
    }
  }

  const defaultBillingMethod = async (params: DefaultBillingMethodParams) => {
    try {
      const res = await repositories.trips.defaultBillingMethod(params)
      return { data: res }
    } catch (err) {
      return { data: null }
    }
  }

  // we are using this in threeDot menu only
  const getTripByTripId = async (tripId: number, params: {assigneeDetailsRequired: boolean}) => {
    try {
      const res = await repositories.trips.getTripByTripId(tripId, params)
      return { success: true, data: res.trip }
    } catch (err) {
      const errorMessages = (err as AxiosResponse).data.errors || (err as AxiosResponse).data.errorMessages
      return { success: false, errors: errorMessages }
    }
  }

  const generateTripReports = async (params: any) => {
    try {
      const res = await repositories.trips.generateTripReports(params)
      return {
        data: res
      }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const getTripReportTemplates = async (params: any) => {
    try {
      const res = await repositories.trips.getTripReportTemplates(params)
      return {
        data: res.tripReportTemplates,
        totalCount: res.meta.totalCount,
        totalPages: res.meta.totalPages,
        currentPage: res.meta.currentPage
      } as baseGetResponse
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }
  const postTripReportTemplate = async (params: any) => {
    try {
      const res = await repositories.trips.postTripReportTemplate(params)
      return { data: res }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const vendorDispatchApproval = async (tripId: number) => {
    try {
      await repositories.trips.vendorDispatchApproval(tripId)
      return { data: true }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const getTripCheckInCheckOutMinMaxDates = async (workOrderServiceId: number) => {
    try {
      const response = await repositories.trips.getCheckInCheckOutMinMaxDates(workOrderServiceId)
      return response
    } catch (error) {
      return error
    }
  }

  const getTripsCount = async (filterParams: any) => {
    try {
      const res = await repositories.trips.getTripsCount(filterParams)
      return {
        count: res.count
      }
    } catch (err) {
      console.log(err)
      return { count: 0 }
    }
  }

  const changeVisibility = async (tripId: string | number, photoId: number, tripPhoto: any) => {
    try {
      const res = await repositories.trips.changePhotoVisibility(tripId, photoId, tripPhoto)
      return res
    } catch (error) {
      console.log(error)
      return 'error'
    }
  }

  const changePhotoVisibility = async (tripId: string | number, photoId: number, visible: boolean) => {
    const tripPhoto = { makeFileAvailableToClient: visible }
    const response = await changeVisibility(tripId, photoId, tripPhoto)
    if (response?.attachment) {
      return response.attachment
    } else if (response?.servicePhoto) {
      return response.servicePhoto
    }
    return false
  }

  interface ResourceCount {
    workOrderActivitiesCount: number
    attachmentsCount: number
    workOrderNotesCount: number
  }

  const getTripResourcesCount = async (workOrderId: number, tripId: number): Promise<{ resourceCount: ResourceCount } | undefined> => {
    const resourceCount = await repositories.trips.getTripResourcesCount(workOrderId, tripId)
    return { resourceCount }
  }

  const photoPreview = async (params: { ids: number[], scopeContext: string }) => {
    try {
      const res: { trips: AttachmentInfo[] } = await repositories.trips.photoPreview(params)
      return res
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const additionalDataDetails = [{ fetchFn: photoPreview, responseKey: 'trips', dataKey: 'attachmentInfo', fetchParams: { scopeContext: 'all' } }]

  const createBulkInvoiceTask = async (params: TripsBulkInvoiceTasksParams) => {
    try {
      const res = await repositories.trips.createBulkInvoiceTask(params)
      return {
        data: res.backgroundTask as BackgroundTask
      }
    } catch (err: any) {
      return { data: false, limitExceeded: Object.keys(err.data?.errors || {}).includes('limit_exceeded') }
    }
  }

  return {
    trips,
    getTrips,
    assign,
    localTrips,
    getTripPhotos,
    getTripPhotosCount,
    getAllTrips,
    getTripsTableHeaders,
    getTripsActions,
    generatePrimaryAction,
    tripPrimaryMenuActionsMap,
    getTripAssigneeHeaders,
    getAllTripsCount,
    getTechnicianTrips,
    generateTechnicianTripMenuActions,
    technicianTripMenuActionsMap,
    generateTechnicianTripPrimaryAction,
    technicianTripPrimaryMenuActionsMap,
    generateTripActions,
    getWoTripsPhotos,
    tripsAutoComplete,
    getTrip,
    tripModalRef,
    createTripNote,
    acceptTrip,
    rejectTrip,
    fetchVendorTeamMemberEmails,
    resendVendorDispatchEmail,
    cancelTrip,
    getTripById,
    getTripCategories,
    createOrUpdateTrip,
    updateTabsQuantity,
    getDefaultVendorBillingMethod,
    getLaborRateSchedule,
    renderBulkDisptachTripModal,
    bulkTripDispatchEventData,
    copyAndCreateTrip,
    copyTrip,
    defaultBillingMethod,
    getTripByTripId,
    generateTripReports,
    getTripReportTemplates,
    postTripReportTemplate,
    tripsTableRef,
    vendorDispatchApproval,
    renderBulkGenerateTripReport,
    bulkGenerateTripReportData,
    getTripCheckInCheckOutMinMaxDates,
    sharedActionsForTrips,
    renderBulkVerifyTripModal,
    bulkVerifyEventData,
    renderBulkUnverifyTripModal,
    bulkUnverifyEventData,
    TRIP_ACTIONS,
    getTripsCount,
    changeVisibility,
    changePhotoVisibility,
    timeCardTableRef,
    getTripResourcesCount,
    additionalDataDetails,
    getActionFilterHeaderActions,
    renderTripsBatchInvoiceConfiguration,
    tripsBulkInvoiceTasksParams,
    createBulkInvoiceTask
  }
}
