import { kebabCase, isArray, transform, isObject, range, pick, cloneDeep } from 'lodash'
import { AutocompleteObject, AutocompleteResponse, MappingObject } from '@/types/interfaces'

// this only works on https. check the async clipboard JS docs for more details.
// https://developer.mozilla.org/en-US/docs/Web/API/Clipboard_API
export const copyToClipboard = (error: any) => {
  navigator.clipboard.writeText(error.value.join(', ')).then(function () {
    console.log('successful copy to clipboard')
  }, function () {
    console.log('failure when copying to clipboard')
  })
}

export const configureActionModal = (key: string, props:any) => {
  const config = props[key]
  return {
    ...config
  }
}

// convert object keys to kebab case recursively
export const deepKebabCase = (obj: Record<string, unknown>) => transform(obj, (result: Record<string, unknown>, value: unknown, key: string, target) => {
  const camelKey = isArray(target) ? key : kebabCase(key)
  result[camelKey] = isObject(value) ? deepKebabCase(value as Record<string, unknown>) : value
})

/**
* Normalizes an object or array to ensure consistent structure for comparison.
*
* @param {any} obj - The value to normalize.
* @returns {any} - Returns the normalized value.
*/
function normalize (obj: any): any {
  if (Array.isArray(obj)) {
    // Normalize each item in the array
    return obj.map(item => normalize(item))
  } else if (obj && typeof obj === 'object') {
    // Normalize each property of the object
    const normalizedObj = {} as any
    Object.keys(obj).sort().forEach(key => {
      normalizedObj[key] = normalize(obj[key])
    })
    return normalizedObj
  }
  // Return primitive values as is
  return obj
}

/**
 * Deeply compares two values (objects or arrays) for equality.
 *
 * @param {any} obj1 - The first value to compare.
 * @param {any} obj2 - The second value to compare.
 * @param {WeakSet} [seen=new WeakSet()] - Keeps track of objects that have been compared to handle circular references.
 * @returns {boolean} - Returns true if the values are deeply equal, otherwise false.
 */
export function deepEqual (obj1: any, obj2: any, seen = new WeakSet()): boolean {
  // If both values are the same reference or primitive types with the same value
  if (obj1 === obj2) return true

  // Check if either value is not an object or is null
  if (typeof obj1 !== 'object' || obj1 === null ||
      typeof obj2 !== 'object' || obj2 === null) {
    return false
  }

  // Handle circular references by tracking seen objects
  if (seen.has(obj1) || seen.has(obj2)) return false
  seen.add(obj1)
  seen.add(obj2)

  // Compare arrays
  if (Array.isArray(obj1) && Array.isArray(obj2)) {
    if (obj1.length !== obj2.length) return false

    // Normalize arrays by converting elements to sorted JSON strings
    const normalizeArray = (arr: any) => arr.map((item: any) => JSON.stringify(normalize(item))).sort()
    const normalized1 = normalizeArray(obj1)
    const normalized2 = normalizeArray(obj2)

    return JSON.stringify(normalized1) === JSON.stringify(normalized2)
  }

  // Check if one is an array and the other is not
  if (Array.isArray(obj1) !== Array.isArray(obj2)) return false

  // Compare objects
  const keys1 = Object.keys(obj1).sort() // Sort keys to ensure consistent order
  const keys2 = Object.keys(obj2).sort()

  // Check if objects have the same number of keys
  if (keys1.length !== keys2.length) return false

  // Recursively compare each key-value pair
  for (const key of keys1) {
    if (!keys2.includes(key)) return false // Key not found in both objects
    if (!deepEqual(obj1[key], obj2[key], seen)) return false // Recursively compare values
  }

  return true
}

export function createRange (length: number, start = 0): number[] {
  return range(start, start + length)
}

/**
 * Modifies an autocomplete object based on the provided item and mappings.
 *
 * @param item - The autocomplete response item.
 * @param index - The index used to create a unique ID.
 * @param mappings - An array of MappingObject for additional lookup.
 *
 * @returns An AutocompleteObject with id, text, and value.
 * If a matching mapping is found, the id and value are updated accordingly.
 */
export function modifyAutocompleteObject (
  item: AutocompleteResponse,
  index: number,
  mappings: MappingObject[]
): AutocompleteObject {
  let obj = {} as AutocompleteObject
  obj = {
    id: `${index}-${item.text}`,
    text: item.text,
    value: item.text
  }
  if (!mappings.length) return obj

  const found = mappings.find(mapping => mapping.code === item.text)
  if (!found) return obj

  obj = {
    id: found.text,
    text: found.text,
    value: found.code
  }
  return obj
}
