export const EQUAL = 'eq'

export const DATE_EQ = 'date_eq'

export const GREATER_THAN = 'gt'

export const DATE_GT = 'date_gt'

export const DATE_GT_EQ_TO = 'date_gteq'

export const GREATER_THAN_OR_EQUAL_TO = 'gteq'

export const LESS_THAN = 'lt'

export const DATE_LT = 'date_lt'

export const DATE_LT_EQ_TO = 'date_lteq'

export const LESS_THAN_OR_EQUAL_TO = 'lteq'

export const BETWEEN = 'bt'

export const DATE_BETWEEN = 'date_bt'

export const IN = 'in'

export const CONTAINS = 'contains'

export const MATCHES = 'match'

export const NOT_NULL = 'not_null'

export const NULL = 'null'

export const NOT_IN = 'not_in'
