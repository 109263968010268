
import {
  CLIENT_NAME
} from '@/constants/filters/resources/client'

import {
  PAYMENT_REFERENCE_NUMBER,
  PAYMENT_METHOD,
  PAYMENT_DEPOSIT_DATE,
  PAYMENT_STATUS
} from '@/constants/filters/resources/payments'

import {
  WORK_ORDER_START_DATE,
  WORK_ORDER_EXPIRATION_DATE,
  WORK_ORDER_SERVICE_CHECK_IN_TIME,
  WORK_ORDER_SERVICE_CHECK_OUT_TIME
} from '../resources/workOrder'

import {
  TRIP_CREATION_DATE,
  TRIP_EXPIRATION_DATE,
  TRIP_SERVICE_CHECK_IN_TIME,
  TRIP_SERVICE_CHECK_OUT_TIME
} from '../resources/trip'

import { usePayments } from '@/use/payments'
import { AutocompleteResponse, AutocompletePayload } from '@/types/interfaces/api-v2/autocomplete'
import { useSession } from '@/use/session'
import { BROKER } from '@/constants/permissions'

export const receivedPaymentFilters = () => {
  const { paymentsAutoComplete } = usePayments()

  const paymentsAutoCompleteFetchFn = async (params: AutocompletePayload) => {
    const { data, nextPage } = await paymentsAutoComplete(params) as { data: AutocompleteResponse[], nextPage: number }
    return {
      data: data.map((item: AutocompleteResponse, index: number) => {
        return {
          id: `${index}-${item.text}`,
          text: item.text,
          value: item.text
        }
      }),
      nextPage
    }
  }

  const { session } = useSession()
  const availableWorkOrderFilters = () => {
    const baseFilters: any = []
    if (session.currentCompanyType === BROKER) {
      baseFilters.push(
        WORK_ORDER_SERVICE_CHECK_IN_TIME({ pinned: false, isPrimary: false }),
        WORK_ORDER_SERVICE_CHECK_OUT_TIME({ pinned: false, isPrimary: false }),
        TRIP_SERVICE_CHECK_IN_TIME({ pinned: false, isPrimary: false }),
        TRIP_SERVICE_CHECK_OUT_TIME({ pinned: false, isPrimary: false })
      )
    }
    return baseFilters
  }

  return {
    filters: [
      PAYMENT_REFERENCE_NUMBER(true, { pinned: false, isPrimary: false }),
      PAYMENT_METHOD(true, { pinned: false, isPrimary: false }),
      // PAYMENT_DEPOSIT_ACCOUNT(true, { pinned: false, isPrimary: false }),
      PAYMENT_DEPOSIT_DATE(true, { pinned: true, isPrimary: true }),
      // PAYMENT_AMOUNT_RECEIVED(true, { pinned: false, isPrimary: false }),
      PAYMENT_STATUS(true, { pinned: false, isPrimary: false }),
      CLIENT_NAME({ pinned: true, isPrimary: true }, { field: 'client', tab: 'active' }, paymentsAutoCompleteFetchFn),
      WORK_ORDER_START_DATE({ pinned: false, isPrimary: false }),
      WORK_ORDER_EXPIRATION_DATE({ pinned: false, isPrimary: false }),
      TRIP_CREATION_DATE({ pinned: false, isPrimary: false }),
      TRIP_EXPIRATION_DATE({ pinned: false, isPrimary: false }),
      ...availableWorkOrderFilters()
    ],
    sortBy: {
      value: 'payment_date',
      direction: {
        text: 'Descending',
        value: 'desc'
      },
      items: [
        {
          label: 'Payment Date',
          value: 'payment_date'
        },
        {
          label: 'Payment Id',
          value: 'id'
        }
      ]
    },
    fieldMapping: {
      'payment-client-name': {
        name: 'client_name',
        field: 'value'
      },
      'reference-number': {
        name: 'reference_number',
        field: 'value'
      },
      'payment-method': {
        name: 'payment_method',
        field: 'value'
      },
      'deposit-account': {
        name: 'payment_account_account_name',
        field: 'value'
      },
      'deposit-date': {
        name: 'payment_date',
        field: 'value'
      },
      amount: {
        name: 'amount_in_dollar',
        field: 'value'
      },
      status: {
        name: 'status',
        field: 'value'
      },
      'client-name': {
        name: 'company_name',
        field: 'value'
      },
      'work-order-start-date': {
        name: 'invoices_work_order_created_at',
        field: 'value'
      },
      'work-order-expiration-date': {
        name: 'invoices_work_order_expiration_date',
        field: 'value'
      },
      'trip-creation-date': {
        name: 'invoices_trips_start_date',
        field: 'value'
      },
      'trip-expiration-date': {
        name: 'invoices_trips_expiration_date',
        field: 'value'
      },
      'work-order-service-check-in': {
        name: 'invoices_work_order_services_effective_check_in_time',
        field: 'value'
      },
      'work-order-service-check-out': {
        name: 'invoices_work_order_services_effective_check_out_time',
        field: 'value'
      },
      'trip-service-check-in': {
        name: 'invoices_trips_services_effective_check_in_time',
        field: 'value'
      },
      'trip-service-check-out': {
        name: 'invoices_trips_services_effective_check_out_time',
        field: 'value'
      }
    }
  }
}
