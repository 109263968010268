import { api } from '@/api'
import qs from 'qs'

import { FiltersParams, PrefillFilterData } from '@/types/interfaces'

export default {
  getFilterTemplates: (params: FiltersParams = {}) => {
    return api.$get('/filters', {
      params,
      paramsSerializer: (params: FiltersParams) => {
        return qs.stringify(params)
      }
    })
  },
  getAndSetDefaultFilterByTab: (path: string, params: FiltersParams = {}) => {
    return api.$get(`/default_filters/${path}`, {
      params,
      paramsSerializer: (params: FiltersParams) => {
        return qs.stringify(params)
      }
    })
  },
  createFilterTemplate: (template: any, params: FiltersParams = {}) => {
    return api.$post('/filters', {
      filter: template
    }, {
      params,
      paramsSerializer: params => {
        return qs.stringify(params)
      }
    })
  },
  updateFilterTemplate: (templateId: number, template: any, params: FiltersParams) => {
    return api.$patch(`/filters/${templateId}`, {
      filter: template
    }, {
      params,
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },
  deleteFilterTemplate: (templateId: number, params: FiltersParams) => {
    return api.$delete(`/filters/${templateId}`, {
      params,
      paramsSerializer: params => {
        return qs.stringify(params)
      }
    })
  },
  createDefaultFilter: (
    template: any, params: FiltersParams = {}, prefillFilter?: PrefillFilterData
  ) => {
    const apiParams = { filter: template } as any
    if (prefillFilter) {
      const { filterKey, resourceId, resourceType } = prefillFilter
      apiParams.prefillFilter = {
        filterKey,
        resourceId,
        resourceType
      }
    }
    return api.$post(`/default_filters/${template.path}`, apiParams, {
      params,
      paramsSerializer: params => {
        return qs.stringify(params)
      }
    })
  }
}
