import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return !_vm.loading ? _c('div', [_setup.isXsOrSmaller ? _c('div', {
    staticClass: "d-flex align-center ml-auto overflow-hidden"
  }, [_setup.props.totalSelected ? _c('div', {
    staticClass: "mr-2",
    attrs: {
      "id": "uc-table-slct-all"
    }
  }, [_c('uc-tooltip', {
    attrs: {
      "disabled": !_setup.props.totalSelected,
      "text": "Select All",
      "position": "top"
    }
  }, [_c('uc-button', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _setup.props.totalSelected >= 1,
      expression: "props.totalSelected >= 1"
    }],
    attrs: {
      "disabled": !_setup.props.totalSelected || _setup.props.totalSelected === _setup.props.totalCount,
      "type": "text",
      "state": "text",
      "icon": "",
      "size": 'sm',
      "has-hover": "",
      "icon-name": _setup.CHECK
    },
    on: {
      "click": function ($event) {
        return _setup.emit('select-all');
      }
    }
  })], 1)], 1) : _vm._e(), _setup.props.totalCount && _setup.props.isBulkActionsAvailable ? _c('uc-chip', {
    attrs: {
      "text": `${_setup.props.totalSelected} / ${_setup.props.totalCount}`,
      "size": "sm",
      "closeable": _setup.props.totalSelected >= 1
    },
    on: {
      "click:close": function ($event) {
        return _setup.emit('select-none');
      }
    }
  }) : _vm._e()], 1) : _c('div', {
    staticClass: "d-flex align-center ml-auto overflow-hidden"
  }, [_setup.props.totalCount && _setup.props.isBulkActionsAvailable ? _c('uc-tooltip', {
    attrs: {
      "disabled": !_setup.props.totalSelected,
      "text": 'Clear Selection',
      "position": "top"
    }
  }, [_c('uc-button', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _setup.props.totalSelected >= 1,
      expression: "props.totalSelected >= 1"
    }],
    attrs: {
      "disabled": !_setup.props.totalSelected,
      "type": "text",
      "state": "text",
      "icon": "",
      "has-hover": "",
      "icon-name": _setup.EXIT
    },
    on: {
      "click": function ($event) {
        return _setup.emit('select-none');
      }
    }
  })], 1) : _vm._e(), _setup.props.totalSelected ? _c('div', {
    staticClass: "mr-2",
    attrs: {
      "id": "uc-table-slct-all"
    }
  }, [_c('uc-tooltip', {
    attrs: {
      "disabled": !_setup.props.totalSelected,
      "text": "Select All",
      "position": "top"
    }
  }, [_c('uc-button', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _setup.props.totalSelected >= 1,
      expression: "props.totalSelected >= 1"
    }],
    attrs: {
      "disabled": !_setup.props.totalSelected || _setup.props.totalSelected === _setup.props.totalCount,
      "type": "text",
      "state": "text",
      "icon": "",
      "has-hover": "",
      "icon-name": _setup.CHECK
    },
    on: {
      "click": function ($event) {
        return _setup.emit('select-all');
      }
    }
  })], 1)], 1) : _vm._e(), _setup.props.showOnlyTotal ? _c('uc-chip', {
    attrs: {
      "text": 'Total',
      "size": "sm",
      "show-quantity": true,
      "quantity": _setup.props.totalCount || 0,
      "hide-text-on-mobile": false,
      "state": 'layer-secondary'
    }
  }) : _setup.props.totalCount && _setup.props.isBulkActionsAvailable ? _c('uc-chip', {
    attrs: {
      "text": `${_setup.props.totalSelected} / ${_setup.props.totalCount}`,
      "size": "sm",
      "hide-text-on-mobile": false,
      "state": 'layer-secondary'
    }
  }) : _vm._e()], 1)]) : _c(VProgressCircular, {
    attrs: {
      "size": 22,
      "color": "primary",
      "indeterminate": ""
    }
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };