import { VCheckbox } from 'vuetify/lib/components/VCheckbox';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "card ma-2 d-flex flex-row",
    on: {
      "click": function ($event) {
        return _setup.emit('row-click');
      },
      "dblclick": function ($event) {
        return _setup.emit('row-dblclick');
      }
    }
  }, [_c('div', {
    staticClass: "d-flex flex-1 flex-column overflow-hidden"
  }, _vm._l(_setup.rows, function (row, rowIndex) {
    return _c('div', {
      key: rowIndex,
      staticClass: "row"
    }, [rowIndex === 0 && _vm.canSelect ? _c('div', {
      staticClass: "column-select mr-1"
    }, [_c(VCheckbox, {
      staticClass: "checkbox",
      staticStyle: {
        "margin-top": "0px !important"
      },
      attrs: {
        "input-value": !_vm.selectionDisabled && _vm.isSelected,
        "disabled": _vm.selectionDisabled,
        "hide-details": "",
        "data-cy": "uc-list-row-base-checkbox"
      },
      on: {
        "change": function ($event) {
          return _setup.emit('toggle-select');
        },
        "click": function ($event) {
          $event.stopPropagation();
        }
      }
    })], 1) : rowIndex > 0 && _vm.canSelect ? _c('div', {
      staticClass: "column-select"
    }) : _vm._e(), _vm._l(row, function (item, index) {
      return _c('div', {
        key: index,
        staticClass: "d-flex align-center",
        class: _setup.getStyleClass(rowIndex)
      }, [_c('uc-rows-cell-wrapper', {
        attrs: {
          "column": item,
          "size": "card"
        }
      }, [_vm._t(item === null || item === void 0 ? void 0 : item.templateName)], 2)], 1);
    })], 2);
  }), 0), _setup.props.showActions ? _c('div', {
    staticClass: "actions-split-actions d-flex align-start"
  }, [_c('uc-rows-cell-wrapper', {
    attrs: {
      "column": _setup.ACTIONS_COL,
      "size": "card"
    }
  }, [_vm._t(_setup.ACTIONS_COL.templateName)], 2)], 1) : _vm._e(), _vm.canExpand ? _c('div', {
    staticClass: "d-flex ml-2 mt-1 align-start",
    attrs: {
      "data-cy": "list-row-drawer-expander"
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        !_vm.isExpanded;
        _setup.emit('toggle-expand');
      }
    }
  }, [_c('uc-icon', {
    class: ['transition3Sec', _vm.isExpanded ? 'transformRotate180' : ''],
    attrs: {
      "value": _setup.DRAWER_CLOSED,
      "size": "sm"
    }
  })], 1) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };