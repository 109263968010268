
// data types used for filters.
export enum FilterDataTypes {
  Select = 'select',
  Number = 'number',
  NumberPicker = 'number-picker',
  String = 'string',
  Date = 'date',
  Datetime = 'datetime'
}

// TODO: Crews, Roles, External References, check rest
export enum FilterResources {
  CLIENT = 'client',
  VENDOR = 'vendor',
  SITE = 'site',
  EMPLOYEE = 'employee',
  INVOICE = 'invoice',
  PROPOSAL = 'proposal',
  PURCHASE_ORDER = 'purchase-order',
  SCHEDULED_SERVICE = 'scheduled-service',
  EXTERNAL_REFERENCES = 'external_references',
  TRIP = 'trip',
  WEATHER_EVENT = 'weather-event',
  WORK_ORDER = 'work-orders'
}
