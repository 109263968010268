import { useWorkOrders } from '@/use/work-orders'
import { useSession } from '@/use/session'
import { useInboundWorkOrders } from '@/use/inbound-work-orders'
import { BROKER } from '@/constants/permissions'
import { useApp } from '@/use/app'
import { AutocompleteMappings, AutocompletePayload, AutocompleteResponse } from '@/types/interfaces/api-v2/autocomplete'
import { FilterResources } from '@/types/enums/filter-types'
import moment from 'moment'
import { camelCase } from 'lodash'
import { modifyAutocompleteObject } from '@/utils/helpers'

const { WorkOrdersAutoComplete, getWorkOrderStatus, fieldMappingForAutocomplete } = useWorkOrders()
const { getCompanySetValues } = useSession()
const { inboundWorkOrdersAutoComplete } = useInboundWorkOrders()
const { companyType } = useApp()
const { session } = useSession()

getCompanySetValues()

const workOrdersAutoCompleteFetchFn = async (params: AutocompletePayload) => {
  const autocompleteMethod = companyType.value !== BROKER
    ? inboundWorkOrdersAutoComplete
    : WorkOrdersAutoComplete
  const { data, nextPage } = await autocompleteMethod({ ...params }) as { data: AutocompleteResponse[], nextPage: number }

  const valueMappings = fieldMappingForAutocomplete(
    camelCase(params.field) as keyof AutocompleteMappings
  )
  return {
    data: data.map((item: AutocompleteResponse, index: number) => {
      const { id, text, value } = modifyAutocompleteObject(item, index, valueMappings)
      return { id, text, value }
    }),
    nextPage
  }
}

const WORK_ORDER_NUMBER = (config = {}, params: AutocompletePayload = { field: 'id', invoiceables: false }, fetchFn: any = workOrdersAutoCompleteFetchFn) => {
  return {
    text: 'Work Order Number',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'work-order-number-filter',
      headerText: 'Work Order Number',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      predicate: 'in',
      params,
      searchKeys: ['id'],
      tokenSeparators: ['\t', '\n', ','],
      collectionPredicate: 'eq'
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.WORK_ORDER,
    ...config
  }
}

const WORK_ORDER_START_DATE = (config = {}) => {
  return {
    text: 'Start Date',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'work-order-start-date-filter',
      headerText: 'Work Order Start Date',
      size: 'md',
      contentType: 'date',
      rowType: 'text',
      showHeaders: true
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.WORK_ORDER,
    ...config
  }
}

const WORK_ORDER_CREATED_DATE = (config = {}) => {
  return {
    text: 'Creation Date',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'work-order-created-date-filter',
      headerText: 'Work Order Created Date',
      size: 'md',
      contentType: 'date',
      rowType: 'text',
      showHeaders: true
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.WORK_ORDER,
    ...config
  }
}

const WORK_ORDER_EXPIRATION_DATE = (config = {}) => {
  return {
    text: 'Expiration Date',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'work-order-expiration-date-filter',
      headerText: 'Work Order Expiration Date',
      size: 'md',
      contentType: 'date',
      rowType: 'text',
      showHeaders: true
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.WORK_ORDER,
    ...config
  }
}

const WORK_ORDER_TRIP_EXPIRATION_DATE = (config = {}) => {
  return {
    text: 'Trip Expiration Date',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'work-order-trip-expiration-date-filter',
      headerText: 'Work Order Trip Expiration Date',
      size: 'md',
      contentType: 'date',
      rowType: 'text',
      showHeaders: true
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.WORK_ORDER,
    ...config
  }
}

const WORK_ORDER_GENERATION_DATE = (config = {}) => {
  return {
    text: 'Generation Date',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'work-order-generation-date-filter',
      headerText: 'Work Order Generation Date',
      size: 'md',
      contentType: 'date',
      rowType: 'text',
      showHeaders: true
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.WORK_ORDER,
    ...config

  }
}

const WORK_ORDER_TYPE = (config = {}, params = { field: 'work_order_type', invoiceables: false }, fetchFn = workOrdersAutoCompleteFetchFn) => {
  return {
    text: 'Type',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'work-order-type-filter',
      headerText: 'Work Order Type',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: ['work_order_type']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.WORK_ORDER,
    ...config
  }
}

const WORK_ORDER_PRIORITY = (config = {}, params: AutocompletePayload = { field: 'priority' }, fetchFn: any = workOrdersAutoCompleteFetchFn) => {
  return {
    text: 'Priority',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'work-order-priority-filter',
      headerText: 'Work Order Priority',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: ['priority']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.WORK_ORDER,
    ...config
  }
}

// NO-API
const WORK_ORDER_TRIP_STATUS = (config = {}, params: AutocompletePayload = { field: 'trip_status' }, fetchFn: any = workOrdersAutoCompleteFetchFn) => {
  return {
    text: 'Trip Status',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'work-order-trip-status-filter',
      headerText: 'Work Order Trip Status',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: ['trip_status']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.WORK_ORDER,
    ...config
  }
}

const WORK_ORDER_STATUS = (config = {}) => {
  return {
    text: 'Status',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'work-order-status-filter',
      headerText: 'Work Order Status',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn: async (params: AutocompletePayload) => {
        const { data } = await getWorkOrderStatus({ ...params }) as any
        if (!Object.keys(data).length) {
          return {
            data: [],
            totalCount: 0,
            totalPages: 0
          }
        }
        return {
          data: Object.values(data).map((item: any, index: number) => {
            return {
              id: item.status,
              text: item.text,
              value: index
            }
          }),
          totalCount: Object.values(data).length,
          totalPages: 1
        }
      },
      params: { q: { s: 'work_order_status asc' } },
      searchKeys: ['work_order_status']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.WORK_ORDER,
    ...config
  }
}

const WORK_ORDER_CUSTOM_STATUS = (config = {}, fetchFn: any = workOrdersAutoCompleteFetchFn) => {
  return {
    text: 'Custom Status',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'work-order-custom-status-filter',
      headerText: 'Work Order Custom Status',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params: { field: 'custom_work_order_status' },
      searchKeys: ['custom_work_order_status']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.WORK_ORDER,
    ...config
  }
}

const WORK_ORDER_CALL = (config = {}, params: AutocompletePayload = { field: 'call_type' }, fetchFn: any = workOrdersAutoCompleteFetchFn) => {
  return {
    text: 'Call',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'work-order-call-filter',
      headerText: 'Work Order Call',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: ['call_type']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.WORK_ORDER,
    ...config
  }
}

const WORK_ORDER_JOB_NAME = (config = {}, params: AutocompletePayload = { field: 'job_name' }, fetchFn: any = workOrdersAutoCompleteFetchFn) => {
  return {
    text: 'Job Name',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'work-order-job-name-filter',
      headerText: 'Work Order Job Name',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: ['work_order_name']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.WORK_ORDER,
    ...config
  }
}

// Not in use (missing API)
const WORK_ORDER_DEPARTMENT_BRANCH = (config = {}, params: AutocompletePayload = { field: 'department_or_branch' }, fetchFn: any = workOrdersAutoCompleteFetchFn) => {
  return {
    text: 'Department Branch',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'work-order-department-branch-filter',
      headerText: 'Work Order Department Branch',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: ['department_or_branch']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.WORK_ORDER,
    ...config
  }
}

const WORK_ORDER_FOLLOW_UP_DATE = (config = {}) => {
  return {
    text: 'Follow Up Date',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'work-order-follow-up-filter',
      headerText: 'Work Order Follow Up Date',
      size: 'md',
      contentType: 'datetime',
      rowType: 'text',
      showHeaders: true
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.WORK_ORDER,
    ...config
  }
}

// Not in use (Missing API)
const WORK_ORDER_CLIENT_CONTACT = (config = {}, fetchFn: any = workOrdersAutoCompleteFetchFn) => {
  return {
    text: 'Client Contact',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'work-order-client-contact-filter',
      headerText: 'Work Order Client Contact',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params: { field: 'client_work_order_contact' },
      searchKeys: ['client_work_order_contact']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.WORK_ORDER,
    ...config
  }
}

// Not in use (missing API)
const WORK_ORDER_TRIP_CATEGORY = (config = {}, fetchFn: any = workOrdersAutoCompleteFetchFn) => {
  return {
    text: 'Trip Category',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'work-order-trip-category-filter',
      headerText: 'Work Order Trip Category',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params: { include: 'trip_category' },
      searchKeys: ['trip_category']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.WORK_ORDER,
    ...config
  }
}

const WORK_ORDER_PROBLEM_TYPE = (config = {}, params = { field: 'problem_type', invoiceables: false }, fetchFn: any = workOrdersAutoCompleteFetchFn) => {
  return {
    text: 'Problem Type',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'work-order-problem-type-filter',
      headerText: 'Work Order Problem Type',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: ['problem_type']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.WORK_ORDER,
    ...config
  }
}

const WORK_ORDER_COMPLETION_DATE = (config = {}) => {
  return {
    text: 'Completion Date',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'work-order-completion-date-filter',
      headerText: 'WO Completion Date',
      size: 'md',
      contentType: 'date',
      rowType: 'text',
      showHeaders: true,
      params: { include: 'trips', q: { s: 'id asc' } },
      searchKeys: ['work_order_id']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.WORK_ORDER,
    ...config
  }
}

const WORK_ORDER_TRIP_COMPLETION_DATE = (config = {}) => {
  return {
    text: 'Trip Completion Date',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'work-order-trip-completion-date-filter',
      headerText: 'Work Order Trip Completion Date',
      size: 'md',
      contentType: 'date',
      rowType: 'text',
      showHeaders: true,
      params: { include: 'trips', q: { s: 'id asc' } },
      searchKeys: ['work_order_id']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.WORK_ORDER,
    ...config
  }
}

const VENDOR_INVOICE_EXISTS = (config = {}) => {
  return {
    text: 'Vendor Invoice Exists',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'vendor-invoice-exists',
      headerText: 'Vendor Invoice Exists',
      size: 'md',
      contentType: 'select',
      rowType: 'text',
      items: [
        { label: 'Yes', value: 'true' }, { label: 'No', value: 'false' }
      ],
      showHeaders: true,
      fetchFn: () => {
        return {
          data: [{ label: 'Yes', value: 'true' }, { label: 'No', value: 'false' }],
          totalCount: 2,
          totalPages: 1
        }
      },
      params: { },
      searchKeys: ['text']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.WORK_ORDER,
    ...config
  }
}

const WORK_ORDER_SERVICE = (config = {}, params: AutocompletePayload = { field: 'trade_service_name' }, fetchFn: any = workOrdersAutoCompleteFetchFn) => {
  return {
    text: 'Service',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'work-order-service-filter',
      headerText: 'Work Order Service',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: ['trade_service_name']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.WORK_ORDER,
    ...config
  }
}

const WORK_ORDER_TRADE = (config = {}, params: AutocompletePayload = { field: 'trade_name' }, fetchFn: any = workOrdersAutoCompleteFetchFn) => {
  return {
    text: 'Trade',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'work-order-trade-filter',
      headerText: 'Work Order Trade',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: ['trade']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.WORK_ORDER,
    ...config
  }
}

const WORK_ORDER_BILLING_METHOD = (config = {}, params: AutocompletePayload = { field: 'work_order_type' }, fetchFn: any = workOrdersAutoCompleteFetchFn) => {
  return {
    text: 'Billing Method',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'work-order-billing-method-filter',
      headerText: 'Work Order Billing Method',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: ['work_order_type']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.WORK_ORDER,
    ...config
  }
}

const WORK_ORDER_EXTERNAL_REGION = (config = {}, fetchFn: any = workOrdersAutoCompleteFetchFn) => {
  return {
    text: 'External Region',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'work-order-external-region-filter',
      headerText: 'External Region',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params: { field: 'external_region' },
      searchKeys: []
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.WORK_ORDER,
    ...config
  }
}

const WORK_ORDER_CATEGORY = (config = {}, params = { field: 'category', invoiceables: false }, fetchFn: any = workOrdersAutoCompleteFetchFn) => {
  return {
    text: 'Problem Code',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'work-order-problem-code-filter',
      headerText: 'Work Order Problem Code',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: ['category']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.WORK_ORDER,
    ...config
  }
}

const WORK_ORDER_DUE_DATE = (config = {}) => {
  return {
    text: 'Due Date',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'work-order-due-date-filter',
      headerText: 'Work Order Due Date',
      size: 'md',
      contentType: 'date',
      rowType: 'text',
      showHeaders: true,
      params: { include: 'trips', q: { s: 'id asc' } },
      searchKeys: ['work_order_id']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.WORK_ORDER,
    ...config
  }
}

const WORK_ORDER_CLOSE_DATE = (config = {}) => {
  return {
    text: 'Close Date',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'work-order-close-date-filter',
      headerText: 'Work Order Close Date',
      size: 'md',
      contentType: 'date',
      rowType: 'text',
      showHeaders: true,
      params: { include: 'trips', q: { s: 'id asc' } },
      searchKeys: ['work_order_id']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.WORK_ORDER,
    ...config
  }
}

const WORK_ORDER_TASK = (config = {}, params: AutocompletePayload = { field: 'trade_service_task_name' }, fetchFn: any = workOrdersAutoCompleteFetchFn) => {
  return {
    text: 'Task',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'work-order-task-filter',
      headerText: 'Work Order Task',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: []
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.WORK_ORDER,
    ...config
  }
}

const WORK_ORDER_SCHEDULED_SERVICE_NUMBER = (config = {}, params: AutocompletePayload = { field: 'agreement_contract_id' }, fetchFn: any = workOrdersAutoCompleteFetchFn) => {
  return {
    text: 'Scheduled Service Number',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'work-order-scheduled-service-number-filter',
      headerText: 'Scheduled Service Number',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      predicate: '_cont',
      params,
      searchKeys: [],
      collectionPredicate: 'eq'
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.WORK_ORDER,
    ...config
  }
}

const WORK_ORDER_WEATHER_EVENT_WW_ID = (config = {}, params: AutocompletePayload = { field: 'weather_event_id' }, fetchFn: any = workOrdersAutoCompleteFetchFn) => {
  return {
    text: 'WW ID',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'work-order-weather-event-ww-id-filter',
      headerText: 'Weather Event WW ID',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      predicate: '_cont',
      params,
      searchKeys: ['weather_event_id'],
      collectionPredicate: 'eq'
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.WEATHER_EVENT,
    ...config
  }
}

const WORK_ORDER_VENDOR_STATUS = (
  config = {},
  params: AutocompletePayload = { field: 'vendor_status', allRecords: true },
  fetchFn: any = workOrdersAutoCompleteFetchFn
) => {
  return {
    text: 'Vendor Status',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'work-order-vendor-status-filter',
      headerText: 'Vendor Status',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      items: fetchFn,
      params,
      searchKeys: ['text']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.WORK_ORDER,
    ...config
  }
}

const WORK_ORDER_SERVICE_CHANNEL_CONNECTION = (config = {}, params: AutocompletePayload = { field: 'service_channel_connection_id', invoiceables: false }, fetchFn: any = workOrdersAutoCompleteFetchFn) => {
  return {
    text: 'ServiceChannel Connection',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'work-order-service-channel-connection-filter',
      headerText: 'ServiceChannel Connection',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: ['external_id']
    },
    pinned: false,
    isPrimary: false,
    ...config
  }
}

const WORK_ORDER_CORRIGO_CONNECTION = (config = {}, params: AutocompletePayload = { field: 'corrigo_connection_id', invoiceables: false }, fetchFn: any = workOrdersAutoCompleteFetchFn) => {
  return {
    text: 'Corrigo Connection',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'work-order-corrigo-connection-filter',
      headerText: 'Corrigo Connection',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: ['external_id']
    },
    pinned: false,
    isPrimary: false,
    ...config
  }
}

const WORK_ORDER_CLIENT_STATUS = (
  config = {},
  params: AutocompletePayload = { field: 'client_status', allRecords: true },
  fetchFn: any = workOrdersAutoCompleteFetchFn
) => {
  return {
    text: 'Client Status',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'work-order-client-status-filter',
      headerText: 'Client Status',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      items: fetchFn,
      params,
      searchKeys: ['text']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.WORK_ORDER,
    ...config
  }
}

const WORK_ORDER_SERVICE_CHECK_IN_TIME = (config = {}) => {
  return {
    text: 'Work Order Service Check in',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'work-order-service-check-in-filter',
      headerText: 'Work Order Service Check In',
      size: 'md',
      contentType: 'datetime',
      rowType: 'text',
      showHeaders: true
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.WORK_ORDER,
    ...config
  }
}

const WORK_ORDER_SERVICE_CHECK_OUT_TIME = (config = {}) => {
  return {
    text: 'Work Order Service Check out',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'work-order-service-check-out-filter',
      headerText: 'Work Order Service Check Out',
      size: 'md',
      contentType: 'datetime',
      rowType: 'text',
      showHeaders: true
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.WORK_ORDER,
    ...config
  }
}

const WORK_ORDER_SENT_AND_RECEIVED = (config = {}) => {
  const options = [
    { label: 'Sent', value: 'Sent', predicate: 'eq', data: session.currentCompany.id },
    { label: 'Received', value: 'Received', predicate: 'not_eq', data: session.currentCompany.id }
  ]
  return {
    text: 'Sent and Received',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'sent-and-received-filter',
      headerText: 'Sent and Received',
      size: 'md',
      contentType: 'select',
      rowType: 'text',
      showHeaders: true,
      fetchFn: () => {
        return {
          data: options,
          totalCount: options.length,
          totalPages: 1
        }
      },
      operator: [{ text: 'Is', value: 'eq' }],
      searchKeys: ['text']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.WORK_ORDER,
    ...config
  }
}

const WORK_ORDER_SOURCE = (config = {}) => {
  const options = [{ id: 1, text: 'CIWO', value: '2' }, { id: 2, text: 'TIWO', value: '3' }]
  return {
    text: 'Source',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'source-filter',
      headerText: 'Source',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn: () => {
        return {
          data: options,
          totalCount: options.length,
          totalPages: 1
        }
      },
      params: { field: 'value' },
      searchKeys: ['text'],
      operator: [{ text: 'Is', value: '_in' }]
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.WORK_ORDER,
    ...config
  }
}

const WORK_ORDER_SCHEDULED_SERVICE = (config = {}) => {
  const options = [{ label: 'Is', value: '1', predicate: 'not_null' }, { label: 'Is Not', value: '0', predicate: 'not_null' }]
  return {
    text: 'Scheduled Service',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'scheduled-service-filter',
      headerText: 'Scheduled Service',
      size: 'md',
      contentType: 'select',
      rowType: 'text',
      showHeaders: true,
      fetchFn: () => {
        return {
          data: options,
          totalCount: options.length,
          totalPages: 1
        }
      },
      params: {},
      searchKeys: ['text'],
      operator: [{ text: 'Is', value: '_not_null' }]
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.WORK_ORDER,
    ...config
  }
}

const ACTIVE_OR_NON_ACTIVE = (config = {}) => {
  const options = [
    { label: 'Active', value: 'Active', predicate: 'gt', data: moment().utc().format() },
    { label: 'Non-Active', value: 'Non-Active', predicate: 'lt', data: moment().utc().format() }
  ]
  return {
    text: 'Active Or Non Active',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'active-or-non-active-filter',
      headerText: 'Active Or Non Active',
      size: 'md',
      contentType: 'select',
      rowType: 'text',
      showHeaders: true,
      fetchFn: () => {
        return {
          data: options,
          totalCount: options.length,
          totalPages: 1
        }
      },
      operator: [{ text: 'Is', value: 'eq' }],
      searchKeys: ['text']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.WORK_ORDER,
    ...config
  }
}

export {
  WORK_ORDER_NUMBER,
  WORK_ORDER_START_DATE,
  WORK_ORDER_CREATED_DATE,
  WORK_ORDER_EXPIRATION_DATE,
  WORK_ORDER_TRIP_EXPIRATION_DATE,
  WORK_ORDER_GENERATION_DATE,
  WORK_ORDER_TYPE,
  WORK_ORDER_PRIORITY,
  WORK_ORDER_TRIP_STATUS,
  WORK_ORDER_STATUS,
  WORK_ORDER_CUSTOM_STATUS,
  WORK_ORDER_CALL,
  WORK_ORDER_JOB_NAME,
  WORK_ORDER_DEPARTMENT_BRANCH,
  WORK_ORDER_FOLLOW_UP_DATE,
  WORK_ORDER_CLIENT_CONTACT,
  WORK_ORDER_TRIP_CATEGORY,
  WORK_ORDER_PROBLEM_TYPE,
  WORK_ORDER_COMPLETION_DATE,
  WORK_ORDER_TRIP_COMPLETION_DATE,
  VENDOR_INVOICE_EXISTS,
  WORK_ORDER_SERVICE,
  WORK_ORDER_TRADE,
  WORK_ORDER_BILLING_METHOD,
  WORK_ORDER_EXTERNAL_REGION,
  WORK_ORDER_CATEGORY,
  WORK_ORDER_DUE_DATE,
  WORK_ORDER_CLOSE_DATE,
  WORK_ORDER_TASK,
  WORK_ORDER_SCHEDULED_SERVICE_NUMBER,
  WORK_ORDER_WEATHER_EVENT_WW_ID,
  WORK_ORDER_VENDOR_STATUS,
  WORK_ORDER_SERVICE_CHANNEL_CONNECTION,
  WORK_ORDER_CORRIGO_CONNECTION,
  WORK_ORDER_CLIENT_STATUS,
  WORK_ORDER_SERVICE_CHECK_IN_TIME,
  WORK_ORDER_SERVICE_CHECK_OUT_TIME,
  WORK_ORDER_SENT_AND_RECEIVED,
  WORK_ORDER_SOURCE,
  WORK_ORDER_SCHEDULED_SERVICE,
  ACTIVE_OR_NON_ACTIVE
}
